/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractResponse } from '../models';
// @ts-ignore
import { ErrorMessageResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { PartnerAccountRequest } from '../models';
/**
 * PartnerAccountApi - axios parameter creator
 * @export
 */
export const PartnerAccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add the partner account
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountAdd: async (contractUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('partnerAccountAdd', 'contractUid', contractUid)
            // verify required parameter 'partnerAccountRequest' is not null or undefined
            assertParamExists('partnerAccountAdd', 'partnerAccountRequest', partnerAccountRequest)
            const localVarPath = `/api/contract/{contractUid}/partner-account`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the partner account
         * @param {string} partnerAccountUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountDelete: async (partnerAccountUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerAccountUid' is not null or undefined
            assertParamExists('partnerAccountDelete', 'partnerAccountUid', partnerAccountUid)
            const localVarPath = `/api/partner-account/{partnerAccountUid}`
                .replace(`{${"partnerAccountUid"}}`, encodeURIComponent(String(partnerAccountUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the partner account
         * @param {string} partnerAccountUid 
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountEdit: async (partnerAccountUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerAccountUid' is not null or undefined
            assertParamExists('partnerAccountEdit', 'partnerAccountUid', partnerAccountUid)
            // verify required parameter 'partnerAccountRequest' is not null or undefined
            assertParamExists('partnerAccountEdit', 'partnerAccountRequest', partnerAccountRequest)
            const localVarPath = `/api/partner-account/{partnerAccountUid}`
                .replace(`{${"partnerAccountUid"}}`, encodeURIComponent(String(partnerAccountUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerAccountApi - functional programming interface
 * @export
 */
export const PartnerAccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerAccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add the partner account
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAccountAdd(contractUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAccountAdd(contractUid, partnerAccountRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the partner account
         * @param {string} partnerAccountUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAccountDelete(partnerAccountUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAccountDelete(partnerAccountUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the partner account
         * @param {string} partnerAccountUid 
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerAccountEdit(partnerAccountUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerAccountEdit(partnerAccountUid, partnerAccountRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerAccountApi - factory interface
 * @export
 */
export const PartnerAccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerAccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Add the partner account
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountAdd(contractUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.partnerAccountAdd(contractUid, partnerAccountRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the partner account
         * @param {string} partnerAccountUid 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountDelete(partnerAccountUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.partnerAccountDelete(partnerAccountUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the partner account
         * @param {string} partnerAccountUid 
         * @param {PartnerAccountRequest} partnerAccountRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerAccountEdit(partnerAccountUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.partnerAccountEdit(partnerAccountUid, partnerAccountRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerAccountApi - interface
 * @export
 * @interface PartnerAccountApi
 */
export interface PartnerAccountApiInterface {
    /**
     * 
     * @summary Add the partner account
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {PartnerAccountRequest} partnerAccountRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApiInterface
     */
    partnerAccountAdd(contractUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Delete the partner account
     * @param {string} partnerAccountUid 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApiInterface
     */
    partnerAccountDelete(partnerAccountUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Edit the partner account
     * @param {string} partnerAccountUid 
     * @param {PartnerAccountRequest} partnerAccountRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApiInterface
     */
    partnerAccountEdit(partnerAccountUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

}

/**
 * PartnerAccountApi - object-oriented interface
 * @export
 * @class PartnerAccountApi
 * @extends {BaseAPI}
 */
export class PartnerAccountApi extends BaseAPI implements PartnerAccountApiInterface {
    /**
     * 
     * @summary Add the partner account
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {PartnerAccountRequest} partnerAccountRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApi
     */
    public partnerAccountAdd(contractUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PartnerAccountApiFp(this.configuration).partnerAccountAdd(contractUid, partnerAccountRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the partner account
     * @param {string} partnerAccountUid 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApi
     */
    public partnerAccountDelete(partnerAccountUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PartnerAccountApiFp(this.configuration).partnerAccountDelete(partnerAccountUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the partner account
     * @param {string} partnerAccountUid 
     * @param {PartnerAccountRequest} partnerAccountRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountApi
     */
    public partnerAccountEdit(partnerAccountUid: string, partnerAccountRequest: PartnerAccountRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PartnerAccountApiFp(this.configuration).partnerAccountEdit(partnerAccountUid, partnerAccountRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
