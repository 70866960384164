// https://czsodexo.atlassian.net/wiki/spaces/NI/pages/3858235543/NGM+Environments#NGM-environments-and-interconnections

const countryConfig = {
  // All subdirectories you want to support, default for /
  paths: ['cz', 'be'],
  // Default path to be used when visiting a non-subdirectory prefixed path not matched by domain
  defaultPath: 'cz',
  // simplify only on root domain?
  countryCodes: {
    // local
    'merchant-front.test': 'be',
    // dev
    'partner.dev.pluxee.cz': 'cz',
    'shop.merchants.dev.pluxee.be': 'be',
    // dev2
    'partner2.dev.pluxee.cz': 'cz',
    'shop.merchants2.dev.pluxee.be': 'be',
    // uat
    'partner.uat.pluxee.cz': 'cz',
    'shop.merchants.uat.pluxee.be': 'be',
    // sta
    'partner.sta.pluxee.cz': 'cz',
    'shop.merchants.sta.pluxee.be': 'be',
    // prod
    'partner.pluxee.cz': 'cz',
    'shop.merchants.pluxee.be': 'be',
  }
};

module.exports = countryConfig;
