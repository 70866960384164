/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Location payment error code
 * @export
 * @enum {string}
 */

export enum LocationPaymentErrorEnum {
    InvalidAmount = 'INVALID_AMOUNT',
    InvalidAccount = 'INVALID_ACCOUNT',
    VoucherDenied = 'VOUCHER_DENIED',
    VoucherValueExceeded = 'VOUCHER_VALUE_EXCEEDED',
    AccountLimitExceeded = 'ACCOUNT_LIMIT_EXCEEDED',
    LimitExceeded = 'LIMIT_EXCEEDED',
    LowAccountBalance = 'LOW_ACCOUNT_BALANCE',
    Expired = 'EXPIRED',
    ConfirmationTriesExceeded = 'CONFIRMATION_TRIES_EXCEEDED',
    LocationNotFound = 'LOCATION_NOT_FOUND',
    InvalidConfirmation = 'INVALID_CONFIRMATION',
    InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
    TrxAlreadyExists = 'TRX_ALREADY_EXISTS',
    Failed = 'FAILED'
}



