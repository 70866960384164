import { Country } from 'react-phone-number-input';

const countries: { [key in Country]?: string } = {
  AT: 'Österreich',
  BE: 'België',
  CZ: 'Česká republika',
  DE: 'Deutschland',
  PL: 'Polska',
  SK: 'Slovensko',
  UA: 'Україна',
  VN: 'Việt Nam',
};

export default countries;
