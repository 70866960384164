/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ProductsResponse } from '../models';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Products search
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [services] List of the company service &#x60;code&#x60;
         * @param {string} [contractUid] Contract uid for display individual products offer
         * @param {string} [campaignId] Code of campaign.
         * @param {boolean} [hidden] Display hidden products.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        products: async (ngmCountry?: 'cz' | 'be', services?: Array<string>, contractUid?: string, campaignId?: string, hidden?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (services) {
                localVarQueryParameter['services'] = services.join(COLLECTION_FORMATS.csv);
            }

            if (contractUid !== undefined) {
                localVarQueryParameter['contractUid'] = contractUid;
            }

            if (campaignId !== undefined) {
                localVarQueryParameter['campaignId'] = campaignId;
            }

            if (hidden !== undefined) {
                localVarQueryParameter['hidden'] = hidden;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Products search
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [services] List of the company service &#x60;code&#x60;
         * @param {string} [contractUid] Contract uid for display individual products offer
         * @param {string} [campaignId] Code of campaign.
         * @param {boolean} [hidden] Display hidden products.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async products(ngmCountry?: 'cz' | 'be', services?: Array<string>, contractUid?: string, campaignId?: string, hidden?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.products(ngmCountry, services, contractUid, campaignId, hidden, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Products search
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {Array<string>} [services] List of the company service &#x60;code&#x60;
         * @param {string} [contractUid] Contract uid for display individual products offer
         * @param {string} [campaignId] Code of campaign.
         * @param {boolean} [hidden] Display hidden products.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        products(ngmCountry?: 'cz' | 'be', services?: Array<string>, contractUid?: string, campaignId?: string, hidden?: boolean, options?: any): AxiosPromise<ProductsResponse> {
            return localVarFp.products(ngmCountry, services, contractUid, campaignId, hidden, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * 
     * @summary Products search
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {Array<string>} [services] List of the company service &#x60;code&#x60;
     * @param {string} [contractUid] Contract uid for display individual products offer
     * @param {string} [campaignId] Code of campaign.
     * @param {boolean} [hidden] Display hidden products.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    products(ngmCountry?: 'cz' | 'be', services?: Array<string>, contractUid?: string, campaignId?: string, hidden?: boolean, options?: any): AxiosPromise<ProductsResponse>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * 
     * @summary Products search
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {Array<string>} [services] List of the company service &#x60;code&#x60;
     * @param {string} [contractUid] Contract uid for display individual products offer
     * @param {string} [campaignId] Code of campaign.
     * @param {boolean} [hidden] Display hidden products.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public products(ngmCountry?: 'cz' | 'be', services?: Array<string>, contractUid?: string, campaignId?: string, hidden?: boolean, options?: any) {
        return ProductsApiFp(this.configuration).products(ngmCountry, services, contractUid, campaignId, hidden, options).then((request) => request(this.axios, this.basePath));
    }
}
