/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmployeeClassificationRequest } from '../models';
// @ts-ignore
import { EmployeeErrorResponse } from '../models';
// @ts-ignore
import { EmployeeListResponse } from '../models';
// @ts-ignore
import { EmployeeLocationsRequest } from '../models';
// @ts-ignore
import { EmployeeLocationsResponse } from '../models';
// @ts-ignore
import { EmployeeLocationsSortingEnum } from '../models';
// @ts-ignore
import { EmployeePersonalRequest } from '../models';
// @ts-ignore
import { EmployeePortalAccessRequest } from '../models';
// @ts-ignore
import { EmployeeRequest } from '../models';
// @ts-ignore
import { EmployeeResponse } from '../models';
// @ts-ignore
import { EmployeeSortingEnum } from '../models';
// @ts-ignore
import { EmployeeStatesResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { PortalInvitationErrorResponse } from '../models';
// @ts-ignore
import { PortalInvitationResponse } from '../models';
// @ts-ignore
import { SuccessResponse } from '../models';
// @ts-ignore
import { UserRoleEnum } from '../models';
/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create the new employee
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {EmployeeRequest} employeeRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeCreate: async (contractUid: string, employeeRequest: EmployeeRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('employeeCreate', 'contractUid', contractUid)
            // verify required parameter 'employeeRequest' is not null or undefined
            assertParamExists('employeeCreate', 'employeeRequest', employeeRequest)
            const localVarPath = `/api/contract/{contractUid}/employee`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Employee full detail
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDetail: async (employeeUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeeDetail', 'employeeUid', employeeUid)
            const localVarPath = `/api/employee/{employeeUid}`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept the portal invitation
         * @param {string} invitationKey Invitation token received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeInvitationAccept: async (invitationKey: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationKey' is not null or undefined
            assertParamExists('employeeInvitationAccept', 'invitationKey', invitationKey)
            const localVarPath = `/api/employee/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invitationKey !== undefined) {
                localVarQueryParameter['invitationKey'] = invitationKey;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify the portal invitation
         * @param {string} invitationKey Invitation key received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeInvitationVerify: async (invitationKey: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationKey' is not null or undefined
            assertParamExists('employeeInvitationVerify', 'invitationKey', invitationKey)
            const localVarPath = `/api/employee/invitation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (invitationKey !== undefined) {
                localVarQueryParameter['invitationKey'] = invitationKey;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsExportGet: async (contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('employeeLocationsExportGet', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/employee-locations/export`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (employeeUid !== undefined) {
                localVarQueryParameter['employeeUid'] = employeeUid;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsGet: async (contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('employeeLocationsGet', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/employee-locations`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (employeeUid !== undefined) {
                localVarQueryParameter['employeeUid'] = employeeUid;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach/Detach locations to employee
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeLocationsRequest} employeeLocationsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsSet: async (employeeUid: string, employeeLocationsRequest: EmployeeLocationsRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeeLocationsSet', 'employeeUid', employeeUid)
            // verify required parameter 'employeeLocationsRequest' is not null or undefined
            assertParamExists('employeeLocationsSet', 'employeeLocationsRequest', employeeLocationsRequest)
            const localVarPath = `/api/employee/{employeeUid}/locations`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeLocationsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionActivate: async (employeeUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeePartitionActivate', 'employeeUid', employeeUid)
            const localVarPath = `/api/employee/{employeeUid}/activate`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Employee classification inside the company
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeClassificationRequest} employeeClassificationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionClassification: async (employeeUid: string, employeeClassificationRequest: EmployeeClassificationRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeePartitionClassification', 'employeeUid', employeeUid)
            // verify required parameter 'employeeClassificationRequest' is not null or undefined
            assertParamExists('employeePartitionClassification', 'employeeClassificationRequest', employeeClassificationRequest)
            const localVarPath = `/api/employee/{employeeUid}/classification`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeeClassificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionDeactivate: async (employeeUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeePartitionDeactivate', 'employeeUid', employeeUid)
            const localVarPath = `/api/employee/{employeeUid}/deactivate`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Employee personal data.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePersonalRequest} employeePersonalRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionPerson: async (employeeUid: string, employeePersonalRequest: EmployeePersonalRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeePartitionPerson', 'employeeUid', employeeUid)
            // verify required parameter 'employeePersonalRequest' is not null or undefined
            assertParamExists('employeePartitionPerson', 'employeePersonalRequest', employeePersonalRequest)
            const localVarPath = `/api/employee/{employeeUid}/personal`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeePersonalRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enable/Disable portal access for the employee. Usable to Resend the invitation email.
         * @summary Enable/Disable portal access for the employee.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePortalAccessRequest} employeePortalAccessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionPortalaccess: async (employeeUid: string, employeePortalAccessRequest: EmployeePortalAccessRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeePartitionPortalaccess', 'employeeUid', employeeUid)
            // verify required parameter 'employeePortalAccessRequest' is not null or undefined
            assertParamExists('employeePartitionPortalaccess', 'employeePortalAccessRequest', employeePortalAccessRequest)
            const localVarPath = `/api/employee/{employeeUid}/portal-access`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(employeePortalAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeStates: async (employeeUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'employeeUid' is not null or undefined
            assertParamExists('employeeStates', 'employeeUid', employeeUid)
            const localVarPath = `/api/employee/{employeeUid}/states`
                .replace(`{${"employeeUid"}}`, encodeURIComponent(String(employeeUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all contract related employees
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [inactive] Show also inactive users
         * @param {'access' | 'contact'} [trait] Employees with the selected trait
         * @param {Array<string>} [locationId] Location UIDs
         * @param {Array<UserRoleEnum>} [userRoles] User roles delimited by comma
         * @param {Array<string>} [companyPositions] Company position IDs delimited by comma
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesList: async (contractUid: string, inactive?: boolean, trait?: 'access' | 'contact', locationId?: Array<string>, userRoles?: Array<UserRoleEnum>, companyPositions?: Array<string>, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('employeesList', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/employees`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (inactive !== undefined) {
                localVarQueryParameter['inactive'] = inactive;
            }

            if (trait !== undefined) {
                localVarQueryParameter['trait'] = trait;
            }

            if (locationId) {
                localVarQueryParameter['locationId'] = locationId.join(COLLECTION_FORMATS.csv);
            }

            if (userRoles) {
                localVarQueryParameter['userRoles'] = userRoles.join(COLLECTION_FORMATS.csv);
            }

            if (companyPositions) {
                localVarQueryParameter['companyPositions'] = companyPositions.join(COLLECTION_FORMATS.csv);
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create the new employee
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {EmployeeRequest} employeeRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeCreate(contractUid: string, employeeRequest: EmployeeRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeCreate(contractUid, employeeRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Employee full detail
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeDetail(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeDetail(employeeUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Accept the portal invitation
         * @param {string} invitationKey Invitation token received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeInvitationAccept(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeInvitationAccept(invitationKey, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify the portal invitation
         * @param {string} invitationKey Invitation key received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeInvitationVerify(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalInvitationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeInvitationVerify(invitationKey, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLocationsExportGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLocationsExportGet(contractUid, ngmCountry, employeeUid, fulltext, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeLocationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLocationsGet(contractUid, ngmCountry, employeeUid, fulltext, pageNumber, pageSize, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attach/Detach locations to employee
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeLocationsRequest} employeeLocationsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeLocationsSet(employeeUid: string, employeeLocationsRequest: EmployeeLocationsRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeLocationsSet(employeeUid, employeeLocationsRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePartitionActivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePartitionActivate(employeeUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the Employee classification inside the company
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeClassificationRequest} employeeClassificationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePartitionClassification(employeeUid: string, employeeClassificationRequest: EmployeeClassificationRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePartitionClassification(employeeUid, employeeClassificationRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePartitionDeactivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePartitionDeactivate(employeeUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the Employee personal data.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePersonalRequest} employeePersonalRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePartitionPerson(employeeUid: string, employeePersonalRequest: EmployeePersonalRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePartitionPerson(employeeUid, employeePersonalRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enable/Disable portal access for the employee. Usable to Resend the invitation email.
         * @summary Enable/Disable portal access for the employee.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePortalAccessRequest} employeePortalAccessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeePartitionPortalaccess(employeeUid: string, employeePortalAccessRequest: EmployeePortalAccessRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeePartitionPortalaccess(employeeUid, employeePortalAccessRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeStates(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeStatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeStates(employeeUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all contract related employees
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [inactive] Show also inactive users
         * @param {'access' | 'contact'} [trait] Employees with the selected trait
         * @param {Array<string>} [locationId] Location UIDs
         * @param {Array<UserRoleEnum>} [userRoles] User roles delimited by comma
         * @param {Array<string>} [companyPositions] Company position IDs delimited by comma
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeesList(contractUid: string, inactive?: boolean, trait?: 'access' | 'contact', locationId?: Array<string>, userRoles?: Array<UserRoleEnum>, companyPositions?: Array<string>, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeesList(contractUid, inactive, trait, locationId, userRoles, companyPositions, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create the new employee
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {EmployeeRequest} employeeRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeCreate(contractUid: string, employeeRequest: EmployeeRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeeCreate(contractUid, employeeRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Employee full detail
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeDetail(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeeDetail(employeeUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Accept the portal invitation
         * @param {string} invitationKey Invitation token received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeInvitationAccept(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.employeeInvitationAccept(invitationKey, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify the portal invitation
         * @param {string} invitationKey Invitation key received in the email
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeInvitationVerify(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PortalInvitationResponse> {
            return localVarFp.employeeInvitationVerify(invitationKey, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsExportGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.employeeLocationsExportGet(contractUid, ngmCountry, employeeUid, fulltext, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * List all locations with Location soft detail and person related options
         * @summary List locations with the relation to person
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [employeeUid] Employee &#x60;uid&#x60;
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<EmployeeLocationsResponse> {
            return localVarFp.employeeLocationsGet(contractUid, ngmCountry, employeeUid, fulltext, pageNumber, pageSize, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach/Detach locations to employee
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeLocationsRequest} employeeLocationsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeLocationsSet(employeeUid: string, employeeLocationsRequest: EmployeeLocationsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.employeeLocationsSet(employeeUid, employeeLocationsRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionActivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeePartitionActivate(employeeUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Employee classification inside the company
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeeClassificationRequest} employeeClassificationRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionClassification(employeeUid: string, employeeClassificationRequest: EmployeeClassificationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeePartitionClassification(employeeUid, employeeClassificationRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate the employee profile.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionDeactivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeePartitionDeactivate(employeeUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Employee personal data.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePersonalRequest} employeePersonalRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionPerson(employeeUid: string, employeePersonalRequest: EmployeePersonalRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeePartitionPerson(employeeUid, employeePersonalRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * Enable/Disable portal access for the employee. Usable to Resend the invitation email.
         * @summary Enable/Disable portal access for the employee.
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {EmployeePortalAccessRequest} employeePortalAccessRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeePartitionPortalaccess(employeeUid: string, employeePortalAccessRequest: EmployeePortalAccessRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse> {
            return localVarFp.employeePartitionPortalaccess(employeeUid, employeePortalAccessRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} employeeUid Employee &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeStates(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeStatesResponse> {
            return localVarFp.employeeStates(employeeUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all contract related employees
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {boolean} [inactive] Show also inactive users
         * @param {'access' | 'contact'} [trait] Employees with the selected trait
         * @param {Array<string>} [locationId] Location UIDs
         * @param {Array<UserRoleEnum>} [userRoles] User roles delimited by comma
         * @param {Array<string>} [companyPositions] Company position IDs delimited by comma
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {EmployeeSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeesList(contractUid: string, inactive?: boolean, trait?: 'access' | 'contact', locationId?: Array<string>, userRoles?: Array<UserRoleEnum>, companyPositions?: Array<string>, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeListResponse> {
            return localVarFp.employeesList(contractUid, inactive, trait, locationId, userRoles, companyPositions, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeApi - interface
 * @export
 * @interface EmployeeApi
 */
export interface EmployeeApiInterface {
    /**
     * 
     * @summary Create the new employee
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {EmployeeRequest} employeeRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeCreate(contractUid: string, employeeRequest: EmployeeRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary Employee full detail
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeDetail(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary Accept the portal invitation
     * @param {string} invitationKey Invitation token received in the email
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeInvitationAccept(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Verify the portal invitation
     * @param {string} invitationKey Invitation key received in the email
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeInvitationVerify(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PortalInvitationResponse>;

    /**
     * List all locations with Location soft detail and person related options
     * @summary List locations with the relation to person
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeLocationsExportGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<void>;

    /**
     * List all locations with Location soft detail and person related options
     * @summary List locations with the relation to person
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<EmployeeLocationsResponse>;

    /**
     * 
     * @summary Attach/Detach locations to employee
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeeLocationsRequest} employeeLocationsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeLocationsSet(employeeUid: string, employeeLocationsRequest: EmployeeLocationsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Activate the employee profile.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeePartitionActivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary Update the Employee classification inside the company
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeeClassificationRequest} employeeClassificationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeePartitionClassification(employeeUid: string, employeeClassificationRequest: EmployeeClassificationRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary Deactivate the employee profile.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeePartitionDeactivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary Update the Employee personal data.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeePersonalRequest} employeePersonalRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeePartitionPerson(employeeUid: string, employeePersonalRequest: EmployeePersonalRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * Enable/Disable portal access for the employee. Usable to Resend the invitation email.
     * @summary Enable/Disable portal access for the employee.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeePortalAccessRequest} employeePortalAccessRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeePartitionPortalaccess(employeeUid: string, employeePortalAccessRequest: EmployeePortalAccessRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeResponse>;

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeeStates(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeStatesResponse>;

    /**
     * 
     * @summary List all contract related employees
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {boolean} [inactive] Show also inactive users
     * @param {'access' | 'contact'} [trait] Employees with the selected trait
     * @param {Array<string>} [locationId] Location UIDs
     * @param {Array<UserRoleEnum>} [userRoles] User roles delimited by comma
     * @param {Array<string>} [companyPositions] Company position IDs delimited by comma
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {EmployeeSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApiInterface
     */
    employeesList(contractUid: string, inactive?: boolean, trait?: 'access' | 'contact', locationId?: Array<string>, userRoles?: Array<UserRoleEnum>, companyPositions?: Array<string>, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<EmployeeListResponse>;

}

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI implements EmployeeApiInterface {
    /**
     * 
     * @summary Create the new employee
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {EmployeeRequest} employeeRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeCreate(contractUid: string, employeeRequest: EmployeeRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeCreate(contractUid, employeeRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Employee full detail
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeDetail(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeDetail(employeeUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Accept the portal invitation
     * @param {string} invitationKey Invitation token received in the email
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeInvitationAccept(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeInvitationAccept(invitationKey, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify the portal invitation
     * @param {string} invitationKey Invitation key received in the email
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeInvitationVerify(invitationKey: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeInvitationVerify(invitationKey, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all locations with Location soft detail and person related options
     * @summary List locations with the relation to person
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeLocationsExportGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any) {
        return EmployeeApiFp(this.configuration).employeeLocationsExportGet(contractUid, ngmCountry, employeeUid, fulltext, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all locations with Location soft detail and person related options
     * @summary List locations with the relation to person
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [employeeUid] Employee &#x60;uid&#x60;
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {EmployeeLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', employeeUid?: string, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeLocationsSortingEnum, sortDesc?: boolean, options?: any) {
        return EmployeeApiFp(this.configuration).employeeLocationsGet(contractUid, ngmCountry, employeeUid, fulltext, pageNumber, pageSize, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach/Detach locations to employee
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeeLocationsRequest} employeeLocationsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeLocationsSet(employeeUid: string, employeeLocationsRequest: EmployeeLocationsRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeLocationsSet(employeeUid, employeeLocationsRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate the employee profile.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeePartitionActivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeePartitionActivate(employeeUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the Employee classification inside the company
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeeClassificationRequest} employeeClassificationRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeePartitionClassification(employeeUid: string, employeeClassificationRequest: EmployeeClassificationRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeePartitionClassification(employeeUid, employeeClassificationRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate the employee profile.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeePartitionDeactivate(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeePartitionDeactivate(employeeUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the Employee personal data.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeePersonalRequest} employeePersonalRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeePartitionPerson(employeeUid: string, employeePersonalRequest: EmployeePersonalRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeePartitionPerson(employeeUid, employeePersonalRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enable/Disable portal access for the employee. Usable to Resend the invitation email.
     * @summary Enable/Disable portal access for the employee.
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {EmployeePortalAccessRequest} employeePortalAccessRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeePartitionPortalaccess(employeeUid: string, employeePortalAccessRequest: EmployeePortalAccessRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeePartitionPortalaccess(employeeUid, employeePortalAccessRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} employeeUid Employee &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeeStates(employeeUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeeStates(employeeUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all contract related employees
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {boolean} [inactive] Show also inactive users
     * @param {'access' | 'contact'} [trait] Employees with the selected trait
     * @param {Array<string>} [locationId] Location UIDs
     * @param {Array<UserRoleEnum>} [userRoles] User roles delimited by comma
     * @param {Array<string>} [companyPositions] Company position IDs delimited by comma
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {EmployeeSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public employeesList(contractUid: string, inactive?: boolean, trait?: 'access' | 'contact', locationId?: Array<string>, userRoles?: Array<UserRoleEnum>, companyPositions?: Array<string>, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: EmployeeSortingEnum, sortDesc?: boolean, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeApiFp(this.configuration).employeesList(contractUid, inactive, trait, locationId, userRoles, companyPositions, fulltext, pageNumber, pageSize, sortBy, sortDesc, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
