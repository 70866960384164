import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import parseFilterValues from './parseFilterValues';
import stringifyUrlFilterValues from './stringifyFilterValues';
import { FilterValues } from './types';

const useUrlFilter = <T extends FilterValues>(
  initialValues: T,
  defaultValues?: Partial<T>,
  withTime?: boolean,
): [T, (s: T) => Promise<boolean>] => {
  const { pathname, push, query } = useRouter();
  const didMount = useRef(false);
  const [filterState, setFilterState] = useState<T>(() =>
    parseFilterValues(initialValues, query, defaultValues),
  );

  const updateFilterState = useCallback(
    (state: T) => {
      push(
        {
          pathname,
          query: stringifyUrlFilterValues(initialValues, state, withTime),
        },
        undefined,
        {
          shallow: true,
        },
      );
      return Promise.resolve(true);
    },
    [initialValues, push, pathname, withTime],
  );

  useEffect(() => {
    if (didMount.current) {
      setFilterState(parseFilterValues(initialValues, query, defaultValues));
    } else {
      didMount.current = true;
    }
  }, [initialValues, defaultValues, query, setFilterState]);

  return [filterState, updateFilterState];
};

export default useUrlFilter;
