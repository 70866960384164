/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractFileListResponse } from '../models';
/**
 * EmployeeFileApi - axios parameter creator
 * @export
 */
export const EmployeeFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Receive employee files aligned to actual contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeContractFileGetDocuments: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('employeeContractFileGetDocuments', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/employee-documents`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download employee file document
         * @param {string} fileUid Employee file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFileDownloadDocument: async (fileUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUid' is not null or undefined
            assertParamExists('employeeFileDownloadDocument', 'fileUid', fileUid)
            const localVarPath = `/api/employee/documents/{fileUid}`
                .replace(`{${"fileUid"}}`, encodeURIComponent(String(fileUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeFileApi - functional programming interface
 * @export
 */
export const EmployeeFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmployeeFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Receive employee files aligned to actual contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeContractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractFileListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeContractFileGetDocuments(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download employee file document
         * @param {string} fileUid Employee file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async employeeFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFileDownloadDocument(fileUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmployeeFileApi - factory interface
 * @export
 */
export const EmployeeFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmployeeFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Receive employee files aligned to actual contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeContractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractFileListResponse> {
            return localVarFp.employeeContractFileGetDocuments(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download employee file document
         * @param {string} fileUid Employee file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        employeeFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.employeeFileDownloadDocument(fileUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmployeeFileApi - interface
 * @export
 * @interface EmployeeFileApi
 */
export interface EmployeeFileApiInterface {
    /**
     * 
     * @summary Receive employee files aligned to actual contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFileApiInterface
     */
    employeeContractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractFileListResponse>;

    /**
     * 
     * @summary Download employee file document
     * @param {string} fileUid Employee file &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFileApiInterface
     */
    employeeFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

}

/**
 * EmployeeFileApi - object-oriented interface
 * @export
 * @class EmployeeFileApi
 * @extends {BaseAPI}
 */
export class EmployeeFileApi extends BaseAPI implements EmployeeFileApiInterface {
    /**
     * 
     * @summary Receive employee files aligned to actual contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFileApi
     */
    public employeeContractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeFileApiFp(this.configuration).employeeContractFileGetDocuments(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download employee file document
     * @param {string} fileUid Employee file &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeFileApi
     */
    public employeeFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return EmployeeFileApiFp(this.configuration).employeeFileDownloadDocument(fileUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
