const forceFileDownload = (link: string, filename: string, tryToOpen: boolean = false) => {
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = link;
  if (!tryToOpen) tempLink.setAttribute('download', filename);

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined' || tryToOpen) {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);
  tempLink.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    document.body.removeChild(tempLink);
  }, 200);
};

export default forceFileDownload;
