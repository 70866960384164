import {
  endOfDay,
  endOfMinute,
  format,
  formatISO,
  parse,
  parseISO,
  startOfDay,
  startOfMinute,
} from 'date-fns';
import { isDate } from 'lodash';
import { ParsedUrlQueryInput } from 'querystring';
import { FilterValues } from './types';

export const DATE_FORMAT = 'yyyy-MM-dd';

export const stringifyUrlValue = (value: any, withTime?: boolean): string => {
  if (isDate(value)) {
    return withTime ? formatISO(value) : format(value, DATE_FORMAT);
  }
  return value;
};

// request(s) stringify for communication with backend
export const stringifyRequestDate = (
  value: string | Date,
  typeOf:
    | 'startOfDay'
    | 'endOfDay'
    | 'startOfDateTime'
    | 'endOfDateTime'
    | 'startOfDateTimeDay'
    | 'endOfDateTimeDay',
): string | undefined => {
  try {
    if (
      typeOf === 'startOfDateTime' ||
      typeOf === 'endOfDateTime' ||
      typeOf === 'startOfDateTimeDay' ||
      typeOf === 'endOfDateTimeDay'
    ) {
      const dateTime = isDate(value) ? value : parseISO(value);
      return (
        typeOf === 'endOfDateTime'
          ? endOfMinute(dateTime)
          : typeOf === 'startOfDateTime'
            ? startOfMinute(dateTime)
            : typeOf === 'endOfDateTimeDay'
              ? endOfDay(dateTime)
              : startOfDay(dateTime)
      ).toISOString();
    }
    const date = isDate(value) ? value : parse(value, DATE_FORMAT, new Date());
    return (typeOf === 'endOfDay' ? endOfDay(date) : startOfDay(date)).toISOString();
  } catch (e) {
    return undefined;
  }
};

export const stringifyUrlFilterValues = <T extends FilterValues>(
  initialValues: T,
  currentValues: T,
  withTime?: boolean,
): ParsedUrlQueryInput => {
  let result: ParsedUrlQueryInput = {};

  Object.keys(initialValues).map((key) => {
    const initialValue = stringifyUrlValue(initialValues[key], withTime);
    const currentValue = stringifyUrlValue(currentValues[key], withTime);
    if (currentValue !== initialValue) {
      result[key] = currentValue;
    }
  });

  return result;
};

export default stringifyUrlFilterValues;
