/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Location object status
 * @export
 * @enum {string}
 */

export enum LocationStatusEnum {
    New = 'new',
    Active = 'active',
    Inactive = 'inactive',
    Failed = 'failed',
    Creating = 'creating',
    Deleting = 'deleting',
    Updating = 'updating',
    Activating = 'activating',
    Deactivating = 'deactivating'
}



