import { InputVariant, Textarea, TextareaProps } from '@pluxee-design-system/core';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { memo } from 'react';

interface TextareaControlProps
  extends Omit<TextareaProps, 'isDisabled' | 'name' | 'value' | 'variant'> {
  name: string;
  readonly?: boolean;
  skipVariant?: boolean;
}

// TODO: get rid-of formik
const TextareaControl = ({
  name,
  readonly,
  field,
  skipVariant = false,
  ...textareaProps
}: TextareaControlProps & WithFastFieldProps<string>) => {
  const hasError = Boolean(field.error);

  return (
    <Textarea
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
      {...textareaProps}
      variant={
        skipVariant
          ? undefined
          : textareaProps.isReadOnly || readonly
            ? InputVariant.READONLY
            : field.isTouched && hasError
              ? InputVariant.ERROR
              : field.isTouched
                ? InputVariant.SUCCESS
                : InputVariant.FILLED
      }
      helpText={field.isTouched && hasError ? field.error : textareaProps.helpText}
    />
  );
};

const MemoizedTextareaControl = memo(TextareaControl);

export default withFastField(MemoizedTextareaControl);
