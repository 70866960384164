import { Box } from '@chakra-ui/react';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuList,
  Icons,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { HamburgerMenuContext } from './HamburgerMenuContext';

interface HamburgerMenuProps {
  navigation?: ReactNode;
}

const headerHeight = { base: 'calc(4rem - 1px)', md: 'calc(5rem - 1px)' };

// TODO: remove when Hamburger icon officially added to Header
const HamburgerMenu = ({ navigation }: HamburgerMenuProps) => {
  const [contentToDisplay, setContentToDisplay] = useState(() => navigation);

  const handleSetContentToDisplay = useCallback(
    (e?: ReactNode) => {
      setContentToDisplay(e ?? navigation);
    },
    [navigation],
  );

  useEffect(() => {
    setContentToDisplay(navigation);
  }, [navigation, setContentToDisplay]);

  return (
    <HamburgerMenuContext.Provider value={handleSetContentToDisplay}>
      <Box h={headerHeight} position="relative" sx={{ '> button': { height: headerHeight } }}>
        <DropdownMenu variant="navigation" placement="bottom-end" offset={[0, 1]}>
          {({ isOpen }) => (
            <>
              <DropdownMenuButton
                dropDownIconVisible={false}
                icon={
                  <Box h="6" color="semantic.interactive.primary">
                    <SvgSpriteConsumer size={24} svgId={Icons.MENU24} />
                  </Box>
                }
                isOpen={isOpen}
              />
              <DropdownMenuList>{contentToDisplay}</DropdownMenuList>
            </>
          )}
        </DropdownMenu>
      </Box>
    </HamburgerMenuContext.Provider>
  );
};

export default HamburgerMenu;
