import { Text, TextProps } from '@chakra-ui/react';

const HtmlText = ({ children, variant, ...rest }: TextProps) => {
  return (
    <Text
      variant={variant}
      {...rest}
      sx={{
        '> p + p': { marginTop: 4 },
        '> p + h2': { marginTop: 4 },
        '> p + h3': { marginTop: 4 },
        '> h2 + p': { marginTop: 4 },
        '> h2 + h3': { marginTop: 4 },
        '> h3 + p': { marginTop: 4 },
        h2: { textStyle: 'title.desktopSection' },
        h3: { textStyle: 'title.mobileCard' },
        a: {
          borderBottom: '.125rem solid',
          color: 'semantic.cta.secondary',
          textStyle: variant,
          textDecoration: 'none',
          textTransform: 'none',
          _hover: {
            color: 'semantic.text.hover',
          },
          _active: {
            color: 'semantic.cta.secondaryPressed',
          },
          _focusVisible: {
            outlineColor: 'semantic.outline.focus',
          },
        },
      }}
    >
      {children}
    </Text>
  );
};

export default HtmlText;
