import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { Paginator } from 'generated';
import { last } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import useSWRInfinite, { SWRInfiniteConfiguration, SWRInfiniteResponse } from 'swr/infinite';
import { defaultSWRConfig } from './useRequest';

export const defaultSWRInfiniteConfig: SWRInfiniteConfiguration = {
  ...defaultSWRConfig,
  revalidateAll: false,
  revalidateFirstPage: false, // default: true
  revalidateIfStale: true, // default: true
  dedupingInterval: 30 * 1000, // default: 2000ms
};

export const onMountSWRInfiniteConfig: SWRInfiniteConfiguration = {
  ...defaultSWRInfiniteConfig,
  revalidateFirstPage: true, // default: true
  revalidateOnFocus: false, // default: true
  revalidateOnReconnect: true, // default: true
  revalidateOnMount: true, // default: undefined
  dedupingInterval: 500, // default: 2000ms
  persistSize: false, // default: false
};

export interface PagedResponse<Data> {
  entries: Array<Data>;
  meta?: Paginator;
}

type AxiosPagedPromise<Data> = AxiosPromise<PagedResponse<Data>>;
type AxiosPagedResponse<Data> = AxiosResponse<PagedResponse<Data>>;

export interface PagedResponseType<Data, Error>
  extends Omit<SWRInfiniteResponse<AxiosPagedResponse<Data>, Error>, 'data'> {
  data: Data[];
  hasNextPage: boolean;
  isEmpty: boolean;
  isLoadingInitialPage: boolean;
  isLoadingNextPage: boolean;
  isLoadingPage: boolean;
  isOK: boolean;
  loadNextPage: (startOffset: number, endOffset: number) => Promise<void>;
  meta?: Paginator;
  response?: AxiosPagedResponse<Data>[];
}

/**
 * Reusable hook to co-operate with useSWRInfinite and generated OpenAPI codebase.
 *
 * @param api A function to execute OpenAPI endpoint.
 * @param opt A list of values to be passed to the function.
 * @param enabled Conditional data fetching.
 * @param swrConfig Optionally SWR Infinite configuration.
 * @param pageSize Size of a paginator (default=20).
 */
const usePagedRequest = <
  E,
  T extends (...options: any) => AxiosPagedPromise<E>,
  P extends Parameters<T> = Parameters<T>,
>(
  api: T,
  opt: (pageIndex: number, pageSize: number) => P,
  enabled: boolean = true,
  pageSize: number = 20,
  swrConfig: SWRInfiniteConfiguration = defaultSWRInfiniteConfig,
): PagedResponseType<E, AxiosError> => {
  const isFetching = useRef(false);

  const getKey = (pageIndex: number, previousPageResponse: AxiosPagedResponse<E> | null) => {
    if (enabled) {
      const prevMeta = previousPageResponse?.data?.meta;
      const prevData = previousPageResponse?.data?.entries;

      // reached the end
      if (prevData?.length === 0) return null;

      // to not fetch multiple pages at once
      if (isFetching.current && pageIndex) return null;

      // already have all data
      if (prevData && prevData?.length < pageSize) return null;

      // cursor to nextPage is nullish
      if (prevMeta && prevMeta.nextPage === null) return null;

      // key stored in memory-cache, nested arrays are not properly cached !!!
      return [api.name, pageIndex, JSON.stringify(opt(pageIndex, pageSize))];
    }
    return null;
  };

  const fetcher = async (_apiName: string, pageIndex: number, _options: string) => {
    let value: AxiosPagedResponse<E>;
    try {
      isFetching.current = true;
      // cannot use JSON.parse(options), undefined value is not preserved but is required in API
      // JSON.stringify([undefined, 123]) -> JSON.parse('[null, 123]') -> [null, 123]
      // we've to evaluate opt() once more :-/
      value = await api(...opt(pageIndex + 1, pageSize));
      if (isFetching.current) {
        isFetching.current = false;
      }
    } catch (e) {
      if (isFetching.current) {
        isFetching.current = false;
      }
      throw e;
    }
    return value;
  };

  const {
    data: response,
    error,
    isValidating,
    setSize,
    size,
    ...rest
  } = useSWRInfinite<AxiosPagedResponse<E>, AxiosError>(getKey, fetcher, swrConfig);

  const firstPage = response?.[0]?.data;
  const firstPageData = firstPage?.entries;
  const lastPage = last(response)?.data;
  const lastPageData = lastPage?.entries;
  const lastPageMeta = lastPage?.meta;

  const isLoadingInitialPage = !response && !error;
  const isLoadingNextPage =
    isLoadingInitialPage ||
    (isValidating && size > 1 && Boolean(response) && typeof response?.[size - 1] === 'undefined');
  const isEmpty = !(firstPageData && firstPageData.length > 0) || Boolean(error);
  // if meta.nextPage is defined, check its value, otherwise check data.length
  const hasNextPage = Boolean(
    isLoadingInitialPage ||
      (lastPageMeta && lastPageMeta.nextPage !== undefined && lastPageMeta.totalPages !== undefined
        ? lastPageMeta.nextPage !== null && lastPageMeta.nextPage <= lastPageMeta.totalPages
        : lastPageData && lastPageData.length === pageSize),
  );

  const loadNextPage = useCallback(
    async (_start: number, _end: number) => {
      if (isLoadingNextPage || isFetching.current) return;

      await setSize((prevSize) => prevSize + 1);
    },
    [isLoadingNextPage, setSize],
  );

  const data = useMemo(
    () =>
      (response || []).reduce((result, page) => {
        result.push(...page.data.entries);
        return result;
      }, [] as E[]),
    [response],
  );

  return {
    ...rest,
    data,
    error,
    hasNextPage,
    isEmpty,
    isLoadingInitialPage,
    isLoadingNextPage,
    isLoadingPage: isLoadingInitialPage || isLoadingNextPage,
    isValidating,
    isOK: Boolean(!error && lastPage),
    loadNextPage,
    meta: firstPage?.meta,
    response,
    setSize,
    size,
  };
};

export default usePagedRequest;
