import { FieldHelperProps, FieldHookConfig, FieldInputProps, useField } from 'formik';
import { useRef } from 'react';

const useFastField = <Val = any>(propsOrFieldName: string | FieldHookConfig<Val>) => {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName);

  // field
  const fieldRef = useRef<FieldInputProps<Val>>();
  fieldRef.current = field;

  const makeFieldShim = ({ name, checked, value }: FieldInputProps<Val>) => ({
    name,
    checked,
    value,
    onBlur: (args: any) => fieldRef.current?.onBlur(args),
    onChange: (args: any) => fieldRef.current?.onChange(args),
  });

  const fieldShim = useRef(makeFieldShim(field));

  if (fieldShim.current.value !== field.value || fieldShim.current.name !== field.name) {
    fieldShim.current = makeFieldShim(field);
  }

  // On every render save the newest helpers to actualHelpersRef
  const actualHelpersRef = useRef<FieldHelperProps<Val>>(helpers);
  actualHelpersRef.current.setValue = helpers.setValue;
  actualHelpersRef.current.setTouched = helpers.setTouched;
  actualHelpersRef.current.setError = helpers.setError;

  const consistentHelpersRef = useRef<FieldHelperProps<Val>>({
    setValue: (value: Val, shouldValidate?: boolean | undefined) =>
      actualHelpersRef.current.setValue(value, shouldValidate),
    setTouched: (value: boolean, shouldValidate?: boolean) =>
      actualHelpersRef.current.setTouched(value, shouldValidate),
    setError: (value?: string) => actualHelpersRef.current.setError(value),
  });

  return [fieldShim.current, meta, consistentHelpersRef.current] as const;
};

export default useFastField;
