import { ParsedUrlQuery } from 'querystring';
import { mapValues, merge, omit, pick } from 'lodash';
import { FilterValues } from './types';

const sortingKeys: Array<keyof FilterValues> = ['sortBy', 'sortOrder'];

const parseFilterValues = <T extends FilterValues>(
  initialValues: T,
  query: ParsedUrlQuery | undefined = {},
  defaultValues: Partial<T> = {},
): T => {
  const picked = mapValues(pick(query, Object.keys(initialValues)), (value, key) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    if (value === '') return defaultValues[key];
    return value;
  });

  // omit sortBy and sortOrder
  const omitted = omit(picked, sortingKeys);
  let result;

  // has any extra filter been set excluding sorting?
  if (Object.keys(omitted).length > 0) {
    // merge set filters
    result = merge({}, initialValues, picked);
  } else {
    // otherwise use default filters
    result = merge({}, initialValues, defaultValues);
  }

  // merge default and picked sorting together
  const defaultSorts = pick(defaultValues, sortingKeys);
  const pickedSorts = pick(picked, sortingKeys);

  return merge(result, defaultSorts, pickedSorts);
};

export default parseFilterValues;
