/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorMessageResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { PosListResponse } from '../models';
// @ts-ignore
import { PosProductsRequest } from '../models';
// @ts-ignore
import { PosRequest } from '../models';
// @ts-ignore
import { PosResponse } from '../models';
// @ts-ignore
import { PosStatesResponse } from '../models';
// @ts-ignore
import { PosTransferRequest } from '../models';
// @ts-ignore
import { ProductsSelectionResponse } from '../models';
// @ts-ignore
import { SuccessResponse } from '../models';
/**
 * PosApi - axios parameter creator
 * @export
 */
export const PosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Renew the POS certificate
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} email Email address
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posCertRenewal: async (posUid: string, email: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posCertRenewal', 'posUid', posUid)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('posCertRenewal', 'email', email)
            const localVarPath = `/api/pos/{posUid}/cert-renewal`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the new POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posCreate: async (locationUid: string, posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('posCreate', 'locationUid', locationUid)
            // verify required parameter 'posRequest' is not null or undefined
            assertParamExists('posCreate', 'posRequest', posRequest)
            const localVarPath = `/api/location/{locationUid}/pos`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(posRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary POS full detail
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posDetail: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posDetail', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all location related POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posList: async (locationUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationUid' is not null or undefined
            assertParamExists('posList', 'locationUid', locationUid)
            const localVarPath = `/api/location/{locationUid}/pos-list`
                .replace(`{${"locationUid"}}`, encodeURIComponent(String(locationUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer the post to another location
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosTransferRequest} posTransferRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posLocationTransfer: async (posUid: string, posTransferRequest: PosTransferRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posLocationTransfer', 'posUid', posUid)
            // verify required parameter 'posTransferRequest' is not null or undefined
            assertParamExists('posLocationTransfer', 'posTransferRequest', posTransferRequest)
            const localVarPath = `/api/pos/{posUid}/transfer`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(posTransferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionActivate: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posPartitionActivate', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/activate`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Block the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionBlock: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posPartitionBlock', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/block`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Dectivate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionDeactivate: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posPartitionDeactivate', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/deactivate`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the POS products
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosProductsRequest} posProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionProducts: async (posUid: string, posProductsRequest: PosProductsRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posPartitionProducts', 'posUid', posUid)
            // verify required parameter 'posProductsRequest' is not null or undefined
            assertParamExists('posPartitionProducts', 'posProductsRequest', posProductsRequest)
            const localVarPath = `/api/pos/{posUid}/products`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(posProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unblock the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionUnblock: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posPartitionUnblock', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/unblock`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all products on contract and relation state to POS
         * @summary List contract products with POS relation state
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posProductsRead: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posProductsRead', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/products`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary State of the latest action for each type
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posStates: async (posUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posUid' is not null or undefined
            assertParamExists('posStates', 'posUid', posUid)
            const localVarPath = `/api/pos/{posUid}/states`
                .replace(`{${"posUid"}}`, encodeURIComponent(String(posUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate the POS data
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posValidate: async (posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'posRequest' is not null or undefined
            assertParamExists('posValidate', 'posRequest', posRequest)
            const localVarPath = `/api/pos-validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(posRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PosApi - functional programming interface
 * @export
 */
export const PosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Renew the POS certificate
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} email Email address
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posCertRenewal(posUid: string, email: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posCertRenewal(posUid, email, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create the new POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posCreate(locationUid: string, posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posCreate(locationUid, posRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary POS full detail
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posDetail(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posDetail(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all location related POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posList(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posList(locationUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Transfer the post to another location
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosTransferRequest} posTransferRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posLocationTransfer(posUid: string, posTransferRequest: PosTransferRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posLocationTransfer(posUid, posTransferRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posPartitionActivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posPartitionActivate(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Block the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posPartitionBlock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posPartitionBlock(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Dectivate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posPartitionDeactivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posPartitionDeactivate(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the POS products
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosProductsRequest} posProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posPartitionProducts(posUid: string, posProductsRequest: PosProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posPartitionProducts(posUid, posProductsRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unblock the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posPartitionUnblock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posPartitionUnblock(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all products on contract and relation state to POS
         * @summary List contract products with POS relation state
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posProductsRead(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsSelectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posProductsRead(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary State of the latest action for each type
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posStates(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PosStatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posStates(posUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validate the POS data
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async posValidate(posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.posValidate(posRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PosApi - factory interface
 * @export
 */
export const PosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PosApiFp(configuration)
    return {
        /**
         * 
         * @summary Renew the POS certificate
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {string} email Email address
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posCertRenewal(posUid: string, email: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.posCertRenewal(posUid, email, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the new POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posCreate(locationUid: string, posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posCreate(locationUid, posRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary POS full detail
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posDetail(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posDetail(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all location related POS
         * @param {string} locationUid Location &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posList(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosListResponse> {
            return localVarFp.posList(locationUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer the post to another location
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosTransferRequest} posTransferRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posLocationTransfer(posUid: string, posTransferRequest: PosTransferRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posLocationTransfer(posUid, posTransferRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionActivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posPartitionActivate(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Block the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionBlock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posPartitionBlock(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Dectivate the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionDeactivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posPartitionDeactivate(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the POS products
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {PosProductsRequest} posProductsRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionProducts(posUid: string, posProductsRequest: PosProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posPartitionProducts(posUid, posProductsRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unblock the POS
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posPartitionUnblock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse> {
            return localVarFp.posPartitionUnblock(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * List all products on contract and relation state to POS
         * @summary List contract products with POS relation state
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posProductsRead(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ProductsSelectionResponse> {
            return localVarFp.posProductsRead(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary State of the latest action for each type
         * @param {string} posUid POS &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posStates(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosStatesResponse> {
            return localVarFp.posStates(posUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate the POS data
         * @param {PosRequest} posRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        posValidate(posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.posValidate(posRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PosApi - interface
 * @export
 * @interface PosApi
 */
export interface PosApiInterface {
    /**
     * 
     * @summary Renew the POS certificate
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {string} email Email address
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posCertRenewal(posUid: string, email: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Create the new POS
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {PosRequest} posRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posCreate(locationUid: string, posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary POS full detail
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posDetail(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary List all location related POS
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posList(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosListResponse>;

    /**
     * 
     * @summary Transfer the post to another location
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {PosTransferRequest} posTransferRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posLocationTransfer(posUid: string, posTransferRequest: PosTransferRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary Activate the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posPartitionActivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary Block the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posPartitionBlock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary Dectivate the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posPartitionDeactivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary Edit the POS products
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {PosProductsRequest} posProductsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posPartitionProducts(posUid: string, posProductsRequest: PosProductsRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * 
     * @summary Unblock the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posPartitionUnblock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosResponse>;

    /**
     * List all products on contract and relation state to POS
     * @summary List contract products with POS relation state
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posProductsRead(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ProductsSelectionResponse>;

    /**
     * 
     * @summary State of the latest action for each type
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posStates(posUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<PosStatesResponse>;

    /**
     * 
     * @summary Validate the POS data
     * @param {PosRequest} posRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApiInterface
     */
    posValidate(posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

}

/**
 * PosApi - object-oriented interface
 * @export
 * @class PosApi
 * @extends {BaseAPI}
 */
export class PosApi extends BaseAPI implements PosApiInterface {
    /**
     * 
     * @summary Renew the POS certificate
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {string} email Email address
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posCertRenewal(posUid: string, email: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posCertRenewal(posUid, email, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create the new POS
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {PosRequest} posRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posCreate(locationUid: string, posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posCreate(locationUid, posRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary POS full detail
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posDetail(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posDetail(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all location related POS
     * @param {string} locationUid Location &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posList(locationUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posList(locationUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Transfer the post to another location
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {PosTransferRequest} posTransferRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posLocationTransfer(posUid: string, posTransferRequest: PosTransferRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posLocationTransfer(posUid, posTransferRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posPartitionActivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posPartitionActivate(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Block the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posPartitionBlock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posPartitionBlock(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Dectivate the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posPartitionDeactivate(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posPartitionDeactivate(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the POS products
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {PosProductsRequest} posProductsRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posPartitionProducts(posUid: string, posProductsRequest: PosProductsRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posPartitionProducts(posUid, posProductsRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unblock the POS
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posPartitionUnblock(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posPartitionUnblock(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all products on contract and relation state to POS
     * @summary List contract products with POS relation state
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posProductsRead(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posProductsRead(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary State of the latest action for each type
     * @param {string} posUid POS &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posStates(posUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posStates(posUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validate the POS data
     * @param {PosRequest} posRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PosApi
     */
    public posValidate(posRequest: PosRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return PosApiFp(this.configuration).posValidate(posRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
