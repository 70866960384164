import useLocalStorage from 'data/useLocalStorage';
import { useCallback } from 'react';

const useDismiss = <T>(key: string, defaultValue: T | null, dismissId: T | null) => {
  const { value, setValue } = useLocalStorage<T | null>(key, defaultValue);
  const handleDismiss = useCallback(() => setValue(dismissId), [setValue, dismissId]);
  return {
    handleDismiss,
    isDismissed: value === dismissId,
  };
};

export default useDismiss;
