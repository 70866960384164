/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractFileListResponse } from '../models';
/**
 * ContractFileApi - axios parameter creator
 * @export
 */
export const ContractFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download contract file document
         * @param {string} fileUid Contract file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileDownloadDocument: async (fileUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileUid' is not null or undefined
            assertParamExists('contractFileDownloadDocument', 'fileUid', fileUid)
            const localVarPath = `/api/contract/documents/{fileUid}`
                .replace(`{${"fileUid"}}`, encodeURIComponent(String(fileUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a new contract documents
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileGetDocuments: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractFileGetDocuments', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/documents`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create request for new contract PDF files
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileRefreshDocument: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractFileRefreshDocument', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/documents`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a file assiged to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} fileUid File &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFile: async (contractUid: string, fileUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractGetFile', 'contractUid', contractUid)
            // verify required parameter 'fileUid' is not null or undefined
            assertParamExists('contractGetFile', 'fileUid', fileUid)
            const localVarPath = `/api/contract/{contractUid}/file/{fileUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"fileUid"}}`, encodeURIComponent(String(fileUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractFileApi - functional programming interface
 * @export
 */
export const ContractFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download contract file document
         * @param {string} fileUid Contract file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractFileDownloadDocument(fileUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a new contract documents
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractFileListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractFileGetDocuments(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create request for new contract PDF files
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractFileRefreshDocument(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractFileRefreshDocument(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download a file assiged to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} fileUid File &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractGetFile(contractUid: string, fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractGetFile(contractUid, fileUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractFileApi - factory interface
 * @export
 */
export const ContractFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Download contract file document
         * @param {string} fileUid Contract file &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractFileDownloadDocument(fileUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a new contract documents
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractFileListResponse> {
            return localVarFp.contractFileGetDocuments(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create request for new contract PDF files
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFileRefreshDocument(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractFileRefreshDocument(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a file assiged to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} fileUid File &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractGetFile(contractUid: string, fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractGetFile(contractUid, fileUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractFileApi - interface
 * @export
 * @interface ContractFileApi
 */
export interface ContractFileApiInterface {
    /**
     * 
     * @summary Download contract file document
     * @param {string} fileUid Contract file &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApiInterface
     */
    contractFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Request a new contract documents
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApiInterface
     */
    contractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractFileListResponse>;

    /**
     * 
     * @summary Create request for new contract PDF files
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApiInterface
     */
    contractFileRefreshDocument(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Download a file assiged to contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} fileUid File &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApiInterface
     */
    contractGetFile(contractUid: string, fileUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

}

/**
 * ContractFileApi - object-oriented interface
 * @export
 * @class ContractFileApi
 * @extends {BaseAPI}
 */
export class ContractFileApi extends BaseAPI implements ContractFileApiInterface {
    /**
     * 
     * @summary Download contract file document
     * @param {string} fileUid Contract file &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApi
     */
    public contractFileDownloadDocument(fileUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractFileApiFp(this.configuration).contractFileDownloadDocument(fileUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a new contract documents
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApi
     */
    public contractFileGetDocuments(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractFileApiFp(this.configuration).contractFileGetDocuments(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create request for new contract PDF files
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApi
     */
    public contractFileRefreshDocument(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractFileApiFp(this.configuration).contractFileRefreshDocument(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a file assiged to contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} fileUid File &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractFileApi
     */
    public contractGetFile(contractUid: string, fileUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractFileApiFp(this.configuration).contractGetFile(contractUid, fileUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
