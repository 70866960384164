import { Input, InputProps, InputVariant } from '@pluxee-design-system/core';
import { memo } from 'react';
import withFastField, { WithFastFieldProps } from './withFastField';

interface InputControlProps extends Omit<InputProps, 'isDisabled' | 'name' | 'value' | 'variant'> {
  name: string;
  readonly?: boolean;
  skipVariant?: boolean;
  skipSuccess?: boolean;
}

// TODO: get rid-of formik
const InputControl = ({
  disabled,
  name,
  readonly,
  skipVariant = false,
  skipSuccess = false,
  field,
  ...inputProps
}: InputControlProps & WithFastFieldProps<string>) => {
  const hasError = Boolean(field.error);

  return (
    <Input
      name={field.name}
      onBlur={inputProps.onBlur ?? field.onBlur}
      onChange={field.onChange}
      value={field.value}
      {...inputProps}
      disabled={inputProps.isReadOnly || readonly ? undefined : disabled}
      variant={
        skipVariant
          ? undefined
          : inputProps.isReadOnly || readonly
          ? InputVariant.READONLY
          : field.isTouched && hasError
          ? InputVariant.ERROR
          : field.isTouched && !skipSuccess
          ? InputVariant.SUCCESS
          : InputVariant.FILLED
      }
      helpText={field.isTouched && hasError ? field.error : inputProps.helpText}
    />
  );
};

const MemoizedInputControl = memo(InputControl);

export default withFastField(MemoizedInputControl);
