import { formatPrice, PriceAndFees } from 'components/Products/PayoutCard/utils';
import env from 'env';
import { isEqual } from 'lodash';
import Script from 'next/script';
import { CountryCode } from 'types/config';
import { getFullBasePath } from 'utils/router';
import { Event, EventCategory, PageCategory, Variables } from './types';

const separators = {
  comma: ', ',
  pipe: ' | ',
  slash: ' / ',
  semicolon: '; ',
};

const requiredVariables: Array<keyof Variables> = [
  'site_env',
  'site_browser',
  'site_country',
  'site_type',
  'site_sourcepage',
  'site_lang',
  'site_platform',
  'site_resolution',
];

const gtm = {
  hasRequiredVariables: false,
  variables: {
    site_env: undefined,
    site_browser: undefined,
    site_country: undefined,
    site_type: undefined,
    site_sourcepage: undefined,
    site_ciam_id: undefined,
    site_company_id: undefined,
    site_lang: undefined,
    site_platform: undefined,
    site_resolution: undefined,
  } as Partial<Variables>,
  countryCode: undefined as undefined | CountryCode,

  init(gtmId?: string, countryCode?: CountryCode, hasRequiredVariables?: boolean | null) {
    // country specific
    if (countryCode) this.countryCode = countryCode;
    if (hasRequiredVariables !== null && hasRequiredVariables !== undefined)
      this.hasRequiredVariables = hasRequiredVariables;

    // to disable GTM on local
    if (!gtmId || !this.hasRequiredVariables || env.env === 'local') return null;
    return (
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function (w, d, s, l, i) {
        w[l] = w[l] || [${JSON.stringify(this.variables)}];
        w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        var f = d.getElementsByTagName(s)[0];
        var j = d.createElement(s);
        var dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${gtmId}')`}
      </Script>
    );
  },
  setVariables(variables: Partial<Variables>) {
    if (!this.hasRequiredVariables) {
      this.variables = { ...this.variables, ...variables };
      return requiredVariables.every((key) => Boolean(this.variables[key]) ?? false);
    } else {
      const newVariables = { ...this.variables, ...variables };
      if (!isEqual(newVariables, this.variables)) {
        this.variables = newVariables;
        this.push(this.variables);
      }
      return null;
    }
  },
  track(event: Event) {
    const fullPath = getFullBasePath();
    this.push({
      ...event,
      event: 'gtm.custom_event',
      event_label:
        fullPath && event.event_label.startsWith('/')
          ? `${fullPath}${event.event_label}`
          : event.event_label,
    });
  },
  push(data: Record<string, any>) {
    // TODO: remove when NNGM-487 tested
    if (env.env !== 'local' && env.env !== 'production') console.log(data);

    if (window !== undefined && window.dataLayer !== undefined) {
      window.dataLayer.push(data);
    }
  },
  // Events
  trackClickSupport() {
    this.track({
      event_name: 'click_support',
      event_category: EventCategory.Support,
      page_category: PageCategory.Home,
      event_label: '/support',
    });
  },
  trackSupportSearch(event_search_label: string, event_results_number: number) {
    this.track({
      event_name: 'support_search',
      event_category: EventCategory.Support,
      page_category: PageCategory.FAQ,
      event_label: '/support/search',
      event_results_number,
      event_search_label,
    });
  },
  trackClickContactUs(event_position: 'Nav' | 'Question' | 'Bottom', event_faq_label?: string) {
    this.track({
      event_name: 'click_contact_us',
      event_category: EventCategory.Support,
      page_category: PageCategory.FAQ,
      event_label: '/contact-us',
      event_position,
      event_faq_label,
    });
  },
  trackClickSubmitContactForm(
    event_topic: string[],
    event_subtopic: string[],
    event_message?: string,
  ) {
    this.track({
      event_name: 'click_submit_contact_form',
      event_category: EventCategory.Support,
      page_category: PageCategory.ContactForm,
      event_label: '/contact-us',
      event_topic: event_topic.join() || undefined,
      event_subtopic: event_subtopic.join() || undefined,
      event_message,
    });
  },
  trackClickSelectLanguage(event_language: string) {
    this.track({
      event_name: 'click_select_language',
      event_category: EventCategory.LanguageSelection,
      page_category: PageCategory.Home,
      event_label: '/',
      event_language,
    });
  },
  trackClickLogin() {
    this.track({
      event_name: 'click_login',
      event_category: EventCategory.Login,
      page_category: PageCategory.Home,
      event_label: env.sodexoConnectUrl,
    });
  },
  trackClickManageCompany() {
    this.track({
      event_name: 'click_manage_company',
      event_category: EventCategory.ManageCompany,
      page_category: PageCategory.Home,
      event_label: '/dashboard',
    });
  },
  trackClickRegisterCompany() {
    this.track({
      event_name: 'click_register_company',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Home,
      event_label: '/affiliation',
    });
  },
  trackClickRegisterLocation() {
    // TODO: implement when Vlad returns from a vacation
  },
  trackClickFindMe(event_message_code?: string, event_message?: string) {
    this.track({
      event_name: 'click_find_me',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.CompanyID,
      event_label: '/affiliation',
      event_message_code: event_message_code ?? 'OK',
      event_message: event_message ?? 'OK',
    });
  },
  trackClickCompanyConfirm(event_activity_selected: string[]) {
    this.track({
      event_name: 'click_company_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.CompanyInfo,
      event_label: '/affiliation',
      event_activity_selected: event_activity_selected.join(separators.pipe) || undefined,
    });
  },
  trackClickCompanyBack(event_activity_selected: string[]) {
    this.track({
      event_name: 'click_company_back',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.CompanyInfo,
      event_label: '/affiliation',
      event_activity_selected: event_activity_selected.join(separators.pipe) || undefined,
    });
  },
  trackClickContactConfirm(event_contact_position: string[], event_contact_language?: string[]) {
    this.track({
      event_name: 'click_contact_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Contact,
      event_label: '/affiliation',
      event_contact_position: event_contact_position.join(separators.pipe) || undefined,
      ...(this.countryCode === 'be'
        ? { event_contact_language: event_contact_language?.join(separators.pipe) || undefined }
        : {}),
    });
  },
  trackClickContactBack(event_contact_position: string[], event_contact_language?: string[]) {
    this.track({
      event_name: 'click_contact_back',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Contact,
      event_label: '/affiliation',
      event_contact_position: event_contact_position.join(separators.pipe) || undefined,
      ...(this.countryCode === 'be'
        ? { event_contact_language: event_contact_language?.join(separators.pipe) || undefined }
        : {}),
    });
  },
  trackClickProductSelect(event_product_name: string, event_product_activity: string[] = []) {
    this.track({
      event_name: 'click_product_select',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_product_name,
      event_product_activity: event_product_activity.join(separators.pipe) || undefined,
    });
  },
  trackClickProductOfferSelect(
    event_product_name: string,
    { price, fees, feesByKey }: PriceAndFees,
    event_payout_frequency?: string | null,
    event_payout_channel?: string | null,
    event_product_tier?: string,
  ) {
    const priceFormatted = formatPrice(price);
    this.track({
      event_name: 'click_product_offer_select',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_product_name,
      ...(this.countryCode === 'cz'
        ? {
            event_payout_frequency: event_payout_frequency || undefined,
            event_payout_channel: event_payout_channel || undefined,
            // see components/Products/PayoutCard/CzPayoutCard.tsx
            event_payout_commission: priceFormatted
              ? `${priceFormatted}${
                  fees.length > 0
                    ? `${separators.pipe}${fees.map((p) => formatPrice(p)).join(separators.slash)}`
                    : ''
                }`
              : undefined,
          }
        : {}),
      ...(this.countryCode === 'be'
        ? {
            event_product_tier: event_product_tier || undefined,
            // see components/Products/PayoutCard/BePayoutCard.tsx
            event_payout_commission: [
              [
                formatPrice(feesByKey['trxCutOff'] ? feesByKey['trxCostAboveCutOff'] : price, '-'),
                formatPrice(feesByKey['monthlyFee'], '-'),
                formatPrice(feesByKey['activationFee'], '-'),
                feesByKey['trxCutOff']?.fee > 0
                  ? // <cutOff: price (minCost)
                    `<${formatPrice(feesByKey['trxCutOff'], '0')}: ${formatPrice(
                      price,
                      '-',
                    )} (${formatPrice(feesByKey['trxMinCost'], '-')})`
                  : '-',
              ].join(separators.slash),
              formatPrice(feesByKey['onlinePrice'], '-'),
            ].join(separators.pipe),
          }
        : {}),
    });
  },
  trackClickProductOfferApply(
    event_product_name: string,
    { price, fees, feesByKey }: PriceAndFees,
    event_payout_frequency?: string | null,
    event_payout_channel?: string | null,
    event_product_tier?: string,
  ) {
    const priceFormatted = formatPrice(price);
    this.track({
      event_name: 'click_product_offer_apply',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_product_name,
      ...(this.countryCode === 'cz'
        ? {
            event_payout_frequency: event_payout_frequency ?? undefined,
            event_payout_channel: event_payout_channel ?? undefined,
            // see components/Products/PayoutCard/CzPayoutCard.tsx
            event_payout_commission: priceFormatted
              ? `${priceFormatted}${
                  fees.length > 0
                    ? `${separators.pipe}${fees.map((p) => formatPrice(p)).join(separators.slash)}`
                    : ''
                }`
              : undefined,
          }
        : {}),
      ...(this.countryCode === 'be'
        ? {
            event_product_tier: event_product_tier || undefined,
            // see components/Products/PayoutCard/BePayoutCard.tsx
            event_payout_commission: [
              [
                formatPrice(feesByKey['trxCutOff'] ? feesByKey['trxCostAboveCutOff'] : price, '-'),
                formatPrice(feesByKey['monthlyFee'], '-'),
                formatPrice(feesByKey['activationFee'], '-'),
                feesByKey['trxCutOff']?.fee > 0
                  ? // <cutOff: price (minCost)
                    `<${formatPrice(feesByKey['trxCutOff'], '0')}: ${formatPrice(
                      price,
                      '-',
                    )} (${formatPrice(feesByKey['trxMinCost'], '-')})`
                  : '-',
              ].join(separators.slash),
              formatPrice(feesByKey['onlinePrice'], '-'),
            ].join(separators.pipe),
          }
        : {}),
    });
  },
  trackClickProductSelectConfirm(event_selected_product_number: number) {
    this.track({
      event_name: 'click_product_select_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_selected_product_number,
    });
  },
  trackClickProductSelectBack() {
    this.track({
      event_name: 'click_product_select_back',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
    });
  },
  trackClickProductDelete(event_product_name: string) {
    this.track({
      event_name: 'click_product_delete',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_product_name,
    });
  },
  trackClickProductEdit(event_product_name: string) {
    this.track({
      event_name: 'click_product_edit',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Product,
      event_label: '/affiliation',
      event_product_name,
    });
  },
  trackClickPaymentConfirm() {
    this.track({
      event_name: 'click_payment_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Payment,
      event_label: '/affiliation',
    });
  },
  trackClickPaymentBack() {
    this.track({
      event_name: 'click_payment_back',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Payment,
      event_label: '/affiliation',
    });
  },
  trackClickContractConfirm() {
    this.track({
      event_name: 'click_contract_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Contract,
      event_label: '/onboarding',
    });
  },
  trackClickContractBack() {
    this.track({
      event_name: 'click_contract_back',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Contract,
      event_label: '/affiliation',
    });
  },
  // BEL-only
  trackClickLocationSelectType(event_location_type: string) {
    this.track({
      event_name: 'click_location_selecttype',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Location,
      event_label: '/onboarding',
      event_location_type: event_location_type?.toLowerCase(),
    });
  },
  trackClickLocationAddTerminal() {
    this.track({
      event_name: 'click_location_addterminal',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Location,
      event_label: '/onboarding',
    });
  },
  trackClickLocationConfirm(
    params:
      | {
          event_location_name: string;
          event_activity: string[];
          event_accepted_product: string[];
          event_payment_option: Array<{ name?: string; options?: string[][] }>;
          event_terminal_created_number?: number;
        }
      | {
          event_location_name: string;
          event_activity: string[][];
          event_accepted_product: string[];
          event_terminal_definition: Array<{ deviceType: string; products: string[] }>;
        }
      | null,
  ) {
    this.track({
      event_name: 'click_location_confirm',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Location,
      event_label: '/onboarding',
      ...(params
        ? 'event_payment_option' in params
          ? {
              event_location_name: params.event_location_name || undefined,
              event_activity: params.event_activity.join(separators.pipe) || undefined,
              event_accepted_product:
                params.event_accepted_product.join(separators.pipe) || undefined,
              event_payment_option:
                params.event_payment_option
                  .map((o) => {
                    const options = o.options
                      ?.filter((op) => op.some((i) => i))
                      ?.map((op) => op.join(separators.comma))
                      ?.join(separators.semicolon);
                    return `${o.name}${o.options ? `:${options ? ` ${options}` : ''}` : ''}`;
                  })
                  .join(separators.pipe) || undefined,
              event_terminal_created_number: params.event_terminal_created_number,
            }
          : {
              event_location_name: params.event_location_name || undefined,
              event_activity:
                params.event_activity
                  .map((row) => row.join(separators.semicolon))
                  .join(separators.pipe) || undefined,
              event_accepted_product:
                params.event_accepted_product.join(separators.pipe) || undefined,
              event_terminal_definition:
                params.event_terminal_definition
                  .map((def) => `${def.deviceType}: ${def.products.join(separators.semicolon)}`)
                  .join(separators.pipe) || undefined,
              event_terminal_created_number: params.event_terminal_definition.length,
            }
        : {}),
    });
  },
  trackClickLocationAnother() {
    this.track({
      event_name: 'click_location_another',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Location,
      event_label: '/onboarding',
    });
  },
  trackClickLocationFinish() {
    this.track({
      event_name: 'click_location_finish',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Location,
      event_label: '/onboarding',
    });
  },
  trackClickRegistrationFinish(event_label = '/dashboard') {
    this.track({
      event_name: 'click_registration_finish',
      event_category: EventCategory.RegisterCompany,
      page_category: PageCategory.Finished,
      event_label,
    });
  },
  trackClickDashboardInfotext(event_label: string) {
    this.track({
      event_name: 'click_dashboard_infotext',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label
    })
  },
  trackClickDashboardBanner(event_label: string) {
    this.track({
      event_name: 'click_dashboard_banner',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label
    })
  },
  trackClickDashboardRemittanceSheet() {
    this.track({
      event_name: 'click_dashboard_remittance_sheet',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/remittance-sheet'
    })
  },
  trackClickDashboardOnlinePayment() {
    this.track({
      event_name: 'click_dashboard_online_payment',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/payments'
    })
  },
  trackClickDashboardFaq() {
    this.track({
      event_name: 'click_dashboard_faq',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/support'
    })
  },
  trackClickDashboardPerformance() {
    this.track({
      event_name: 'click_dashboard_performance',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/performance'
    })
  },
  trackClickDashboardInvoices() {
    this.track({
      event_name: 'click_dashboard_invoices',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/invoices'
    })
  },
  trackClickDashboardTransactions() {
    this.track({
      event_name: 'click_dashboard_transactions',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/transactions'
    })
  },
  trackClickDashboardPackages() {
    this.track({
      event_name: 'click_dashboard_packages',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/packages'
    })
  },
  trackClickDashboardProducts() {
    this.track({
      event_name: 'click_dashboard_products',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/products'
    })
  },
  trackClickDashboardLocations() {
    this.track({
      event_name: 'click_dashboard_locations',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/locations'
    })
  },
  trackClickDashboardEmployees() {
    this.track({
      event_name: 'click_dashboard_employees',
      event_category: EventCategory.SelfCare,
      page_category: PageCategory.Dashboard,
      event_label: '/employees'
    })
  }
};

export default gtm;
