export interface Variables {
  site_env: 'preprod' | 'production';
  site_browser: string;
  site_country: 'cz' | 'be';
  site_type: 'Merchant Portal NGM';
  site_sourcepage: string;
  site_ciam_id: string; // SDXC ID
  site_company_id: string; // Company Identifier
  site_lang: string; // cs-CZ
  site_platform: 'desktop' | 'tablet';
  site_resolution: string; // NxM
}

export enum EventCategory {
  Support = 'Support',
  LanguageSelection = 'Language Selection',
  Login = 'Login',
  ManageCompany = 'Manage Company',
  RegisterCompany = 'Register Company',
  SelfCare = 'Self-care',
}

export enum PageCategory {
  Home = 'Home',
  FAQ = 'FAQ',
  ContactForm = 'Contact Form',
  CompanyID = 'Company ID',
  CompanyInfo = 'Company Info',
  Contact = 'Contact',
  Contract = 'Contract',
  Product = 'Product',
  Payment = 'Payment',
  Location = 'Location',
  Finished = 'Finished',
  Dashboard = 'Dashboard',
}

/*
 * Events
 */
interface CommonEvent<E extends EventCategory, P extends PageCategory> {
  // event: 'gtm.custom_event',
  event_category: E;
  page_category: P;
  event_label: string; // URL of the NEXT link, where a user goes to after the event
}

interface ClickSupportEvent extends CommonEvent<EventCategory.Support, PageCategory.Home> {
  event_name: 'click_support';
}

interface SupportSearchEvent extends CommonEvent<EventCategory.Support, PageCategory.FAQ> {
  event_name: 'support_search';
  event_results_number: number; // number of results
  event_search_label: string; // search query
}

interface ClickContactUsEvent extends CommonEvent<EventCategory.Support, PageCategory.FAQ> {
  event_name: 'click_contact_us';
  event_position: 'Nav' | 'Question' | 'Bottom';
  event_faq_label?: string;
}

interface ClickSubmitContactFormEvent
  extends CommonEvent<EventCategory.Support, PageCategory.ContactForm> {
  event_name: 'click_submit_contact_form';
  event_topic?: string;
  event_subtopic?: string;
  event_message?: string;
}

interface ClickSelectLanguageEvent
  extends CommonEvent<EventCategory.LanguageSelection, PageCategory.Home> {
  event_name: 'click_select_language';
  event_language: string;
}

interface ClickLoginEvent extends CommonEvent<EventCategory.Login, PageCategory.Home> {
  event_name: 'click_login';
}

interface ClickManageCompanyEvent
  extends CommonEvent<EventCategory.ManageCompany, PageCategory.Home> {
  event_name: 'click_manage_company';
}

interface ClickRegisterCompanyEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Home> {
  event_name: 'click_register_company';
}

interface ClickFindMeEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.CompanyID> {
  event_name: 'click_find_me';
  event_message_code: string; // CS code of validation or attribute value of response
  event_message: string; // message of validation
}

interface ClickCompanyConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.CompanyInfo> {
  event_name: 'click_company_confirm';
  event_activity_selected?: string; // x | y | z
}

interface ClickCompanyBackEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.CompanyInfo> {
  event_name: 'click_company_back';
  event_activity_selected?: string; // x | y | z
}

interface ClickContactConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Contact> {
  event_name: 'click_contact_confirm';
  event_contact_position?: string;
  event_contact_language?: string; // BEL-only
}

interface ClickContactBackEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Contact> {
  event_name: 'click_contact_back';
  event_contact_position?: string;
  event_contact_language?: string; // BEL-only
}

interface ClickProductSelectEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_select';
  event_product_name: string;
  event_product_activity?: string; // a | b | c
}

interface ClickProductOfferSelectEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_offer_select';
  event_product_name: string;
  event_payout_frequency?: string;
  event_payout_channel?: string;
  event_payout_commission?: string; // 5,5% | 0,6Kc / 0,2Kc
  event_product_tier?: string;
}

interface ClickProductOfferApplyEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_offer_apply';
  event_product_name: string;
  event_payout_frequency?: string;
  event_payout_channel?: string;
  event_payout_commission?: string; // 5,5% | 0,6Kc / 0,2Kc
  event_product_tier?: string;
}

interface ClickProductSelectConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_select_confirm';
  event_selected_product_number: number;
}

interface ClickProductSelectBackEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_select_back';
}

interface ClickProductSelectRolloutEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_select_rollout';
}

interface ClickProductDeleteEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_delete';
  event_product_name: string;
}

interface ClickProductEditEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Product> {
  event_name: 'click_product_edit';
  event_product_name: string;
}

interface ClickPaymentConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Payment> {
  event_name: 'click_payment_confirm';
}

interface ClickPaymentBackEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Payment> {
  event_name: 'click_payment_back';
}

interface ClickContractConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Contract> {
  event_name: 'click_contract_confirm';
}

interface ClickContractBackEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Contract> {
  event_name: 'click_contract_back';
}

interface ClickLocationStepOneConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_stepone_confirm';
  event_location_name?: string;
  event_activity?: string; // activity; category; subcategory (|) ...
  event_accepted_product?: string; // product1 (|)
}

// BEL-only
interface ClickLocationSelectTypeEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_selecttype';
  event_location_type: string;
}

interface ClickLocationAddTerminalEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_addterminal';
}

interface ClickLocationStepTwoConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_steptwo_confirm';
  event_terminal_definition?: string; // deviceType: productName1; productName2 (|)
  event_terminal_created_number: number;
}

interface ClickLocationConfirmEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_confirm';
  // BEL-only
  event_location_name?: string;
  event_activity?: string; // activity; category; subcategory (|) ...
  event_accepted_product?: string; // product1 (|)
  event_payment_option?: string; // paymentMethod: option1 | option2 (|)
  event_terminal_created_number?: number;
}

interface ClickLocationAnotherEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_another';
}

interface ClickLocationFinishEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Location> {
  event_name: 'click_location_finish';
}

interface ClickRegistrationFinishEvent
  extends CommonEvent<EventCategory.RegisterCompany, PageCategory.Finished> {
  event_name: 'click_registration_finish';
}

interface ClickDashboardInfotextEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_infotext';
}

interface ClickDashboardBannerEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_banner';
}

interface ClickDashboardRemittanceSheetEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_remittance_sheet';
}

interface ClickDashboardOnlinePaymentEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_online_payment';
}

interface ClickDashboardFaqEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_faq';
}

interface ClickDashboardPerformanceEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_performance';
}

interface ClickDashboardInvoicesEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_invoices';
}

interface ClickDashboardTransactionsEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_transactions';
}

interface ClickDashboardPackagesEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_packages';
}

interface ClickDashboardProductsEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_products';
}

interface ClickDashboardLocationsEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_locations';
}

interface ClickDashboardEmployeesEvent
  extends CommonEvent<EventCategory.SelfCare, PageCategory.Dashboard> {
  event_name: 'click_dashboard_employees';
}

export type Event =
  | ClickSupportEvent
  | SupportSearchEvent
  | ClickContactUsEvent
  | ClickSubmitContactFormEvent
  | ClickSelectLanguageEvent
  | ClickLoginEvent
  | ClickManageCompanyEvent
  | ClickRegisterCompanyEvent
  | ClickFindMeEvent
  | ClickCompanyConfirmEvent
  | ClickCompanyBackEvent
  | ClickContactConfirmEvent
  | ClickContactBackEvent
  | ClickProductSelectEvent
  | ClickProductOfferSelectEvent
  | ClickProductOfferApplyEvent
  | ClickProductSelectConfirmEvent
  | ClickProductSelectBackEvent
  | ClickProductSelectRolloutEvent
  | ClickProductDeleteEvent
  | ClickProductEditEvent
  | ClickPaymentConfirmEvent
  | ClickPaymentBackEvent
  | ClickContractConfirmEvent
  | ClickContractBackEvent
  | ClickLocationStepOneConfirmEvent
  | ClickLocationSelectTypeEvent
  | ClickLocationAddTerminalEvent
  | ClickLocationStepTwoConfirmEvent
  | ClickLocationConfirmEvent
  | ClickLocationAnotherEvent
  | ClickLocationFinishEvent
  | ClickRegistrationFinishEvent
  | ClickDashboardInfotextEvent
  | ClickDashboardBannerEvent
  | ClickDashboardRemittanceSheetEvent
  | ClickDashboardOnlinePaymentEvent
  | ClickDashboardFaqEvent
  | ClickDashboardPerformanceEvent
  | ClickDashboardInvoicesEvent
  | ClickDashboardTransactionsEvent
  | ClickDashboardPackagesEvent
  | ClickDashboardProductsEvent
  | ClickDashboardLocationsEvent
  | ClickDashboardEmployeesEvent;
