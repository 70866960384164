/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RemittanceSheetBulkCreateRequest
 */
export interface RemittanceSheetBulkCreateRequest {
    /**
     * uuids of sheets to download in bulk
     * @type {Array<string>}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    remittanceSheetUuids: Array<string> | null;
    /**
     * Indicates whether only IDs should be returned
     * @type {boolean}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    idOnly?: boolean | null;
    /**
     * Indicates whether IDs should be excluded
     * @type {boolean}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    idsExcluded?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    location?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    products?: Array<string> | null;
    /**
     * Fulltext search through Author, Location, Product.
     * @type {string}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    fulltext?: string | null;
    /**
     * Uuid of the author of the remittance sheet
     * @type {string}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    author?: string | null;
    /**
     * Timestamp in ISO-8601 format
     * @type {string}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    createdFrom?: string;
    /**
     * Timestamp in ISO-8601 format
     * @type {string}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    createdTill?: string;
    /**
     * 
     * @type {string}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    sortBy?: RemittanceSheetBulkCreateRequestSortByEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    sortDesc?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    amountFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    amountTill?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RemittanceSheetBulkCreateRequest
     */
    pageSize?: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RemittanceSheetBulkCreateRequestSortByEnum {
    Location = 'location',
    CreatedAt = 'created_at',
    TotalAmount = 'total_amount',
    Author = 'author'
}



