import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';

export const defaultSWRConfig: SWRConfiguration = {
  revalidateIfStale: false, // default: true
  revalidateOnFocus: false, // default: true
  revalidateOnReconnect: false, // default: true
  refreshWhenHidden: false, // default: false
  refreshWhenOffline: false, // default: false
  refreshInterval: 0, // default: 0ms (disabled)
  dedupingInterval: 9999999, // default: 2000ms
  shouldRetryOnError: false, // default: true
};

export const onMountSWRConfig: SWRConfiguration = {
  revalidateOnMount: true, // default: undefined
  revalidateOnReconnect: true, // default: true
  dedupingInterval: 500, // default: 2000ms
}

export type PartialParameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? Partial<P> : never;

/**
 * Infers a return type from a function that returns a Promise type.
 *
 * In: (a: any) => Promise<Data>
 *
 * Out: Data
 */
export type ReturnedType<T extends (...args: any) => AxiosPromise> = ReturnType<T> extends AxiosPromise<
  infer S
>
  ? S
  : never;

export interface ResponseType<Data, Error = AxiosError>
  extends Omit<SWRResponse<AxiosResponse<Data>, Error>, 'data'> {
  data?: Data;
  response?: AxiosResponse<Data>;
  isOK: boolean;
  isLoading: boolean;
}

/**
 * Reusable hook to co-operate with useSWR and generated OpenAPI codebase.
 *
 * @param api A function to execute OpenAPI endpoint.
 * @param opt A list of values to be passed to the function.
 * @param swrConfig Optionally SWR configuration.
 * @param enabled To conditionally fetch a data.
 */
const useRequest = <T extends (...options: any) => AxiosPromise>(
  api: T,
  opt: PartialParameters<T>,
  swrConfig: SWRConfiguration<AxiosResponse<ReturnedType<T>>, AxiosError> = defaultSWRConfig,
  enabled: boolean = true,
): ResponseType<ReturnedType<T>, AxiosError> => {
  const { data: response, ...rest } = useSWR<AxiosResponse<ReturnedType<T>>, AxiosError>(
    enabled ? [api.name, ...opt.toString()] : null,
    () => api(...opt),
    swrConfig,
  );
  const data = response && response.data;

  return {
    ...rest,
    data,
    response,
    isOK: Boolean(!rest.error && data),
    isLoading: Boolean(!rest.error && !data),
  };
};

export default useRequest;
