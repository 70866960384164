/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Partition of the back office request subject
 * @export
 * @enum {string}
 */

export enum EmployeeBRPartEnum {
    Employee = 'employee',
    PersonalData = 'personalData',
    Classification = 'classification'
}



