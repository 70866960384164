import { NextApiRequest } from 'next';
import { CountryCode } from 'types/config';
import countryConfig from '../../../country.config';

const findCountry = (path?: string) => {
  if (path) {
    const countryCodes = countryConfig.countryCodes as Record<string, CountryCode>;
    const normalizedPath = path
      .replace('http://', '')
      .replace('https://', '')
      .replace(/:[0-9]+/, '');
    const host = Object.keys(countryCodes).find((countryPath) =>
      normalizedPath.startsWith(countryPath),
    );
    return host
      ? { host, isoCode: countryCodes[host], isSecure: path.startsWith('https://'), path }
      : undefined;
  }
  return undefined;
};

export function apiBaseUrl() {
  if (typeof window === 'undefined') {
    // Return absolute path when called server side
    return process.env.NEXTAUTH_URL_INTERNAL ?? 'http://127.0.0.1:3000/api/auth';
  }
  // Return relative path when called client side
  return '/api/auth';
}

const detectHost = (
  req: NextApiRequest,
): { isSecure: boolean; isoCode: CountryCode; host: string; path: string } | undefined =>
  findCountry(req.headers.host) ??
  findCountry(req.headers['x-original-host'] as string | undefined) ??
  findCountry(req.headers['x-forwarded-host'] as string | undefined) ??
  findCountry(req.headers.referer);

export default detectHost;
