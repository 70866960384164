import Loader from 'common/Loader';
import useConfig from 'data/useConfig';
import useCurrentLocale from 'data/useCurrentLocale';
import hoistNonReactStatic from 'hoist-non-react-statics';
import useI18nLoader from 'i18n/useI18nLoader';
import { ComponentType, useEffect, useMemo, useRef } from 'react';
import { I18nProps, Namespace } from './types';
import useTranslations from './useTranslations';

/**
 * Gets a translation and injects the translation function to a wrapped component.
 *
 * @param Component Component to be injected with the translation function.
 * @param lazyLoading Lazily load
 * @param namespaces List of namespaces to be fetched from API.
 */
const withFetchNamespaces = <P extends {}>(
  Component: ComponentType<P & I18nProps>,
  lazyLoading: boolean,
  ...namespaces: Namespace[]
): ComponentType<Omit<P, keyof I18nProps>> => {
  function WithNamespaces(props: P) {
    const initialLocale = useRef<string | null>(null);
    const { value: currentLocale } = useCurrentLocale();
    const { isoCode, isLoading } = useConfig();
    const context = useTranslations();
    const { nsReady } = context;
    const loadNamespaces = useI18nLoader();

    useEffect(() => {
      if (initialLocale.current === null && initialLocale.current !== currentLocale)
        initialLocale.current = currentLocale;
      if (currentLocale && isoCode) loadNamespaces(namespaces, currentLocale, isoCode);
    }, [isoCode, currentLocale, loadNamespaces]);

    const tReady = useMemo(
      () =>
        lazyLoading
          ? true
          : (initialLocale.current !== null && initialLocale.current !== currentLocale) ||
            nsReady(isoCode, ...namespaces),
      [currentLocale, isoCode, nsReady],
    );

    if (isLoading) {
      return null;
    }

    if (!tReady) {
      return <Loader />;
    }

    return <Component {...props} {...context} tReady={tReady} />;
  }

  return hoistNonReactStatic(WithNamespaces, Component); // copy static methods;
};

export const withLazyNamespaces = <P extends {}>(
  Component: ComponentType<P & I18nProps>,
  ...namespaces: Namespace[]
) => withFetchNamespaces(Component, true, ...namespaces);

const withNamespaces = <P extends {}>(
  Component: ComponentType<P & I18nProps>,
  ...namespaces: Namespace[]
) => withFetchNamespaces(Component, false, ...namespaces);

export default withNamespaces;
