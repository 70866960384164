/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AddressOption } from './address-option';

/**
 * 
 * @export
 * @interface CompanyAddressRequest
 */
export interface CompanyAddressRequest {
    /**
     * Address type
     * @type {string}
     * @memberof CompanyAddressRequest
     */
    type: CompanyAddressRequestTypeEnum;
    /**
     * 
     * @type {AddressOption}
     * @memberof CompanyAddressRequest
     */
    address: AddressOption;
}

/**
    * @export
    * @enum {string}
    */
export enum CompanyAddressRequestTypeEnum {
    Headquarters = 'headquarters',
    Correspondence = 'correspondence'
}



