import { LinkProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

export enum FaqSideMenuItemVariant {
  LINK = 'link',
  LINK_ACTIVE = 'linkActive',
  DROPDOWN = 'dropdown',
  DROPDOWN_ACTIVE = 'dropdownActive',
  SUBLINK = 'sublink',
  SUBLINK_ACTIVE = 'sublinkActive',
}

export enum FaqSideMenuItemType {
  DROPDOWN = 'DROPDOWN',
  LINK = 'LINK',
}

interface FaqSideMenuDefaultProps {
  id: string;
  isSelected?: boolean;
}

export type FaqSideMenuLinkItem = {
  type: `${FaqSideMenuItemType.LINK}`;
} & FaqSideMenuDefaultProps &
  Omit<LinkProps, 'variant'>;

export type FaqSideDropdownItem = {
  type: `${FaqSideMenuItemType.DROPDOWN}`;
  title: string;
  items: FaqSideMenuLinkItem[];
} & FaqSideMenuDefaultProps;

export type FaqSideMenuItem = FaqSideDropdownItem | FaqSideMenuLinkItem;

export type FaqSideMenuItemProps = {
  variant?: FaqSideMenuItemVariant;
} & FaqSideMenuItem;

export interface FaqSideMenuProps {
  children?: ReactNode;
  items?: FaqSideMenuItem[];
  title: string;
}
