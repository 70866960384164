import { contractSwitcherModalParams } from 'components/ContractSwitcher';
import useModal from 'context/ModalContext';
import useCurrentContract from 'data/useCurrentContract';
import { TranslateFn } from 'i18n';
import { useCallback } from 'react';

const useContractSwitcher = (
  t: TranslateFn,
  callback?: (contractId: string) => void,
  enabled = true,
  defaultContractId?: string,
  onlyOnboarding?: boolean
) => {
  const showModal = useModal();
  const { contract: currentContract, setContractId } = useCurrentContract(enabled);

  const handleClick = useCallback(async () => {
    const modalResult = await showModal(contractSwitcherModalParams(t, defaultContractId, onlyOnboarding));
    const values = typeof modalResult === 'object' ? modalResult : undefined;
    if (values && values?.contractId) {
      await setContractId(values.contractId);
      callback && callback(values.contractId);
    }
  }, [callback, defaultContractId, onlyOnboarding, setContractId, showModal, t]);

  return { currentContract, handleClick };
};

export default useContractSwitcher;
