export function getSelectedOptions(documentId: string): string[];
export function getSelectedOptions<T>(documentId: string, defaultValue: T): string[] | T;

export function getSelectedOptions<T>(documentId: string, defaultValue?: T): string[] | T {
  const element = document.getElementById(documentId);
  if (element) {
    return Array.from(
      element.parentElement?.getElementsByClassName('pluxee-select__single-value') ?? [],
    )
      .map((x) => x.textContent)
      .filter((x) => x) as string[];
  }
  return defaultValue !== undefined ? defaultValue : [];
}

export const getInputValue = (documentId: string): string | undefined => {
  const element = document.getElementById(documentId);

  if (element) {
    return (element as HTMLInputElement)?.value || undefined;
  }

  return undefined;
};
