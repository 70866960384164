import { ChakraProvider } from '@chakra-ui/react';
import { PROD_CDN_BASE_URL, SvgSpriteProvider } from '@pluxee-design-system/core';
import { theme } from 'common/theme';
import Layout from 'components/Layout';
import { ModalProvider } from 'context/ModalContext';
import env from 'env';
import { I18nProvider } from 'i18n';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import rum from 'trackers/rum';
import '../css/global.css';

const title = env.env && env.env !== 'production' ? `Pluxee Portal ${env.env}` : 'Pluxee Portal';
const SVG_SPRITE_URL = `${PROD_CDN_BASE_URL}/icons/sprite.svg`;
const toastProps: ToastContainerProps = {
  position: 'bottom-right',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide,
  closeButton: false,
};

const fonts = [
  'TT_Travels_Regular',
  'TT_Travels_DemiBold',
  'TT_Travels_ExtraBold',
  'TT_Travels_Medium',
  'TT_Travels_Bold',
  'TT_Travels_Black',
];

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
  const [, setRefetchInterval] = useState(60); // in seconds

  useEffect(() => {
    if (env.env === 'local') console.log(theme);
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {env.env !== 'production' && <meta name="robots" content="noindex,nofollow" />}
        {fonts.map((font) => (
          <link
            key={font}
            rel="preload"
            href={`/assets/fonts/${font}.woff2`}
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
        ))}
      </Head>

      {rum.init(
        env.rumApplicationId,
        env.rumClientToken,
        env.rumEnv,
        env.rumService,
        env.rumSite,
        env.rumVersion,
      )}

      <ChakraProvider theme={theme}>
        <SvgSpriteProvider src={SVG_SPRITE_URL}>
          <I18nProvider>
            <ModalProvider>
              <ToastContainer {...toastProps} />
              <Layout setInterval={setRefetchInterval}>
                <Component {...pageProps} />
              </Layout>
            </ModalProvider>
          </I18nProvider>
        </SvgSpriteProvider>
      </ChakraProvider>
    </>
  );
};

export default MyApp;
