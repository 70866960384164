import ErrorPage from 'common/feedback/ErrorPage';
import { I18nProps, withNamespaces } from 'i18n';

const Error500 = ({ t }: I18nProps) => (
  <ErrorPage
    variant="500"
    button={t('500_page.buttonHome', 'Go back to the homepage')}
    description={t('500_page.description', 'Sorry, something went wrong on our side...')}
    header={t('500_page.header', 'Sorry!')}
  />
);

export default withNamespaces(Error500, '500_page');
