import createRequestUrl from 'api/utils/createRequestUrl';
import { stringifyRequestDate, toArrayOrUndefined, trimFulltext } from 'common/filters';
import { ProductLocationFilterValues } from 'components/Form/LocationSelect/types';
import { RemittanceArchiveFilterValues } from 'components/RemittanceSheet/Archive';
import {
  AssignedContract,
  ContractApiAxiosParamCreator,
  ContractApiFactory,
  LocationItem,
  RemittanceSheetBulkCreateRequestSortByEnum,
  RemittanceSheetResponse,
} from 'generated';
import { instanceApi } from 'utils/axios';
import forceFileDownload from 'utils/forceFileDownload';
import usePagedRequest, { onMountSWRInfiniteConfig } from './usePagedRequest';
import useRequest, { onMountSWRConfig } from './useRequest';

// Misc
export const PAGE_SIZE: number = 5;
const contractApi = ContractApiFactory(undefined, '', instanceApi);
const contractApiParamCreator = ContractApiAxiosParamCreator(undefined);

// Types
type RemittanceArchiveFn = typeof contractApi.contractRemittanceGet;
type ContractsParams = Parameters<typeof contractApi.contracts>;
type RemittanceArchiveParams = Parameters<RemittanceArchiveFn>;

export const useContracts = (
  query: string | undefined = undefined,
  enabled: boolean = true,
  onboardingOnly: boolean = false,
) =>
  usePagedRequest<AssignedContract, typeof contractApi.contracts, ContractsParams>(
    contractApi.contracts,
    (pageIndex, pageSize) =>
      [
        undefined, // ngm-country passed externally
        pageIndex,
        pageSize,
        trimFulltext(query),
        onboardingOnly || undefined,
      ] as ContractsParams,
    enabled,
    PAGE_SIZE,
    onMountSWRInfiniteConfig,
  );

export const useMyContract = (contractId?: string) =>
  useRequest(contractApi.myContract, [contractId], undefined, contractId !== undefined);

export const useContractDetail = (contractId?: string) =>
  useRequest(contractApi.contractRead, [contractId], {}, contractId !== undefined);

export const useContractProducts = (contractId?: string) =>
  useRequest(contractApi.contractProducts, [contractId], {}, contractId !== undefined);

export const useContractProductLocations = (
  contractId?: string,
  productId?: string,
  fetchOnce = false,
  filterState?: Partial<ProductLocationFilterValues>,
) =>
  usePagedRequest<LocationItem, typeof contractApi.contractProductLocationsGet>(
    contractApi.contractProductLocationsGet,
    (pageIndex, pageSize) => [
      contractId!,
      undefined,
      productId,
      toArrayOrUndefined(filterState?.states),
      filterState?.inactive,
      filterState?.approvedOnly,
      filterState?.withPaperProduct,
      trimFulltext(filterState?.fulltext),
      pageIndex,
      pageSize,
      filterState?.sortBy || undefined,
      filterState?.sortOrder === 'desc',
    ],
    contractId !== undefined,
    20,
    fetchOnce ? undefined : {},
  );

export const useContractStates = (contractId?: string) =>
  useRequest(contractApi.contractStates, [contractId], {}, contractId !== undefined);

export const useDashboardEmployees = (contractId?: string) =>
  useRequest(
    contractApi.contractLandingpageEmployees,
    [contractId],
    onMountSWRConfig,
    contractId !== undefined,
  );

export const useDashboardLocations = (contractId?: string) =>
  useRequest(
    contractApi.contractLandingpageLocations,
    [contractId],
    onMountSWRConfig,
    contractId !== undefined,
  );

/*
 * Remittance Sheet Archive
 */
export const useRemittanceArchive = (
  contractId: string | undefined,
  filterState: RemittanceArchiveFilterValues,
  pageSize = 8,
) =>
  usePagedRequest<RemittanceSheetResponse, RemittanceArchiveFn, RemittanceArchiveParams>(
    contractApi.contractRemittanceGet,
    (pageIndex, pageSize) =>
      [
        contractId!,
        undefined, // ngm-country passed externally
        pageIndex,
        pageSize,
        toArrayOrUndefined(filterState.locationId),
        toArrayOrUndefined(filterState.product),
        stringifyRequestDate(filterState.createdFrom, 'startOfDay'),
        stringifyRequestDate(filterState.createdTill, 'endOfDay'),
        Number(filterState.amountFrom) || undefined,
        Number(filterState.amountTo) || undefined,
        filterState.author || undefined,
        filterState.fulltext || undefined,
        (filterState.sortBy as string) || undefined,
        filterState.sortOrder === 'desc',
      ] as RemittanceArchiveParams,
    contractId !== undefined,
    pageSize,
    onMountSWRConfig,
  );

export const createRemittanceArchiveBulk = (
  contractId: string,
  filterState: RemittanceArchiveFilterValues,
  idsOnly?: string[],
  idsExcluded?: string[],
) =>
  contractApi.contractRemittanceDownloadBulkCreate(
    contractId,
    {
      remittanceSheetUuids: idsOnly ?? idsExcluded ?? null,
      // both false = select all
      idOnly: idsOnly !== undefined && idsOnly.length > 0,
      idsExcluded: idsExcluded !== undefined && idsExcluded.length > 0,

      location: toArrayOrUndefined(filterState.locationId),
      products: toArrayOrUndefined(filterState.product),
      fulltext: filterState.fulltext || undefined,
      author: filterState.author || undefined,
      createdFrom: stringifyRequestDate(filterState.createdFrom, 'startOfDay'),
      createdTill: stringifyRequestDate(filterState.createdTill, 'endOfDay'),
      amountFrom: Number(filterState.amountFrom) || undefined,
      amountTill: Number(filterState.amountTo) || undefined,

      sortBy:
        (filterState.sortBy as unknown as RemittanceSheetBulkCreateRequestSortByEnum) || undefined,
      sortDesc: filterState.sortOrder === 'desc',
    },
    undefined, // ngm-country passed externally
  );

export const downloadRemittanceArchiveBulkFile = async (
  contractId: string,
  fileId: string,
  fileName: string,
) => {
  const requestArgs = await contractApiParamCreator.contractRemittanceDownloadBulkDownload(
    contractId,
    fileId,
  );
  forceFileDownload(createRequestUrl(requestArgs), fileName);
};

export const downloadRemittanceArchiveFile = async (
  contractId: string,
  sheetId: string,
  fileName: string,
) => {
  const requestArgs = await contractApiParamCreator.contractRemittanceDownload(contractId, sheetId);
  forceFileDownload(createRequestUrl(requestArgs), fileName);
};

export default contractApi;
