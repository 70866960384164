import Script from 'next/script';
import { CobrowsingConfig } from 'types/config';
import { getFullBasePath } from 'utils/router';

const cobrowse = {
  config: {} as CobrowsingConfig,

  init(config?: CobrowsingConfig) {
    if (config?.v2 === undefined) return null;

    this.config = config;

    if (config.v2) {
      if (!config.deploymentId) return null;
      return (
        <Script id="cobrowse-chat" strategy="afterInteractive">
          {`(function (g, e, n, es, ys) {
  g['_genesysJs'] = e; g[e] = g[e] || function () {(g[e].q = g[e].q || []).push(arguments);};
  g[e].t = 1 * new Date(); g[e].c = es; ys = document.createElement('script'); ys.async = 1;
  ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
})(window, 'Genesys', 'https://apps.mypurecloud.de/genesys-bootstrap/genesys.min.js', {
  environment: 'prod-euc1',
  deploymentId: '${config.deploymentId}',
});`}
        </Script>
      );
    }

    // v1
    if (!config?.orgGuid || !config?.orgId || !config?.orgName || !config?.deploymentKey)
      return null;
    return (
      <>
        <Script
          id="cobrowse-chat"
          src="https://apps.mypurecloud.de/webchat/jsapi-v1.js"
          strategy="afterInteractive"
        />
        <Script
          id="cobrowse-api"
          src={`https://cobrowse.mypurecloud.de/cobrowse-jsapi-v1.js?deploymentKey=${config.deploymentKey}`}
          strategy="afterInteractive"
        />
      </>
    );
  },
  /**
   * Legacy code, if manual cobrowse triggering is needed, see Genesys docs:
   * https://developer.genesys.cloud/commdigital/digital/webmessaging/messengersdk/pluginExamples#create-a-custom-launcher-button
   *
   * @param currentLocale Current locale to display correct language
   * @param isMobile Just to shrink elements on mobile devices
   */
  start(currentLocale: string, isMobile?: boolean) {
    if (!this.config.v2 && typeof window !== 'undefined' && window.ININ?.cobrowse?.create) {
      window.ININ.cobrowse
        .create({
          // Web chat application URL. Use the correct top-level domain for your Genesys Cloud region, i.e. apps.mypurecloud.ie, apps.mypurecloud.jp, apps.mypurecloud.co.uk.
          webchatAppUrl: 'https://apps.mypurecloud.de/webchat',

          // Web chat service URL
          webchatServiceUrl: 'https://realtime.mypurecloud.de:443',

          // Numeric organization ID
          orgId: this.config.orgId,

          // OrgGuid. Needed for co-browse with voice.
          orgGuid: this.config.orgGuid,

          // Organization name
          orgName: this.config.orgName,

          // Locale code
          locale: currentLocale,

          // CSS class applied to the security key window, but not its contents
          cssClass: 'webchat-frame',

          // Custom style applied to the security key window, but not its contents
          css: {
            width: '100%',
            height: isMobile ? '106px' : '48px',
            border: '0',
            flex: `1 0 ${isMobile ? 106 : 384}px`,
          },

          // Optional CSS for styling security key form
          contentCssUrl: `${getFullBasePath()}/assets/cobrowse.css`,

          // Element id where the security key form will appear.
          containerEl: 'cobrowse-security-form',

          // Set to true to display security key form. Do not include for co-browse with web chat or for PureConnect co-browse with voice.
          promptForSecurityKey: true,
        })
        .catch((err: any) => {
          console.error('cobrowse failed', err);
        });
    }
    if (this.config.v2 && typeof window !== 'undefined' && window.Genesys) {
      window.Genesys('subscribe', 'CobrowseService.ready', (e: any) =>
        console.log('CobrowseService: ready', e),
      );
    }
  },
  startShareScreen(joinCode: string) {
    if (this.config.v2 && typeof window !== 'undefined' && window.Genesys) {
      window.Genesys(
        'command',
        'CobrowseService.acceptSession',
        { joinCode },
        () => {
          console.log('Co-browse session successfully accepted');
        },
        (err: any) => {
          console.error('Could not accept Co-browse session, error code: ', err.code);
        },
      );
    }
  },
  stopShareScreen() {
    if (this.config.v2 && typeof window !== 'undefined' && window.Genesys) {
      window.Genesys(
        'command',
        'CobrowseService.stopSession',
        {},
        () => {
          console.log('Co-browse session successfully stopped');
        },
        (err: any) => {
          console.error('Could not stop Co-browse session: ', err);
        },
      );
    }
  },
};

export default cobrowse;
