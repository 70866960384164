import { Input, InputProps, InputVariant } from '@pluxee-design-system/core';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { ChangeEventHandler, memo, useCallback } from 'react';

interface CompanyIdControlProps
  extends Omit<InputProps, 'isDisabled' | 'name' | 'type' | 'value' | 'variant'> {
  name: string;
  readonly?: boolean;
  format?: string;
}

export const formatValue = (rawValue: string, format?: string) => {
  if (rawValue === '') return '';
  const hasSeparators = format?.includes('.');

  let value = rawValue
    // strip alphabet letters
    .replace(/[A-Za-z]/g, '');
  // replace dot mark with placeholder "M"
  if (hasSeparators) value = value.replace(/\./g, 'M');

  // strip non-numeric letters except "M"
  if (hasSeparators) value = value.replace(/[^\dM]/g, '').replace(/M/g, '.');
  else value = value.replace(/[^\d]/g, '');

  if (format) {
    const lastFormatPos = format.length;
    const lastValuePos = value.length;
    let nextFormatPos = 0;
    let nextValuePos = 0;
    const word = format.split('');
    let lastFillPos = 0;

    while (nextFormatPos < lastFormatPos && nextValuePos < lastValuePos) {
      const symbol = format[nextFormatPos];
      if (symbol === '#') {
        if (value[nextValuePos] !== '.') {
          word[nextFormatPos] = value[nextValuePos];
          nextFormatPos += 1; // move to next format position
          lastFillPos = nextFormatPos;
        }
        // else - stay in current format position
        nextValuePos += 1; // move to next value position
      } else if (symbol === '.') {
        if (value[nextValuePos] === '.') {
          nextValuePos += 1; // move to next value position
          lastFillPos = nextFormatPos + 1;
        }
        // else - stay in current format position
        nextFormatPos += 1; // move to next format position
      }
    }
    return word.join('').substring(0, lastFillPos);
  }

  return value;
};

const CompanyIdControl = ({
  disabled,
  format,
  field,
  field: { setValue },
  mandatory,
  name,
  readonly,
  ...inputProps
}: CompanyIdControlProps & WithFastFieldProps<string>) => {
  const hasError = Boolean(field.error);
  // handle any format to proper format
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const value = formatValue(event.target.value, format);
      setValue(value);
    },
    [format, setValue],
  );

  return (
    <Input
      mandatory={mandatory}
      name={field.name}
      onBlur={inputProps.onBlur ?? field.onBlur}
      onChange={handleChange}
      value={field.value}
      {...inputProps}
      disabled={inputProps.isReadOnly || readonly ? undefined : disabled}
      variant={
        inputProps.isReadOnly || readonly
          ? InputVariant.READONLY
          : field.isTouched && hasError
          ? InputVariant.ERROR
          : field.isTouched
          ? InputVariant.SUCCESS
          : InputVariant.FILLED
      }
      helpText={field.isTouched && hasError ? field.error : inputProps.helpText}
    />
  );
};

const MemoizedCompanyIdControl = memo(CompanyIdControl);

export default withFastField(MemoizedCompanyIdControl);
