import { useContext } from 'react';
import I18nContext from './I18nContext';
import { I18nProps, Namespace } from './types';

const useTranslations = (_defaultNamespace?: Namespace): I18nProps => {
  const context = useContext(I18nContext);

  if (context === undefined) {
    console.error('useTranslations must be used within a I18nContext');
  }

  return context;
};

export default useTranslations;
