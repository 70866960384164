import { CheckboxProps } from '@chakra-ui/react';
import { Checkbox } from '@pluxee-design-system/core';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { includes, isArray } from 'lodash';
import { memo } from 'react';

interface CheckboxControlProps
  extends Omit<
    CheckboxProps,
    'isDisabled' | 'isReadOnly' | 'name' | 'value' | 'variant' | 'title' | 'placeholder'
  > {
  disabled?: boolean;
  mandatory?: boolean; // TODO: not supported
  name: string;
  readonly?: boolean;
  helpText?: string;
}

// TODO: get rid-of formik
const CheckboxControl = ({
  disabled,
  isChecked,
  mandatory,
  name,
  readonly,
  field,
  ...checkboxProps
}: CheckboxControlProps & WithFastFieldProps) => {
  const hasError = Boolean(field.error);
  const value =
    isChecked ||
    field.checked ||
    (isArray(field.value) ? includes(field.value, field.value) : field.value) ||
    (field.value && field.value.checked);

  return (
    <Checkbox
      name={field.name}
      checked={field.checked}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value}
      {...checkboxProps}
      isChecked={value}
      isDisabled={disabled}
      isInvalid={field.isTouched && hasError}
      isReadOnly={readonly}
      // variant={isTouched ? 'success' : 'filled'} no variant supported
      helpText={field.isTouched && hasError ? field.error : checkboxProps.helpText}
    />
  );
};

const MemoizedCheckboxControl = memo(CheckboxControl);

export default withFastField(MemoizedCheckboxControl);
