import { TagStatus } from '@pluxee-design-system/core';
import { ContractStatusEnum } from 'generated/models';
import useTranslations from 'i18n';

interface ContractStatusProps {
  status?: ContractStatusEnum;
}

export const getContractStatus = (status?: ContractStatusEnum) => {
  switch (status) {
    case ContractStatusEnum.Activating:
    case ContractStatusEnum.Creating:
    case ContractStatusEnum.Deactivating:
    case ContractStatusEnum.Deleting:
    case ContractStatusEnum.New:
    case ContractStatusEnum.Updating:
    case ContractStatusEnum.PendingConfirmation:
      return {
        variant: 'inProgress',
        tKey: 'global_texts.statuses.pending',
        tDefault: 'Pending',
      };
    case ContractStatusEnum.Active:
      return {
        variant: 'shipped',
        tKey: 'global_texts.statuses.active',
        tDefault: 'Active',
      };
    case ContractStatusEnum.Inactive:
      return {
        variant: 'expired',
        tKey: 'global_texts.statuses.inactive',
        tDefault: 'Inactive',
      };
    case ContractStatusEnum.Failed:
    default:
      return {
        variant: 'canceled',
        tKey: 'global_texts.statuses.failed',
        tDefault: 'Failed',
      };
  }
};

const ContractStatus = ({ status }: ContractStatusProps) => {
  const { t } = useTranslations();
  const { variant, tKey, tDefault } = getContractStatus(status);

  return (
    <TagStatus
      ellipse
      text={t(tKey, tDefault)}
      // @ts-ignore
      variant={variant}
    />
  );
};

export default ContractStatus;
