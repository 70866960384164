import { useFormikContext } from 'formik';
import { ButtonProps } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/core';

interface SubmitButtonProps extends Omit<ButtonProps, 'type'> {}

const SubmitButton = ({ children, ...buttonProps }: SubmitButtonProps) => {
  const { isSubmitting, isValidating } = useFormikContext();

  return (
    <Button type="submit" isLoading={isSubmitting || isValidating} {...buttonProps}>
      {children}
    </Button>
  );
};

export default SubmitButton;
