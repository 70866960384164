const normalize = (locale: string) => locale.toLowerCase().split('-')[0];

const pickLocale = (supportedLanguages: string[] | undefined, defaultLanguage: string) => {
  if (
    supportedLanguages &&
    defaultLanguage &&
    typeof window !== 'undefined' &&
    window?.navigator?.languages
  ) {
    const browserNorm = window.navigator.languages.map(normalize);
    const supportedNorm = supportedLanguages.map(normalize);
    let result = defaultLanguage;

    browserNorm.some((browserLanguage) => {
      const index = supportedNorm.findIndex((supported) => supported === browserLanguage);
      if (index !== -1) {
        result = supportedLanguages[index];
        return true;
      }
      return false;
    });
    return result;
  }
  return defaultLanguage;
};

export default pickLocale;
