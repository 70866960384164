import { defineStyleConfig } from '@chakra-ui/react';
import { FaqSideMenuItemVariant } from './FaqSideMenu.types';

const FaqSideMenuItem = defineStyleConfig({
  baseStyle: {
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    _hover: {
      background: 'semantic.cta.primaryHover',
    },
  },
  variants: {
    [FaqSideMenuItemVariant.LINK]: {
      padding: 4,
      borderBottom: '1px solid',
      borderBottomColor: 'semantic.border.secondary',
    },
    [FaqSideMenuItemVariant.LINK_ACTIVE]: {
      padding: 4,
      background: 'semantic.cta.primaryHover',
      borderBottom: '1px solid',
      borderBottomColor: 'semantic.border.secondary',
    },
    [FaqSideMenuItemVariant.SUBLINK]: {
      padding: 4,
      paddingLeft: 8,
      borderBottom: '1px solid',
      borderBottomColor: 'semantic.border.secondary',
    },
    [FaqSideMenuItemVariant.SUBLINK_ACTIVE]: {
      padding: 4,
      paddingLeft: 8,
      background: 'semantic.cta.primaryHover',
      borderBottom: '1px solid',
      borderBottomColor: 'semantic.border.secondary',
      _after: {
        content: `""`,
        width: '0%',
        height: '0px',
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftColor: 'semantic.cta.primary',
        position: 'absolute',
        top: 'calc(50% - 0.75rem)', // 3
        left: '0',
        borderStyle: 'solid',
        borderWidth: '0.75rem 0 0.75rem 0.75rem',
      },
    },
    [FaqSideMenuItemVariant.DROPDOWN]: {
      padding: 4,
      borderBottom: '1px solid',
      borderBottomColor: 'semantic.border.secondary',
    },
    [FaqSideMenuItemVariant.DROPDOWN_ACTIVE]: {
      padding: 4,
      background: 'semantic.cta.primaryHover',
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: FaqSideMenuItemVariant.LINK,
  },
});

export default FaqSideMenuItem;
