import { defineStyleConfig } from '@chakra-ui/react';

const Modal = defineStyleConfig({
  sizes: {
    xxl: {
      dialogContainer: {
        height: '100vh',
        p: { sm: 6, md: 12 },
      },
      dialog: {
        // minH: '100%',
        maxH: '100%',
        paddingBottom: { base: 6, md: 10 },
      },
      header: {
        paddingLeft: { base: 4, md: 10 },
        paddingRight: { base: 4, md: 10 },
        h4: { textStyle: { base: 'title.mobileMain', md: 'title.desktopMain' } },
        h5: { textStyle: { base: 'subtitle.mobileMain', md: 'subtitle.desktopMain' } },
      },
      body: {
        paddingLeft: { base: 4, md: 10 },
        paddingRight: { base: 4, md: 10 },
        textStyle: 'body.largeMedium',
      },
      footer: {
        paddingLeft: { base: 4, md: 10 },
        paddingRight: { base: 4, md: 10 },
        mt: { base: 6, md: 10 },
        gap: { base: 4, md: 6 },
        flexDirection: { base: 'column-reverse', md: 'row' },
      },
    },
  },
});

export default Modal;
