type ConfigValue = string | (() => string);

export interface HeadersType {
  locale?: ConfigValue;
  countryCode?: ConfigValue;
  ocpApimSubscriptionKey?: ConfigValue;
}

const getValue = (value: ConfigValue) => (typeof value === 'string' ? value : value());

export const getHeaders = (config?: HeadersType, token?: string) => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (config?.ocpApimSubscriptionKey) {
    headers['Ocp-Apim-Subscription-Key'] = getValue(config.ocpApimSubscriptionKey);
  }

  if (config?.countryCode) {
    headers['ngm-country'] = getValue(config.countryCode);
  }

  if (config?.locale) {
    headers['Accept-Language'] = getValue(config.locale);
  }

  return headers;
};

export default getHeaders;
