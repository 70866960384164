import { ToastId } from '@chakra-ui/react';
import Toast from 'common/feedback/Toast';
import { toast } from 'react-toastify';

// export const { toast, ToastContainer } = createStandaloneToast({
//   defaultOptions: {
//     position: 'bottom-right',
//     duration: null,
//     render: Toast,
//   },
//   theme,
// });

export const notify = (toastType: ToastId) => {
  // if (toast.isActive(toastType)) return;
  
  toast(<Toast id={toastType} />, { toastId: toastType });
};
