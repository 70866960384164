import { ComponentType } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { I18nProps } from './types';
import useTranslations from './useTranslations';

/**
 * Don't fetch a translation!! It only injects the translation function to a wrapped component.
 *
 * @param Component Component to be injected with the translation function.
 */
export const withTranslations = <P extends I18nProps>(
  Component: ComponentType<P & I18nProps>,
): ComponentType<Omit<P, keyof I18nProps>> => {
  function WithTranslations(props: P) {
    const context = useTranslations();

    return <Component {...props} {...context} />;
  }

  return hoistNonReactStatic(WithTranslations, Component); // copy static methods;
};

export default withTranslations;
