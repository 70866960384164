/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ProductTypeEnum } from './product-type-enum';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Object unique ID
     * @type {string}
     * @memberof Product
     */
    uid: string;
    /**
     * Product unique code used in the master BO system
     * @type {string}
     * @memberof Product
     */
    code: string;
    /**
     * Product name in en
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * Product translation key
     * @type {string}
     * @memberof Product
     */
    nameKey: string;
    /**
     * 
     * @type {ProductTypeEnum}
     * @memberof Product
     */
    type?: ProductTypeEnum;
    /**
     * Loop
     * @type {string}
     * @memberof Product
     */
    loop: ProductLoopEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductLoopEnum {
    Closed = 'closed',
    Open = 'open'
}



