import { useContracts, useMyContract } from 'api/contractApi';
import { AxiosError } from 'axios';
import { AssignedContract, ContractAction } from 'generated/models';
import { useCallback, useMemo } from 'react';
import useLocalStorage from './useLocalStorage';

type CurrentContract = AssignedContract | undefined;
type CurrentContractId = string | undefined;

interface ReturnType {
  allowedActions?: ContractAction[];
  contract: CurrentContract;
  contractId: CurrentContractId;
  employeeId?: string;
  error?: AxiosError;
  isOK: boolean;
  isEmpty: boolean;
  isUpdating: boolean;
  setContractId: (contractId: CurrentContractId) => Promise<void>;
  updateContracts: () => Promise<AssignedContract | undefined>;
}

export const useCurrentContractId = () =>
  useLocalStorage<CurrentContractId>('currentContract', undefined);

/**
 * We store contractId twice, one in localStorage and one locally which is validated
 * against a contract returned from useMyContract(). If the hook failed, choose a first contract
 * returned from existing contracts fetched by useContracts()
 *
 * @param enabled
 */
const useCurrentContract = (enabled = true): ReturnType => {
  const { value: storedContractId, setValue: setStoredContractId } = useCurrentContractId();
  const {
    data: myContract,
    isOK,
    isLoading,
    mutate,
  } = useMyContract(enabled ? storedContractId : undefined);
  const {
    data: contracts,
    isOK: isOKcontracts,
    isEmpty,
    error,
    mutate: mutateContracts,
  } = useContracts(undefined, enabled);

  const possibleMyContract = myContract?.contract;
  const possibleContracts = contracts;

  // to update both requests
  const updateContracts = useCallback(async () => {
    const response = await mutate();
    await mutateContracts();
    return response?.data?.contract;
  }, [mutate, mutateContracts]);

  // update contract or use first in if not in the list
  const contract = useMemo<CurrentContract | undefined>(() => {
    if (!isLoading) {
      if (isOK) {
        return possibleMyContract;
      } else if (!isOK && isOKcontracts) {
        return possibleContracts?.[0];
      }
    }
    return undefined;
  }, [isOK, isLoading, isOKcontracts, possibleMyContract, possibleContracts]);

  return {
    allowedActions: contract?.allowedActions,
    contractId: contract?.contract?.uid,
    contract,
    employeeId: contract?.employeeUid,
    error, // ignore MyContract's error
    isOK: isOK || isOKcontracts,
    isEmpty: !isOK && !error && isEmpty,
    isUpdating: storedContractId && storedContractId !== contract?.contract?.uid && !isEmpty,
    setContractId: setStoredContractId, // change only value in localStorage
    updateContracts,
  };
};

export default useCurrentContract;
