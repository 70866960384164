import useSWR, { useSWRConfig } from 'swr';
import { CountryConfig } from 'types/config';
import { defaultSWRConfig } from 'api/useRequest';
import { fetcher, updateHeaders } from 'utils/axios';

const useCountryConfig = () => {
  const key = `/config`;
  const { cache } = useSWRConfig();
  const { data, error } = useSWR<CountryConfig>(key, fetcher, {
    ...defaultSWRConfig,
    revalidateOnMount: cache.get(key) === undefined, // by default revalidation occurs on mount when initialData is not set, use this flag to force behavior
  });

  if (data?.isoCode) {
    updateHeaders(data.isoCode);
  }

  return {
    ...data!,
    isOK: Boolean(!error && data),
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCountryConfig;
