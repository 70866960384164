import { AxiosRequestConfig } from 'axios';
import axiosCaseConverter from 'axios-case-converter';
import { notify } from 'common/feedback/ToastContainer';
import { signIn } from 'data/useSignIn';
import env from 'env';
import { snakeCase } from 'lodash';
import createHumps from 'lodash-humps/lib/createHumps';
import { routerReplace } from '../router';
import axiosFactory, { ConfigType } from './axiosFactory';
import getHeaders from './headers';

export const snakes = createHumps(snakeCase);

export const basicConfig: ConfigType = {
  baseURL: env.apiProxy,
  httpCallbacks: {
    401: (_, options) => signIn(options.currentLocale, options.providerId), // login
    403: () => routerReplace('/403'),
    500: (response) => notify(response.status),
    502: (response) => notify(response.status),
    503: (response) => notify(response.status),
    504: (response) => notify(response.status),
  },
  excludedHttpCallbacks: [500],
};

// Creates an instance for communication with ContentStack + internal apis
const instance = axiosFactory(basicConfig);

// Creates an instance for communication with backend API
export const instanceApi = axiosCaseConverter(
  axiosFactory({ ...basicConfig, baseURL: env.apiBase }),
  {
    caseMiddleware: {
      requestInterceptor: (config: AxiosRequestConfig) => {
        const newConfig = { ...config };
        if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
          return newConfig;
        }
        if (config.data) {
          newConfig.data = JSON.stringify(snakes(JSON.parse(config.data)));
        }
        return newConfig;
      },
    },
  },
);

/**
 * Updates headers of the default instance of axios.
 * @param countryCode Optional country code
 * @param token Optional authorization token
 * @param locale Optional local code
 */
export const updateHeaders = (countryCode?: string, token?: string, locale?: string) => {
  instance.defaults.headers.common = {
    ...instance.defaults.headers.common,
    ...getHeaders({ countryCode, locale }, token),
  };
  instanceApi.defaults.headers.common = {
    ...instanceApi.defaults.headers.common,
    ...getHeaders({ countryCode, locale }, token),
  };
};

export const fetcher = <T>(url: string) => instance.get<T>(url).then((res) => res.data);

export default instance;
