import { Center, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { Button, Illustrations, XMark, XMarkVariants } from '@pluxee-design-system/core';
import Illustration from 'common/Illustration';
import routerPush from 'utils/router';

interface ErrorPageProps {
  button: string;
  description: string;
  header: string;
  redirectTo?: string;
  variant: '403' | '404' | '500';
}

const ErrorPage = ({ button, description, header, redirectTo = '/', variant }: ErrorPageProps) => {
  const size = useBreakpointValue({ base: 30, md: 42 }, { fallback: 'md' });
  const illustration: Illustrations =
    variant === '404'
      ? Illustrations.NOT_FOUND
      : variant === '403'
      ? Illustrations.UNLOCK_OFF
      : Illustrations.DEVICE_TABLET_MAN;

  return (
    <Center p={24} gap={10} flexDirection="column">
      <Flex gap={2} align="center">
        <XMark variant={XMarkVariants.CONFIDENTLY_CORAL} width={size} height={size} />
        <Text
          as="h1"
          variant={{ base: 'heroTitle.mobileMediumBlack', md: 'heroTitle.desktopMediumBlack' }}
        >
          {header}
        </Text>
      </Flex>
      <Illustration illustrationId={illustration} width="20rem" height="20rem" />
      <Text variant={{ base: 'title.mobileSection', md: 'title.desktopSection' }}>
        {description}
      </Text>
      <Button onClick={() => routerPush(redirectTo)}>{button}</Button>
    </Center>
  );
};

export default ErrorPage;
