import { PayoutChannel, Price } from 'generated/models';

const empty: Price[] = [];

export interface PriceAndFees {
  price?: Price;
  fees: Price[];
  feesByKey: Record<string, Price>;
  isSpecialOffer: boolean;
}

export const getPrice = (priceList?: Array<Price> | null) =>
  (priceList || empty).find((p) => p.feeCode === 'price');

export const getFees = (priceList?: Array<Price> | null) =>
  (priceList || empty).filter((p) => p.feeCode !== 'price');

export const getPriceAndFees = (priceList?: Array<Price> | null) => {
  const result: PriceAndFees = {
    price: undefined,
    fees: [],
    feesByKey: {},
    isSpecialOffer: false,
  };

  (priceList || empty).forEach((current) => {
    if (current.feeCode === 'price') {
      result.price = current;
    } else {
      result.feesByKey[current.feeCode] = current;
      result.fees.push(current);
    }
    if (!result.isSpecialOffer) {
      result.isSpecialOffer = current.individualFee !== undefined && current.individualFee !== null;
    }
  });
  return result;
};

export const formatPrice = (p?: Price, defaultPrice = '') =>
  `${p?.individualFee ?? p?.fee ?? defaultPrice}${p?.individualMeasureSign ?? p?.measureSign ?? ''}`;

export const isEcoPass = (p: PayoutChannel) => p.priceTierCode?.toLowerCase()?.includes('ecopass');
