import useCurrentLocale from 'data/useCurrentLocale';
import { get, merge } from 'lodash';
import { ReactNode, useEffect, useMemo, useReducer, useRef } from 'react';
import { fetcher } from 'utils/axios';
import I18nContext from './I18nContext';
import I18nContextLoader from './I18nContextLoader';
import I18nReducer, { initialState } from './I18nReducer';
import {
  I18nContextLoaderType,
  I18nProps,
  Messages,
  ReducerActionType,
  RequestStatus,
} from './types';
import {
  namespacesReady,
  reduceNamespaces,
  translate,
  translateGroup,
  translateHtml,
  translateQueryKey,
} from './utils';

interface I18nProviderProps {
  children: ReactNode;
}

const I18nProvider = ({ children }: I18nProviderProps) => {
  const prevLocale = useRef<string | null>(null);
  const tmpLocale = useRef<string | null>(null);
  const { value: currentLocale } = useCurrentLocale();
  const [state, dispatch] = useReducer(I18nReducer, initialState);

  useEffect(() => {
    prevLocale.current = tmpLocale.current;
    tmpLocale.current = currentLocale;
  }, [currentLocale]);

  const context = useMemo<I18nProps>(() => {
    const prevMessages = prevLocale?.current ? get(state.messages, prevLocale.current, {}) : {};
    const currMessages = get(state.messages, currentLocale, {});
    const messages = merge({}, prevMessages, currMessages);
    return {
      nsReady: namespacesReady(state.loadedMessages, currentLocale),
      t: translate(messages),
      tGroup: translateGroup(currMessages, prevMessages),
      tHtml: translateHtml(messages),
      tQueryKey: translateQueryKey(messages),
    };
  }, [state.messages, state.loadedMessages, currentLocale]);

  const loader = useMemo<I18nContextLoaderType>(
    () => async (rawNamespaces, locale, countryCode) => {
      const namespaces = reduceNamespaces(rawNamespaces, countryCode);

      dispatch({
        type: ReducerActionType.QUEUE_REQUEST,
        request: {
          locale,
          namespaces,
          countryCode,
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    const request = state.activeRequest;

    if (request) {
      const fetchData = async () => {
        const requests = request.namespaces.map((namespace) =>
          fetcher<Messages>(
            `/translations?ns=${namespace}&locale=${request.locale}&countryCode=${request.countryCode}`,
          ).catch(() => null),
        );
        const responses = (await Promise.all(requests)).filter((x) => x !== null) as Messages[];
        dispatch({
          type: ReducerActionType.COMPLETE_REQUEST,
          request,
          messages: responses,
          status: RequestStatus.Success,
        });
      };
      fetchData().catch(() =>
        dispatch({
          type: ReducerActionType.COMPLETE_REQUEST,
          request,
          messages: [],
          status: RequestStatus.Error,
        }),
      );
    }
  }, [state.activeRequest, dispatch]);

  return (
    <I18nContext.Provider value={context}>
      <I18nContextLoader.Provider value={loader}>{children}</I18nContextLoader.Provider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
