import { RadioGroup, RadioGroupProps, SpaceProps, Stack, StackDirection } from '@chakra-ui/react';
import { FieldHelpText, FieldTitle, InputVariant } from '@pluxee-design-system/core';
import withFastField, { WithFastFieldProps } from 'common/forms/withFastField';
import { memo, useCallback } from 'react';

export interface RadioGroupControlProps
  extends Omit<RadioGroupProps, 'isDisabled' | 'name'>,
    Pick<SpaceProps, 'marginBottom' | 'mb' | 'marginTop' | 'mt'> {
  direction?: StackDirection;
  disabled?: boolean;
  mandatory?: boolean;
  name: string;
  onAfterChange?: (value: string) => void;
  readonly?: boolean;
  title?: string;
}

const RadioGroupControl = ({
  children,
  direction,
  disabled,
  mandatory,
  marginBottom,
  mb,
  marginTop,
  mt,
  name,
  onAfterChange,
  onChange,
  readonly,
  title,
  field,
  field: { setValue },
}: RadioGroupControlProps & WithFastFieldProps<string>) => {
  const hasError = Boolean(field.error);
  const variant = readonly
    ? InputVariant.READONLY
    : disabled
    ? 'disabled'
    : field.isTouched && hasError
    ? InputVariant.ERROR
    : field.isTouched
    ? InputVariant.SUCCESS
    : InputVariant.FILLED;

  const handleChange = useCallback(
    (value: string) => {
      setValue(value, true);
      onAfterChange?.(value);
    },
    [setValue, onAfterChange],
  );

  return (
    <>
      {title && (
        <FieldTitle
          title={title}
          mandatory={Boolean(mandatory)}
          disabled={variant === 'disabled'}
          variant={variant !== 'disabled' ? variant : undefined}
        />
      )}
      <RadioGroup
        isDisabled={variant === 'disabled'}
        value={field.value ?? undefined}
        name={name}
        onChange={onChange ?? handleChange}
      >
        <Stack
          align="stretch"
          direction={direction}
          spacing={4}
          mt={marginTop ?? mt}
          mb={marginBottom ?? mb}
        >
          {children}
        </Stack>
      </RadioGroup>
      {field.isTouched && field.error && (
        <FieldHelpText
          disabled={variant === 'disabled'}
          text={field.error}
          variant={InputVariant.ERROR}
        />
      )}
    </>
  );
};

const MemoizedRadioGroupControl = memo(RadioGroupControl);

export default withFastField(MemoizedRadioGroupControl);
