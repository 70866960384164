import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['wrapper', 'carrousel', 'link', 'dots']);

const DashboardCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 4,
      boxShadow: 'small',
      background: 'semantic.surface.1',
    },
    carrousel: {
      overflowY: 'hidden',
      position: 'relative',
      height: '100%',
      '> div': { p: 0, mb: 0 },
      '> div > div': { height: '100%' },
      '.slick-dots': {
        position: 'absolute !important',
        height: '1.19rem',
      },
      '.slick-slider': {
        height: '100%',
      },
    },
    link: {
      right: 0,
      bottom: 0,
    },
    dots: {
      bottom: 0,
    },
  },
  variants: {
    default: {
      wrapper: {
        padding: 4,
      },
    },
    carrousel: {
      wrapper: {
        padding: 4,
      },
    },
    soloCarrousel: {
      carrousel: {
        '.slick-list': {
          height: '100%',
        },
        '.slick-slide': {
          height: '100%',
        },
        '.slick-slide > div': {
          height: '100%',
        },
        '.slick-track': {
          height: '100%',
        },
        'ul.slick-dots': {
          bottom: '1rem !important',
        },
      },
      link: {
        right: 4,
        bottom: 4,
      },
      dots: {
        bottom: 0,
      },
    },
    illustration: {
      wrapper: {
        height: '100%',
      },
      carrousel: {
        '.slick-list': {
          height: '100%',
        },
        '.slick-slide': {
          height: '100%',
        },
        '.slick-slide > div': {
          height: '100%',
        },
        '.slick-track': {
          height: '100%',
        },
        'ul.slick-dots': {
          bottom: '1rem !important',
        },
      },
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: 'default',
  },
});

export default DashboardCard;
