import { DatePicker, DatePickerProps } from '@pluxee-design-system/datepicker';
import useConfig from 'data/useConfig';
import useCurrentLocale from 'data/useCurrentLocale';
import { format, formatISO, set } from 'date-fns';
import { memo, useCallback, useMemo } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { getDateLocale } from 'utils/dateLocale';
import withFastField, { WithFastFieldProps } from './withFastField';

interface DateControlProps
  extends Omit<DatePickerProps, 'name' | 'onChange' | 'showTimeInput' | 'value' | 'variant'> {
  disabled?: boolean;
  filterDate?: (date: Date) => boolean; // TODO: not supported?
  name: string;
  id?: string;
  readonly?: boolean;
  skipVariant?: boolean;
  withEndTime?: boolean;
  withTime?: boolean;
}

const DATE_FORMAT = 'yyyy-MM-dd';
const isMidnight = (date: Date) => date.getHours() === 0 && date.getMinutes() === 0;

// TODO: get rid-of formik
const DateControl = ({
  disabled,
  name,
  placeholder,
  readonly,
  skipVariant = false,
  field,
  field: { setValue },
  withEndTime = false,
  withTime,
  ...datePickerProps
}: DateControlProps & WithFastFieldProps<string | null>) => {
  const { dateFormat } = useConfig();
  const { value: currentLocale } = useCurrentLocale();
  const locale = getDateLocale(currentLocale).code;
  const hasError = Boolean(field.error);

  const defaultPlaceholder = useMemo(
    () => dateFormat.date.replace(/([a-zA-Z])/g, '-'),
    [dateFormat.date],
  );
  const handleChange = useCallback(
    (date: Date | null) => {
      let result: string | null = null;
      if (date) {
        if (withTime) {
          result = formatISO(
            withEndTime && isMidnight(date)
              ? set(date, { hours: 23, minutes: 59, seconds: 0, milliseconds: 0 })
              : set(date, { seconds: 0, milliseconds: 0 }),
          );
        } else {
          result = format(date, DATE_FORMAT);
        }
      }
      setValue(result);
    },
    [withEndTime, setValue, withTime],
  );

  return (
    <DatePicker
      // @ts-ignore
      name={field.name} // passed by ...rest
      locale={locale}
      onBlur={field.onBlur}
      onChange={handleChange}
      value={(field.value && new Date(field.value)) || null}
      placeholder={placeholder ?? defaultPlaceholder}
      {...datePickerProps}
      variant={
        skipVariant
          ? undefined
          : readonly
            ? 'readonly'
            : field.isTouched && hasError
              ? 'error'
              : undefined
      }
      helpText={field.isTouched && hasError ? field.error : datePickerProps.helpText}
      format={withTime ? 'P p' : 'P'}
      showTimeInput={withTime}
    />
  );
};

const MemoizedDateControl = memo(DateControl);

export default withFastField(MemoizedDateControl);
