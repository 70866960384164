/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * User role in the NGM portal
 * @export
 * @enum {string}
 */

export enum UserRoleEnum {
    BoInt = 'bo_int',
    BoExt = 'bo_ext',
    AdminExec = 'admin_exec',
    Admin = 'admin',
    Operator = 'operator',
    Clerk = 'clerk'
}



