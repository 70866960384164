import { useState, useEffect, useCallback } from 'react';
import UAParser from 'ua-parser-js';

interface UserAgent {
  userAgent?: string;
  browser?: string;
  device?: 'desktop' | 'tablet';
  resolution?: string;
}

const useUserAgent = () => {
  const windowClient = 'object' === typeof window;

  const getUserAgent = useCallback((): UserAgent => {
    if (windowClient) {
      const results = new UAParser(window.navigator.userAgent)?.getResult();
      const browser = results?.browser;
      
      return {
        userAgent: window.navigator.userAgent,
        browser: browser ? `${browser.name} ${browser?.version}` : undefined,
        device: results?.device?.type === undefined ? 'desktop' : 'tablet', // mobile?
        resolution: `${window.innerWidth}x${window.innerHeight}`
      };
    }
    return {
      userAgent: undefined,
      browser: undefined,
      device: undefined,
      resolution: undefined,
    };
  }, [windowClient]);

  const [userAgent, setUserAgent] = useState(getUserAgent);

  useEffect(() => {
    if (!windowClient) {
      return;
    }
    function handleLoad() {
      setUserAgent(getUserAgent());
    }
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);
  }, [windowClient, getUserAgent]);

  return userAgent;
};

export default useUserAgent;
