import { ShowModalParams } from 'context/ModalContext';
import { TranslateFn } from 'i18n';
import { KeyboardEvent } from 'react';
import ContractSwitcher from './ContractSwitcher';

export const blockEnterKey = <T,>(keyEvent: KeyboardEvent<T>) => {
  if (keyEvent.key === 'Enter') {
    keyEvent.preventDefault();
  }
};

export const contractSwitcherModalParams = (
  t: TranslateFn,
  defaultContractId?: string,
  onlyOnboarding?: boolean,
): ShowModalParams => ({
  title: t('global_texts.modals.selectCompany.header', 'Select a company'),
  confirmText: t('global_texts.modals.selectCompany.continue', 'Continue with selected'),
  // eslint-disable-next-line react/display-name
  children: (formikFormRef, onSubmit) => (
    <ContractSwitcher
      ref={formikFormRef}
      defaultContractId={defaultContractId}
      onlyOnboarding={onlyOnboarding}
      onSubmit={onSubmit}
      t={t}
    />
  ),
  size: 'xxl',
});
