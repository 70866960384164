import useSWR from 'swr';
import { useCallback, useMemo } from 'react';

const isServerSide: () => boolean = () => typeof window === 'undefined';

/**
 * Gets and sets value to/from local storage.
 *
 * @param key key to get and set value to/from local storage.
 * @param defaultValue default value that is returned in case the key was not found.
 *
 * @returns an array of (the saved value, set value function, and remove value function) in the same order.
 */
const useLocalStorage = <T>(key: string, defaultValue: T | null = null) => {
  let initialValue: string = JSON.stringify(defaultValue);

  if (!isServerSide()) {
    try {
      let storedValue = window.localStorage.getItem(key);
      if (storedValue !== null && storedValue !== 'undefined') initialValue = storedValue;
    } catch (e) {}
  }

  // TODO: refresh?
  const { data: value = initialValue, mutate } = useSWR<string>(key, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenHidden: false,
    refreshWhenOffline: false,
  });

  // ========== Set value ==========
  const setValue = useCallback(
    async (value: T) => {
      try {
        const localStorageValue = JSON.stringify(value);

        await mutate(localStorageValue, false);

        if (isServerSide()) {
          return;
        }

        // Save to local storage
        window.localStorage.setItem(key, localStorageValue);
      } catch (e) {}
    },
    [key, mutate],
  );

  // ========== Remove value ==========
  const removeValue = useCallback(async () => {
    try {
      await mutate(JSON.stringify(defaultValue), false);

      if (isServerSide()) {
        return;
      }

      // Remove value from local storage
      window.localStorage.removeItem(key);
    } catch (e) {}
  }, [defaultValue, key, mutate]);

  // TODO: introduce smart solutions to reduce re-renders
  const returnValue = useMemo(() => {
    let parsedValue;
    try {
      parsedValue = JSON.parse(value);
    } catch (e) {}
    return { value: parsedValue, setValue, removeValue };
  }, [value, setValue, removeValue]);

  return returnValue;
};

export default useLocalStorage;
