import { AxiosError, AxiosResponse } from 'axios';

export interface HttpCallbackOptions {
  currentLocale: string;
  providerId: string;
}
export type HttpCallbackFn = (response: AxiosResponse, options: HttpCallbackOptions) => void;
export type HttpCallbacksType = Record<number, HttpCallbackFn>;
export type ExcludedHttpCallbacksType = number[];

export const EXCLUDE_HTTP_CALLBACKS = 'EXCLUDE_HTTP_CALLBACKS';

export const getSuccessResponseInterceptor =
  (httpCallbacks: HttpCallbacksType, callbackOptions: HttpCallbackOptions) =>
  (response: AxiosResponse) => {
    if (
      response !== undefined &&
      response.status !== undefined &&
      response.status in httpCallbacks
    ) {
      httpCallbacks[response.status](response, callbackOptions);
    }

    return Promise.resolve(response);
  };

export const getErrorResponseInterceptor =
  (
    httpCallbacks: HttpCallbacksType,
    excludedCallbacks: ExcludedHttpCallbacksType,
    callbackOptions: HttpCallbackOptions,
  ) =>
  (error: AxiosError) => {
    const { response } = error;

    if (
      response !== undefined &&
      response.status !== undefined &&
      response.status in httpCallbacks &&
      // @ts-ignore
      !(excludedCallbacks.includes(response.status) && response.config?.[EXCLUDE_HTTP_CALLBACKS])
    ) {
      httpCallbacks[response.status](response, callbackOptions);
    }

    return Promise.reject(error);
  };
