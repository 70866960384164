import markupParser from 'html-react-parser';
import { get, isString, set } from 'lodash';
import {
  BlockQuery,
  Locale,
  Messages,
  Namespace,
  NamespaceDescriptor,
  NamespacesReadyFn,
  RequestId,
  RequestStatus,
  ToReplace,
  TranslateFn,
  TranslateGroupFn,
  TranslateHtmlFn,
  TranslateOptions,
  TranslateQueryKeyFn,
  Value,
} from './types';

// Constants
const transversePrefix = 'transverse.'; // Transverse stack
const separator = '.';
const wildcard = '*';
const emptyReplace = {};
const nonExistingQueryPath = 'doesnt-exist-in-path';

const sanitizeKey = (key: string) =>
  key
    .replace(/([A-Z][a-z0-9])/g, '_$1')
    .toLowerCase()
    .replace(/\._/g, separator);

const normalizePath = (messages: Messages, path: string): string | string[] =>
  Object.keys(messages).find((key) => new RegExp(`^${sanitizeKey(path)}\$`).test(key)) || [];

const normalizeQueryPath = (messages: Messages, query: BlockQuery): string =>
  Object.entries(messages).find(
    ([key, value]) => new RegExp(`^${sanitizeKey(query.where)}\$`).test(key) && value == query.is,
  )?.[0] || nonExistingQueryPath;

const alignPathByQueryPath = (path: string, queryPath: string) => {
  if (queryPath === nonExistingQueryPath) return nonExistingQueryPath;

  const splitQueryPath = queryPath.split(separator);
  return path
    .split(separator)
    .map((word, index) =>
      word === wildcard && splitQueryPath[index] ? splitQueryPath[index] : word,
    )
    .join(separator);
};

const getTranslation = (
  messages: Messages,
  rawPath: string,
  defaultValue?: Value,
  options: Omit<ToReplace, keyof TranslateOptions> & TranslateOptions = emptyReplace,
) => {
  let path = rawPath;
  if (options.query) {
    path = alignPathByQueryPath(rawPath, normalizeQueryPath(messages, options.query));
  }

  return get(messages, normalizePath(messages, path), defaultValue)?.replace(
    /%(\w+)%/g,
    (_: any, k: string) => `${options?.replace?.[k] ?? options?.[k] ?? `%${k}%`}`,
  );
};

export const namespacesReady =
  (requests: Record<RequestId, RequestStatus>, locale: Locale): NamespacesReadyFn =>
  (countryCode: string, ...rawNamespaces: Namespace[]) => {
    const namespaces = reduceNamespaces(rawNamespaces, countryCode);
    return namespaces.every(
      (namespace) =>
        requests[`${locale}.${namespace}`] === RequestStatus.Error ||
        requests[`${locale}.${namespace}`] === RequestStatus.Success,
    );
  };

export const translate =
  (messages: Messages): TranslateFn =>
  (path, defaultValue, options = emptyReplace) =>
    getTranslation(messages, path, defaultValue, options);

export const translateHtml =
  (messages: Messages): TranslateHtmlFn =>
  (path, defaultValue, options = emptyReplace) =>
    markupParser(getTranslation(messages, path, defaultValue, options));

export const translateGroup =
  (currentMessages: Messages, fallbackMessages?: Messages): TranslateGroupFn =>
  (rawGroupKey) => {
    const groupKey = sanitizeKey(rawGroupKey);
    let innerKeys = Object.keys(currentMessages).filter((key) => key.startsWith(groupKey));
    let messages = currentMessages;

    if (innerKeys.length === 0 && fallbackMessages) {
      innerKeys = Object.keys(fallbackMessages).filter((key) => key.startsWith(groupKey));
      messages = fallbackMessages;
    }

    const collectionValues: Record<string, any> = {};

    innerKeys.forEach((k) => {
      set(collectionValues, k, messages[k]);
    });

    return get(collectionValues, groupKey) || [];
  };

export const translateQueryKey =
  (messages: Messages): TranslateQueryKeyFn =>
  (key, query) =>
    alignPathByQueryPath(key, normalizeQueryPath(messages, query));

export const extractNamespace = (key: Namespace): string => {
  const ns = isString(key) ? key : key.name;
  const split = ns.split(separator);
  if (ns.indexOf(transversePrefix) === 0) {
    return `${transversePrefix}${split[1]}`;
  }
  return split[0];
};

export const normalizeNamespace = (ns: Namespace): NamespaceDescriptor =>
  isString(ns) ? { name: ns } : ns;

export const normalizeNamespaces = (namespaces: Namespace[]): NamespaceDescriptor[] =>
  namespaces.map(normalizeNamespace);

export const reduceNamespaces = (namespaces: Namespace[], countryCode: string): string[] =>
  namespaces.reduce((result, rawNamespace) => {
    const namespace = normalizeNamespace(rawNamespace);
    if (!Boolean(namespace.ifCountry && namespace.ifCountry !== countryCode)) {
      result.push(namespace.name);
    }
    return result;
  }, [] as string[]);
