import { get, without } from 'lodash';
import { Reducer } from 'react';
import {
  I18nStateType,
  Messages,
  ReducerAction,
  ReducerActionType,
  RequestId,
  RequestStatus,
} from './types';

export const initialState: I18nStateType = {
  activeRequest: null,
  pendingRequests: [],
  requests: {},
  messages: {},
  loadedMessages: {},
  ready: false,
};

const I18nReducer: Reducer<I18nStateType, ReducerAction> = (state, action) => {
  switch (action.type) {
    case ReducerActionType.QUEUE_REQUEST: {
      const { request: rawRequest } = action;
      const request = { ...rawRequest };
      const requestId: RequestId = `${request.locale}.${request.namespaces.join(';')}`;
      const status = get(state.requests, [requestId], RequestStatus.Init);
      const toFetchNamespaces: string[] = [];
      const toFetchMessages = request.namespaces.reduce(
        (result, namespace) => {
          const nsId: RequestId = `${request.locale}.${namespace}`;
          const nsStatus = get(state.loadedMessages, [nsId], RequestStatus.Init);
          if (nsStatus === RequestStatus.Init) {
            toFetchNamespaces.push(namespace);
            result[nsId] = RequestStatus.Waiting;
          }
          return result;
        },
        {} as Record<RequestId, RequestStatus>,
      );

      if (Object.keys(toFetchMessages).length > 0 && status === RequestStatus.Init) {
        request.namespaces = toFetchNamespaces;
        return {
          ...state,
          activeRequest: state.activeRequest === null ? request : state.activeRequest,
          ready: false,
          requests: { ...state.requests, [requestId]: RequestStatus.Waiting },
          pendingRequests: [...state.pendingRequests, request],
          loadedMessages: { ...state.loadedMessages, ...toFetchMessages },
        };
      }

      return state;
    }
    case ReducerActionType.COMPLETE_REQUEST: {
      const { request } = action;
      const requestId: RequestId = `${request.locale}.${request.namespaces.join(';')}`;
      const pendingRequests = without(state.pendingRequests, request);
      let messages: Messages = {};
      const loadedMessages = request.namespaces.reduce(
        (result, namespace, index) => {
          const nsId: RequestId = `${request.locale}.${namespace}`;
          const nsStatus = action.messages[index] ? RequestStatus.Success : RequestStatus.Error;
          result[nsId] = nsStatus;
          if (nsStatus === RequestStatus.Success)
            messages = { ...messages, ...action.messages[index] };
          return result;
        },
        {} as Record<RequestId, RequestStatus>,
      );

      return {
        ...state,
        activeRequest: pendingRequests[0] || null,
        pendingRequests,
        requests: { ...state.requests, [requestId]: action.status },
        messages: {
          ...state.messages,
          [request.locale]: {
            ...state.messages[request.locale],
            ...messages,
          },
        },
        loadedMessages: { ...state.loadedMessages, ...loadedMessages },
        ready: pendingRequests.length === 0,
      };
    }
    default:
      return state;
  }
};

export default I18nReducer;
