import { Box, Text } from '@chakra-ui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ComponentType, ReactNode, UIEvent, useCallback, useEffect, useRef } from 'react';
import { RenderComponentProps } from './types';

interface VirtualizedListProps<FilterState = string, Data = any> {
  selectedContractId?: string;
  filterState: FilterState;
  hasNextPage?: boolean;
  height?: number | string;
  highlightItem?: (item: Data) => boolean;
  initialScrollOffset?: number;
  isEmpty?: boolean;
  isNextPageLoading?: boolean;
  items: Data[];
  itemSize: number;
  loadNextPage?: (startIndex: number, stopIndex: number) => Promise<void>;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
  pageSize?: number;
  reachedEndText?: string;
  pReachedEnd?: number;
  render: ComponentType<RenderComponentProps<Data>>;
  renderInner?: ReactNode;
  width?: string | number;
}

const nop = () => Promise.resolve();
const nopBool = () => false;

const VirtualizedList = <FilterState extends unknown, Data = any>({
  filterState,
  hasNextPage = false,
  height,
  highlightItem = nopBool,
  initialScrollOffset,
  isEmpty = false,
  isNextPageLoading = false,
  items,
  itemSize,
  loadNextPage = nop,
  onScroll,
  pageSize = 20,
  pReachedEnd = 4,
  reachedEndText,
  render: RenderItem,
  renderInner,
  width = '100%',
}: VirtualizedListProps<FilterState, Data>) => {
  const pageRef = useRef(0);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hasMountedRef = useRef(false);
  const itemCount = hasNextPage ? items.length + pageSize : items.length;

  const { getVirtualItems, getTotalSize, options, scrollToOffset } = useVirtualizer({
    horizontal: false,
    count: itemCount,
    getScrollElement: () => containerRef.current,
    estimateSize: useCallback(() => itemSize, [itemSize]),
    overscan: 1,
    scrollMargin: 0,
    initialOffset: initialScrollOffset ?? 0,
  });

  const totalSize = getTotalSize();
  const virtualRows = getVirtualItems();
  const virtualRowsLength = virtualRows.length;
  const paddingTop =
    virtualRowsLength > 0 ? virtualRows?.[0]?.start - options.scrollMargin || 0 : 0;
  const paddingBottom =
    virtualRowsLength > 0
      ? options.scrollMargin + totalSize - (virtualRows?.[virtualRowsLength - 1]?.end || 0)
      : 0;
  const lastItem = virtualRows?.[virtualRowsLength - 1];

  // Each time the filterState prop changed we reset page counter to clear the cache
  useEffect(() => {
    if (containerRef.current && hasMountedRef.current) {
      pageRef.current = 0;
    }
    hasMountedRef.current = true;
  }, [filterState]);

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length],
  );
  // If there are no more items to be displayed, show a text
  const reachedEnd = !(hasNextPage || isNextPageLoading) || isEmpty ? reachedEndText : undefined;
  const isOutsideRange = lastItem?.index > items.length;

  useEffect(() => {
    // TODO: maybe add some condition to not load multiple pages at once
    if (isOutsideRange && hasNextPage && !isNextPageLoading) {
      loadNextPage(pageSize * pageRef.current, pageSize * (pageRef.current + 1));
      pageRef.current += 1;
    }
  }, [hasNextPage, isNextPageLoading, isOutsideRange, loadNextPage, pageSize]);

  useEffect(() => {
    if (onScroll) {
      requestAnimationFrame(() => {
        scrollToOffset(initialScrollOffset ?? 0, { align: 'start' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScroll]);

  return (
    <Box
      position="relative"
      ref={containerRef}
      onScroll={onScroll}
      overscrollBehavior="contain"
      overflow="auto"
      width={width}
      minHeight={`${itemSize}px`}
      height={`calc(${totalSize}px + 1.5em)`} // 1.5 for body.largeBold
      maxHeight={height}
      sx={{ overflowAnchor: 'none' }}
    >
      {renderInner ? (
        renderInner
      ) : (
        <>
          {paddingTop > 0 && <div style={{ height: `${paddingTop}px` }} />}
          {virtualRows.map((virtualRow, key) => {
            const item = items[virtualRow.index];

            return (
              <RenderItem
                key={key}
                index={virtualRow.index}
                item={item}
                isHighlighted={isItemLoaded(virtualRow.index) && highlightItem(item)}
                isLoaded={isItemLoaded(virtualRow.index)}
              />
            );
          })}
          {paddingBottom > 0 && <div style={{ height: `${paddingBottom}px` }} />}
          {reachedEnd && (
            <Text variant="body.largeBold" p={pReachedEnd} align="center">
              {reachedEnd}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default VirtualizedList;
