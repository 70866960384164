/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssignedContractsResponse } from '../models';
// @ts-ignore
import { CompanyAddressRequest } from '../models';
// @ts-ignore
import { CompanyRequest } from '../models';
// @ts-ignore
import { ContractFinanceRequest } from '../models';
// @ts-ignore
import { ContractLandingPageEmployeesResponse } from '../models';
// @ts-ignore
import { ContractLandingPageLocationsResponse } from '../models';
// @ts-ignore
import { ContractProductRequest } from '../models';
// @ts-ignore
import { ContractProductsResponse } from '../models';
// @ts-ignore
import { ContractResponse } from '../models';
// @ts-ignore
import { ContractServicesRequest } from '../models';
// @ts-ignore
import { ContractStatesResponse } from '../models';
// @ts-ignore
import { ErrorMessageResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { LocationStatusEnum } from '../models';
// @ts-ignore
import { LocationsProductRequest } from '../models';
// @ts-ignore
import { MyContractResponse } from '../models';
// @ts-ignore
import { ProductLocationsResponse } from '../models';
// @ts-ignore
import { ProductLocationsSortingEnum } from '../models';
// @ts-ignore
import { RemittanceSheetBulkCreateRequest } from '../models';
// @ts-ignore
import { RemittanceSheetListResponse } from '../models';
// @ts-ignore
import { RemittanceSheetResponse } from '../models';
// @ts-ignore
import { ScheduledTask } from '../models';
// @ts-ignore
import { ServicesUpdateErrorResponse } from '../models';
// @ts-ignore
import { SuccessResponse } from '../models';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Edit the address
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyAddressRequest} companyAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractAddressEdit: async (contractUid: string, companyAddressRequest: CompanyAddressRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractAddressEdit', 'contractUid', contractUid)
            // verify required parameter 'companyAddressRequest' is not null or undefined
            assertParamExists('contractAddressEdit', 'companyAddressRequest', companyAddressRequest)
            const localVarPath = `/api/contract/{contractUid}/address`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the company data
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyRequest} companyRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCompanyEdit: async (contractUid: string, companyRequest: CompanyRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractCompanyEdit', 'contractUid', contractUid)
            // verify required parameter 'companyRequest' is not null or undefined
            assertParamExists('contractCompanyEdit', 'companyRequest', companyRequest)
            const localVarPath = `/api/contract/{contractUid}/company`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the finance settings
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractFinanceRequest} contractFinanceRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFinanceEdit: async (contractUid: string, contractFinanceRequest: ContractFinanceRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractFinanceEdit', 'contractUid', contractUid)
            // verify required parameter 'contractFinanceRequest' is not null or undefined
            assertParamExists('contractFinanceEdit', 'contractFinanceRequest', contractFinanceRequest)
            const localVarPath = `/api/contract/{contractUid}/finance`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractFinanceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLandingpageEmployees: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractLandingpageEmployees', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/landing-page/employees`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLandingpageLocations: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractLandingpageLocations', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/landing-page/locations`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductAdd: async (contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProductAdd', 'contractUid', contractUid)
            // verify required parameter 'contractProductRequest' is not null or undefined
            assertParamExists('contractProductAdd', 'contractProductRequest', contractProductRequest)
            const localVarPath = `/api/contract/{contractUid}/product`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product from the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product uid viz. &#x60;/api/products&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductDelete: async (contractUid: string, productCode: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProductDelete', 'contractUid', contractUid)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('contractProductDelete', 'productCode', productCode)
            const localVarPath = `/api/contract/{contractUid}/product/{productCode}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"productCode"}}`, encodeURIComponent(String(productCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the contract product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductEdit: async (contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProductEdit', 'contractUid', contractUid)
            // verify required parameter 'contractProductRequest' is not null or undefined
            assertParamExists('contractProductEdit', 'contractProductRequest', contractProductRequest)
            const localVarPath = `/api/contract/{contractUid}/product`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all locations with Location soft detail and product relation state
         * @summary List locations with product relation state
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [productCode] product code viz. &#x60;/api/products&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {ProductLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductLocationsGet: async (contractUid: string, ngmCountry?: 'cz' | 'be', productCode?: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: ProductLocationsSortingEnum, sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProductLocationsGet', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/product-locations`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (states) {
                localVarQueryParameter['states'] = states.join(COLLECTION_FORMATS.csv);
            }

            if (includeInactive !== undefined) {
                localVarQueryParameter['includeInactive'] = includeInactive;
            }

            if (approvedOnly !== undefined) {
                localVarQueryParameter['approvedOnly'] = approvedOnly;
            }

            if (withPaperProduct !== undefined) {
                localVarQueryParameter['withPaperProduct'] = withPaperProduct;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach/Detach locations to product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product code viz. &#x60;/api/products&#x60;
         * @param {LocationsProductRequest} locationsProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductLocationsSet: async (contractUid: string, productCode: string, locationsProductRequest: LocationsProductRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProductLocationsSet', 'contractUid', contractUid)
            // verify required parameter 'productCode' is not null or undefined
            assertParamExists('contractProductLocationsSet', 'productCode', productCode)
            // verify required parameter 'locationsProductRequest' is not null or undefined
            assertParamExists('contractProductLocationsSet', 'locationsProductRequest', locationsProductRequest)
            const localVarPath = `/api/contract/{contractUid}/product-locations`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationsProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Products on the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProducts: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractProducts', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/products`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Contract detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRead: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRead', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download single remmitance sheet file.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownload: async (contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittanceDownload', 'contractUid', contractUid)
            // verify required parameter 'remittanceSheetUid' is not null or undefined
            assertParamExists('contractRemittanceDownload', 'remittanceSheetUid', remittanceSheetUid)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet/{remittanceSheetUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"remittanceSheetUid"}}`, encodeURIComponent(String(remittanceSheetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {RemittanceSheetBulkCreateRequest} remittanceSheetBulkCreateRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownloadBulkCreate: async (contractUid: string, remittanceSheetBulkCreateRequest: RemittanceSheetBulkCreateRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittanceDownloadBulkCreate', 'contractUid', contractUid)
            // verify required parameter 'remittanceSheetBulkCreateRequest' is not null or undefined
            assertParamExists('contractRemittanceDownloadBulkCreate', 'remittanceSheetBulkCreateRequest', remittanceSheetBulkCreateRequest)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet/bulk`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(remittanceSheetBulkCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} bulkId Bulk &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownloadBulkDownload: async (contractUid: string, bulkId: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittanceDownloadBulkDownload', 'contractUid', contractUid)
            // verify required parameter 'bulkId' is not null or undefined
            assertParamExists('contractRemittanceDownloadBulkDownload', 'bulkId', bulkId)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet/bulk/{bulkId}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"bulkId"}}`, encodeURIComponent(String(bulkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all remittance sheets generated by current user.
         * @summary List of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locations] Location uids to filter
         * @param {Array<string>} [products] Products codes to filter
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Remittance sheet amount from
         * @param {number} [amountTill] Remittance sheet amount till
         * @param {string} [author] First and last name of the driver
         * @param {string} [fulltext] Fulltext search string
         * @param {'location' | 'created_at' | 'total_amount' | 'author'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceGet: async (contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locations?: Array<string>, products?: Array<string>, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, author?: string, fulltext?: string, sortBy?: 'location' | 'created_at' | 'total_amount' | 'author', sortDesc?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittanceGet', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (locations) {
                localVarQueryParameter['locations'] = locations.join(COLLECTION_FORMATS.csv);
            }

            if (products) {
                localVarQueryParameter['products'] = products.join(COLLECTION_FORMATS.csv);
            }

            if (createdFrom !== undefined) {
                localVarQueryParameter['createdFrom'] = createdFrom;
            }

            if (createdTill !== undefined) {
                localVarQueryParameter['createdTill'] = createdTill;
            }

            if (amountFrom !== undefined) {
                localVarQueryParameter['amountFrom'] = amountFrom;
            }

            if (amountTill !== undefined) {
                localVarQueryParameter['amountTill'] = amountTill;
            }

            if (author !== undefined) {
                localVarQueryParameter['author'] = author;
            }

            if (fulltext !== undefined) {
                localVarQueryParameter['fulltext'] = fulltext;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortDesc !== undefined) {
                localVarQueryParameter['sortDesc'] = sortDesc;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a remmitance sheet and upload the file to the storage.
         * @summary Create a remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {any} file The uploaded file
         * @param {string} json 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittancePost: async (contractUid: string, file: any, json: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittancePost', 'contractUid', contractUid)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('contractRemittancePost', 'file', file)
            // verify required parameter 'json' is not null or undefined
            assertParamExists('contractRemittancePost', 'json', json)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (json !== undefined) { 
                localVarFormParams.append('json', json as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceSheetDelete: async (contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractRemittanceSheetDelete', 'contractUid', contractUid)
            // verify required parameter 'remittanceSheetUid' is not null or undefined
            assertParamExists('contractRemittanceSheetDelete', 'remittanceSheetUid', remittanceSheetUid)
            const localVarPath = `/api/contract/{contractUid}/remittance-sheet/{remittanceSheetUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)))
                .replace(`{${"remittanceSheetUid"}}`, encodeURIComponent(String(remittanceSheetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the selected services
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractServicesRequest} contractServicesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractServicesEdit: async (contractUid: string, contractServicesRequest: ContractServicesRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractServicesEdit', 'contractUid', contractUid)
            // verify required parameter 'contractServicesRequest' is not null or undefined
            assertParamExists('contractServicesEdit', 'contractServicesRequest', contractServicesRequest)
            const localVarPath = `/api/contract/{contractUid}/services`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractServicesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractStates: async (contractUid: string, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('contractStates', 'contractUid', contractUid)
            const localVarPath = `/api/contract/{contractUid}/states`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of my assigned contracts
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [search] string for fulltext search in all available contracts
         * @param {boolean} [onboardingOnly] Find contracts with unfinished onboarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contracts: async (ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, search?: string, onboardingOnly?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (onboardingOnly !== undefined) {
                localVarQueryParameter['onboardingOnly'] = onboardingOnly;
            }

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detail of assigned contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myContract: async (contractUid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractUid' is not null or undefined
            assertParamExists('myContract', 'contractUid', contractUid)
            const localVarPath = `/api/my-contract/{contractUid}`
                .replace(`{${"contractUid"}}`, encodeURIComponent(String(contractUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Edit the address
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyAddressRequest} companyAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractAddressEdit(contractUid: string, companyAddressRequest: CompanyAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractAddressEdit(contractUid, companyAddressRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the company data
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyRequest} companyRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractCompanyEdit(contractUid: string, companyRequest: CompanyRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractCompanyEdit(contractUid, companyRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the finance settings
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractFinanceRequest} contractFinanceRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractFinanceEdit(contractUid: string, contractFinanceRequest: ContractFinanceRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractFinanceEdit(contractUid, contractFinanceRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractLandingpageEmployees(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractLandingPageEmployeesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractLandingpageEmployees(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractLandingpageLocations(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractLandingPageLocationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractLandingpageLocations(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add product to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProductAdd(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProductAdd(contractUid, contractProductRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete product from the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product uid viz. &#x60;/api/products&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProductDelete(contractUid: string, productCode: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProductDelete(contractUid, productCode, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the contract product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProductEdit(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProductEdit(contractUid, contractProductRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all locations with Location soft detail and product relation state
         * @summary List locations with product relation state
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [productCode] product code viz. &#x60;/api/products&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {ProductLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProductLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', productCode?: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: ProductLocationsSortingEnum, sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductLocationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProductLocationsGet(contractUid, ngmCountry, productCode, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attach/Detach locations to product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product code viz. &#x60;/api/products&#x60;
         * @param {LocationsProductRequest} locationsProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProductLocationsSet(contractUid: string, productCode: string, locationsProductRequest: LocationsProductRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProductLocationsSet(contractUid, productCode, locationsProductRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Products on the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractProducts(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractProducts(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Contract detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRead(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRead(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download single remmitance sheet file.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittanceDownload(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittanceDownload(contractUid, remittanceSheetUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {RemittanceSheetBulkCreateRequest} remittanceSheetBulkCreateRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittanceDownloadBulkCreate(contractUid: string, remittanceSheetBulkCreateRequest: RemittanceSheetBulkCreateRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittanceDownloadBulkCreate(contractUid, remittanceSheetBulkCreateRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} bulkId Bulk &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittanceDownloadBulkDownload(contractUid: string, bulkId: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittanceDownloadBulkDownload(contractUid, bulkId, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all remittance sheets generated by current user.
         * @summary List of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locations] Location uids to filter
         * @param {Array<string>} [products] Products codes to filter
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Remittance sheet amount from
         * @param {number} [amountTill] Remittance sheet amount till
         * @param {string} [author] First and last name of the driver
         * @param {string} [fulltext] Fulltext search string
         * @param {'location' | 'created_at' | 'total_amount' | 'author'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittanceGet(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locations?: Array<string>, products?: Array<string>, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, author?: string, fulltext?: string, sortBy?: 'location' | 'created_at' | 'total_amount' | 'author', sortDesc?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemittanceSheetListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittanceGet(contractUid, ngmCountry, pageNumber, pageSize, locations, products, createdFrom, createdTill, amountFrom, amountTill, author, fulltext, sortBy, sortDesc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a remmitance sheet and upload the file to the storage.
         * @summary Create a remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {any} file The uploaded file
         * @param {string} json 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittancePost(contractUid: string, file: any, json: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemittanceSheetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittancePost(contractUid, file, json, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractRemittanceSheetDelete(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractRemittanceSheetDelete(contractUid, remittanceSheetUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the selected services
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractServicesRequest} contractServicesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractServicesEdit(contractUid: string, contractServicesRequest: ContractServicesRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractServicesEdit(contractUid, contractServicesRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractStates(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractStatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractStates(contractUid, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of my assigned contracts
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [search] string for fulltext search in all available contracts
         * @param {boolean} [onboardingOnly] Find contracts with unfinished onboarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contracts(ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, search?: string, onboardingOnly?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignedContractsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contracts(ngmCountry, pageNumber, pageSize, search, onboardingOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Detail of assigned contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myContract(contractUid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyContractResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myContract(contractUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Edit the address
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyAddressRequest} companyAddressRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractAddressEdit(contractUid: string, companyAddressRequest: CompanyAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.contractAddressEdit(contractUid, companyAddressRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the company data
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {CompanyRequest} companyRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractCompanyEdit(contractUid: string, companyRequest: CompanyRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.contractCompanyEdit(contractUid, companyRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the finance settings
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractFinanceRequest} contractFinanceRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFinanceEdit(contractUid: string, contractFinanceRequest: ContractFinanceRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.contractFinanceEdit(contractUid, contractFinanceRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLandingpageEmployees(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractLandingPageEmployeesResponse> {
            return localVarFp.contractLandingpageEmployees(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract landingpage detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractLandingpageLocations(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractLandingPageLocationsResponse> {
            return localVarFp.contractLandingpageLocations(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add product to contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductAdd(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.contractProductAdd(contractUid, contractProductRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete product from the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product uid viz. &#x60;/api/products&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductDelete(contractUid: string, productCode: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.contractProductDelete(contractUid, productCode, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the contract product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractProductRequest} contractProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductEdit(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.contractProductEdit(contractUid, contractProductRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * List all locations with Location soft detail and product relation state
         * @summary List locations with product relation state
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {string} [productCode] product code viz. &#x60;/api/products&#x60;
         * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
         * @param {boolean} [includeInactive] Include an inactive items into the result
         * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
         * @param {boolean} [withPaperProduct] Items with attached paper product
         * @param {string} [fulltext] Text sentecne for serach recodrs
         * @param {number} [pageNumber] result page to display
         * @param {number} [pageSize] count of entries displayed on the page
         * @param {ProductLocationsSortingEnum} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', productCode?: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: ProductLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<ProductLocationsResponse> {
            return localVarFp.contractProductLocationsGet(contractUid, ngmCountry, productCode, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach/Detach locations to product
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} productCode product code viz. &#x60;/api/products&#x60;
         * @param {LocationsProductRequest} locationsProductRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProductLocationsSet(contractUid: string, productCode: string, locationsProductRequest: LocationsProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.contractProductLocationsSet(contractUid, productCode, locationsProductRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Products on the contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractProducts(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractProductsResponse> {
            return localVarFp.contractProducts(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Contract detail
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRead(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.contractRead(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download single remmitance sheet file.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownload(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractRemittanceDownload(contractUid, remittanceSheetUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {RemittanceSheetBulkCreateRequest} remittanceSheetBulkCreateRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownloadBulkCreate(contractUid: string, remittanceSheetBulkCreateRequest: RemittanceSheetBulkCreateRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ScheduledTask> {
            return localVarFp.contractRemittanceDownloadBulkCreate(contractUid, remittanceSheetBulkCreateRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a bulk operation to download bulk of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} bulkId Bulk &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceDownloadBulkDownload(contractUid: string, bulkId: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void> {
            return localVarFp.contractRemittanceDownloadBulkDownload(contractUid, bulkId, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * List all remittance sheets generated by current user.
         * @summary List of remittance sheets
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {Array<string>} [locations] Location uids to filter
         * @param {Array<string>} [products] Products codes to filter
         * @param {string} [createdFrom] Filter created from date
         * @param {string} [createdTill] Filter created till date
         * @param {number} [amountFrom] Remittance sheet amount from
         * @param {number} [amountTill] Remittance sheet amount till
         * @param {string} [author] First and last name of the driver
         * @param {string} [fulltext] Fulltext search string
         * @param {'location' | 'created_at' | 'total_amount' | 'author'} [sortBy] Set column for sorting
         * @param {boolean} [sortDesc] Describe if sort is descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceGet(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locations?: Array<string>, products?: Array<string>, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, author?: string, fulltext?: string, sortBy?: 'location' | 'created_at' | 'total_amount' | 'author', sortDesc?: boolean, options?: any): AxiosPromise<RemittanceSheetListResponse> {
            return localVarFp.contractRemittanceGet(contractUid, ngmCountry, pageNumber, pageSize, locations, products, createdFrom, createdTill, amountFrom, amountTill, author, fulltext, sortBy, sortDesc, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a remmitance sheet and upload the file to the storage.
         * @summary Create a remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {any} file The uploaded file
         * @param {string} json 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittancePost(contractUid: string, file: any, json: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<RemittanceSheetResponse> {
            return localVarFp.contractRemittancePost(contractUid, file, json, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete remittance sheet.
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractRemittanceSheetDelete(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.contractRemittanceSheetDelete(contractUid, remittanceSheetUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the selected services
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {ContractServicesRequest} contractServicesRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractServicesEdit(contractUid: string, contractServicesRequest: ContractServicesRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse> {
            return localVarFp.contractServicesEdit(contractUid, contractServicesRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary State of the latest request for each partition
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractStates(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractStatesResponse> {
            return localVarFp.contractStates(contractUid, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of my assigned contracts
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {number} [pageNumber] Results page number
         * @param {number} [pageSize] Results page size
         * @param {string} [search] string for fulltext search in all available contracts
         * @param {boolean} [onboardingOnly] Find contracts with unfinished onboarding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contracts(ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, search?: string, onboardingOnly?: boolean, options?: any): AxiosPromise<AssignedContractsResponse> {
            return localVarFp.contracts(ngmCountry, pageNumber, pageSize, search, onboardingOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detail of assigned contract
         * @param {string} contractUid Contract &#x60;uid&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myContract(contractUid: string, options?: any): AxiosPromise<MyContractResponse> {
            return localVarFp.myContract(contractUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - interface
 * @export
 * @interface ContractApi
 */
export interface ContractApiInterface {
    /**
     * 
     * @summary Edit the address
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {CompanyAddressRequest} companyAddressRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractAddressEdit(contractUid: string, companyAddressRequest: CompanyAddressRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Edit the company data
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {CompanyRequest} companyRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractCompanyEdit(contractUid: string, companyRequest: CompanyRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Edit the finance settings
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractFinanceRequest} contractFinanceRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractFinanceEdit(contractUid: string, contractFinanceRequest: ContractFinanceRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Contract landingpage detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractLandingpageEmployees(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractLandingPageEmployeesResponse>;

    /**
     * 
     * @summary Contract landingpage detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractLandingpageLocations(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractLandingPageLocationsResponse>;

    /**
     * 
     * @summary Add product to contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractProductRequest} contractProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProductAdd(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Delete product from the contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} productCode product uid viz. &#x60;/api/products&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProductDelete(contractUid: string, productCode: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Edit the contract product
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractProductRequest} contractProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProductEdit(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * List all locations with Location soft detail and product relation state
     * @summary List locations with product relation state
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [productCode] product code viz. &#x60;/api/products&#x60;
     * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
     * @param {boolean} [withPaperProduct] Items with attached paper product
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {ProductLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProductLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', productCode?: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: ProductLocationsSortingEnum, sortDesc?: boolean, options?: any): AxiosPromise<ProductLocationsResponse>;

    /**
     * 
     * @summary Attach/Detach locations to product
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} productCode product code viz. &#x60;/api/products&#x60;
     * @param {LocationsProductRequest} locationsProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProductLocationsSet(contractUid: string, productCode: string, locationsProductRequest: LocationsProductRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Products on the contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractProducts(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractProductsResponse>;

    /**
     * 
     * @summary Contract detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRead(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary Download single remmitance sheet file.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittanceDownload(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Create a bulk operation to download bulk of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {RemittanceSheetBulkCreateRequest} remittanceSheetBulkCreateRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittanceDownloadBulkCreate(contractUid: string, remittanceSheetBulkCreateRequest: RemittanceSheetBulkCreateRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ScheduledTask>;

    /**
     * 
     * @summary Create a bulk operation to download bulk of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} bulkId Bulk &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittanceDownloadBulkDownload(contractUid: string, bulkId: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<void>;

    /**
     * List all remittance sheets generated by current user.
     * @summary List of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {Array<string>} [locations] Location uids to filter
     * @param {Array<string>} [products] Products codes to filter
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Remittance sheet amount from
     * @param {number} [amountTill] Remittance sheet amount till
     * @param {string} [author] First and last name of the driver
     * @param {string} [fulltext] Fulltext search string
     * @param {'location' | 'created_at' | 'total_amount' | 'author'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittanceGet(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locations?: Array<string>, products?: Array<string>, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, author?: string, fulltext?: string, sortBy?: 'location' | 'created_at' | 'total_amount' | 'author', sortDesc?: boolean, options?: any): AxiosPromise<RemittanceSheetListResponse>;

    /**
     * Create a remmitance sheet and upload the file to the storage.
     * @summary Create a remittance sheet.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {any} file The uploaded file
     * @param {string} json 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittancePost(contractUid: string, file: any, json: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<RemittanceSheetResponse>;

    /**
     * 
     * @summary Delete remittance sheet.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractRemittanceSheetDelete(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Edit the selected services
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractServicesRequest} contractServicesRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractServicesEdit(contractUid: string, contractServicesRequest: ContractServicesRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractResponse>;

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contractStates(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<ContractStatesResponse>;

    /**
     * 
     * @summary List of my assigned contracts
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [search] string for fulltext search in all available contracts
     * @param {boolean} [onboardingOnly] Find contracts with unfinished onboarding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    contracts(ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, search?: string, onboardingOnly?: boolean, options?: any): AxiosPromise<AssignedContractsResponse>;

    /**
     * 
     * @summary Detail of assigned contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApiInterface
     */
    myContract(contractUid: string, options?: any): AxiosPromise<MyContractResponse>;

}

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI implements ContractApiInterface {
    /**
     * 
     * @summary Edit the address
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {CompanyAddressRequest} companyAddressRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractAddressEdit(contractUid: string, companyAddressRequest: CompanyAddressRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractAddressEdit(contractUid, companyAddressRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the company data
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {CompanyRequest} companyRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractCompanyEdit(contractUid: string, companyRequest: CompanyRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractCompanyEdit(contractUid, companyRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the finance settings
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractFinanceRequest} contractFinanceRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractFinanceEdit(contractUid: string, contractFinanceRequest: ContractFinanceRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractFinanceEdit(contractUid, contractFinanceRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract landingpage detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractLandingpageEmployees(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractLandingpageEmployees(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract landingpage detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractLandingpageLocations(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractLandingpageLocations(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add product to contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractProductRequest} contractProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProductAdd(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractProductAdd(contractUid, contractProductRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete product from the contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} productCode product uid viz. &#x60;/api/products&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProductDelete(contractUid: string, productCode: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractProductDelete(contractUid, productCode, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the contract product
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractProductRequest} contractProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProductEdit(contractUid: string, contractProductRequest: ContractProductRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractProductEdit(contractUid, contractProductRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all locations with Location soft detail and product relation state
     * @summary List locations with product relation state
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {string} [productCode] product code viz. &#x60;/api/products&#x60;
     * @param {Array<LocationStatusEnum>} [states] Object states delimited by comma
     * @param {boolean} [includeInactive] Include an inactive items into the result
     * @param {boolean} [approvedOnly] Only active items - has approved from the BackOffice
     * @param {boolean} [withPaperProduct] Items with attached paper product
     * @param {string} [fulltext] Text sentecne for serach recodrs
     * @param {number} [pageNumber] result page to display
     * @param {number} [pageSize] count of entries displayed on the page
     * @param {ProductLocationsSortingEnum} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProductLocationsGet(contractUid: string, ngmCountry?: 'cz' | 'be', productCode?: string, states?: Array<LocationStatusEnum>, includeInactive?: boolean, approvedOnly?: boolean, withPaperProduct?: boolean, fulltext?: string, pageNumber?: number, pageSize?: number, sortBy?: ProductLocationsSortingEnum, sortDesc?: boolean, options?: any) {
        return ContractApiFp(this.configuration).contractProductLocationsGet(contractUid, ngmCountry, productCode, states, includeInactive, approvedOnly, withPaperProduct, fulltext, pageNumber, pageSize, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach/Detach locations to product
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} productCode product code viz. &#x60;/api/products&#x60;
     * @param {LocationsProductRequest} locationsProductRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProductLocationsSet(contractUid: string, productCode: string, locationsProductRequest: LocationsProductRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractProductLocationsSet(contractUid, productCode, locationsProductRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Products on the contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractProducts(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractProducts(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Contract detail
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRead(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRead(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download single remmitance sheet file.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittanceDownload(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRemittanceDownload(contractUid, remittanceSheetUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a bulk operation to download bulk of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {RemittanceSheetBulkCreateRequest} remittanceSheetBulkCreateRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittanceDownloadBulkCreate(contractUid: string, remittanceSheetBulkCreateRequest: RemittanceSheetBulkCreateRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRemittanceDownloadBulkCreate(contractUid, remittanceSheetBulkCreateRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a bulk operation to download bulk of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} bulkId Bulk &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittanceDownloadBulkDownload(contractUid: string, bulkId: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRemittanceDownloadBulkDownload(contractUid, bulkId, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all remittance sheets generated by current user.
     * @summary List of remittance sheets
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {Array<string>} [locations] Location uids to filter
     * @param {Array<string>} [products] Products codes to filter
     * @param {string} [createdFrom] Filter created from date
     * @param {string} [createdTill] Filter created till date
     * @param {number} [amountFrom] Remittance sheet amount from
     * @param {number} [amountTill] Remittance sheet amount till
     * @param {string} [author] First and last name of the driver
     * @param {string} [fulltext] Fulltext search string
     * @param {'location' | 'created_at' | 'total_amount' | 'author'} [sortBy] Set column for sorting
     * @param {boolean} [sortDesc] Describe if sort is descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittanceGet(contractUid: string, ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, locations?: Array<string>, products?: Array<string>, createdFrom?: string, createdTill?: string, amountFrom?: number, amountTill?: number, author?: string, fulltext?: string, sortBy?: 'location' | 'created_at' | 'total_amount' | 'author', sortDesc?: boolean, options?: any) {
        return ContractApiFp(this.configuration).contractRemittanceGet(contractUid, ngmCountry, pageNumber, pageSize, locations, products, createdFrom, createdTill, amountFrom, amountTill, author, fulltext, sortBy, sortDesc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a remmitance sheet and upload the file to the storage.
     * @summary Create a remittance sheet.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {any} file The uploaded file
     * @param {string} json 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittancePost(contractUid: string, file: any, json: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRemittancePost(contractUid, file, json, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete remittance sheet.
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {string} remittanceSheetUid Remittance sheet &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractRemittanceSheetDelete(contractUid: string, remittanceSheetUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractRemittanceSheetDelete(contractUid, remittanceSheetUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the selected services
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {ContractServicesRequest} contractServicesRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractServicesEdit(contractUid: string, contractServicesRequest: ContractServicesRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractServicesEdit(contractUid, contractServicesRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary State of the latest request for each partition
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractStates(contractUid: string, ngmCountry?: 'cz' | 'be', options?: any) {
        return ContractApiFp(this.configuration).contractStates(contractUid, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of my assigned contracts
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {number} [pageNumber] Results page number
     * @param {number} [pageSize] Results page size
     * @param {string} [search] string for fulltext search in all available contracts
     * @param {boolean} [onboardingOnly] Find contracts with unfinished onboarding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contracts(ngmCountry?: 'cz' | 'be', pageNumber?: number, pageSize?: number, search?: string, onboardingOnly?: boolean, options?: any) {
        return ContractApiFp(this.configuration).contracts(ngmCountry, pageNumber, pageSize, search, onboardingOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detail of assigned contract
     * @param {string} contractUid Contract &#x60;uid&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public myContract(contractUid: string, options?: any) {
        return ContractApiFp(this.configuration).myContract(contractUid, options).then((request) => request(this.axios, this.basePath));
    }
}
