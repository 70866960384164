import useFastField from 'common/forms/useFastField';
import useDeepMemo from 'data/useDeepMemo';
import { FormikHandlers, useFormikContext } from 'formik';
import { FieldHelperProps } from 'formik/dist/types';
import { get, isEqual } from 'lodash';
import { ComponentType, useMemo } from 'react';

export interface FastFieldProps<Value = any> {
  error?: any;
  isTouched: boolean;
  setValue: FieldHelperProps<Value>['setValue'];
  setTouched: FieldHelperProps<Value>['setTouched'];

  name: string;
  checked?: boolean;
  value: Value;
  initialValue: Value;
  onChange: FormikHandlers['handleChange'];
  onBlur: FormikHandlers['handleBlur'];
}

export interface WithFastFieldProps<Value = any> {
  field: FastFieldProps<Value>;
}

// TODO: get rid-of formik !!!
const withFastField = <P extends { name: string }>(
  MemoizedComponent: ComponentType<P & WithFastFieldProps>,
): ComponentType<Omit<P, keyof WithFastFieldProps>> =>
  function WithFastField(props: P) {
    const [field, meta, helpers] = useFastField(props.name);
    const { errors } = useFormikContext();
    const error = get(errors, props.name, undefined); // workaround for nested objects :-/
    const initialValue = useDeepMemo(() => meta.initialValue, [meta.initialValue]);
    const isTouched = Boolean(meta.touched || !isEqual(field.value, initialValue));

    const fieldProps = useMemo<FastFieldProps>(
      () => ({
        error,
        isTouched,
        setValue: helpers.setValue,
        setTouched: helpers.setTouched,

        name: field.name,
        checked: field.checked,
        value: field.value,
        initialValue,
        onChange: field.onChange,
        onBlur: field.onBlur,
      }),
      [
        error,
        field.checked,
        field.name,
        field.onBlur,
        field.onChange,
        field.value,
        helpers.setTouched,
        helpers.setValue,
        initialValue,
        isTouched,
      ],
    );

    return <MemoizedComponent {...props} field={fieldProps} />;
  };

export default withFastField;
