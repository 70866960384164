import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

export const getDateLocale = (locale?: string): Locale => {
  const key = locale as keyof typeof Locales;
  const keySub = locale?.substring(0, 2) as keyof typeof Locales;
  return Locales[key] ?? Locales[keySub] ?? Locales.enGB;
};

export const loadLocale = (locale: string) => import(`date-fns/locale/${locale}/index.js`);

export const setDateLocale = (currentLocale: string) => {
  const locale = getDateLocale(currentLocale);
  const code = locale.code || currentLocale;

  registerLocale(code, locale);
  setDefaultLocale(code);
  
  return locale;
}
