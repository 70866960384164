import Router from 'next/router';
import env from 'env';
import { UrlObject } from 'url';

type Url = UrlObject | string;

const routerPush = (url: Url) => {
  if (Router.router) {
    if (url === Router.router.pathname) {
      Router.replace(url);
    } else {
      Router.push(url);
    }
  }
};
export const routerReplace = (url: Url) => {
  if (Router.router) {
    Router.replace(url);
  }
};

export const getFullBasePath = () => {
  if (typeof window !== 'undefined' && window.location) {
    const proto = window.location.protocol;
    const host = window.location.hostname;
    const port = window.location.port ? `:${window.location.port}` : '';
    const base = env.baseUrl || '';
    const locale =
      Router?.locale && Router?.locale !== Router?.defaultLocale ? `/${Router.locale}` : '';
    return `${proto}//${host}${port}${base}${locale}`;
  }
  return undefined;
};

export const getFullPath = () => {
  if (typeof window !== 'undefined' && window.location) {
    return window.location.href;
  }
  return undefined;
}

export default routerPush;
