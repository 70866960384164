/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CompanyData } from './company-data';

/**
 * 
 * @export
 * @interface FindMeResponse
 */
export interface FindMeResponse {
    /**
     * Status in the national register
     * @type {string}
     * @memberof FindMeResponse
     */
    statusNational?: FindMeResponseStatusNationalEnum;
    /**
     * Status in the CountryFixtures BO system
     * @type {string}
     * @memberof FindMeResponse
     */
    statusBo?: FindMeResponseStatusBoEnum;
    /**
     * Status in the CountryFixtures BO system on contract
     * @type {string}
     * @memberof FindMeResponse
     */
    statusBoContract?: FindMeResponseStatusBoContractEnum;
    /**
     * Is the company affliated in the NGM already?
     * @type {boolean}
     * @memberof FindMeResponse
     */
    statusNgm: boolean;
    /**
     * Company master ID from the Backoffice system
     * @type {string}
     * @memberof FindMeResponse
     */
    masterId?: string | null;
    /**
     * 
     * @type {CompanyData}
     * @memberof FindMeResponse
     */
    entry?: CompanyData;
}

/**
    * @export
    * @enum {string}
    */
export enum FindMeResponseStatusNationalEnum {
    AresError = 'ARES_ERROR',
    Eligible = 'ELIGIBLE',
    Ineligible = 'INELIGIBLE',
    NotFound = 'NOT_FOUND'
}
/**
    * @export
    * @enum {string}
    */
export enum FindMeResponseStatusBoEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotFound = 'NOT_FOUND',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum FindMeResponseStatusBoContractEnum {
    Active = 'ACTIVE',
    Inactive = 'INACTIVE',
    NotFound = 'NOT_FOUND'
}



