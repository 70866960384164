import { Box } from '@chakra-ui/react';
import { InputVariant, Radio } from '@pluxee-design-system/core';
import ProductCard from 'common/cards/ProductCard';
import { RenderComponentProps } from 'components/VirtualizedList';
import { AssignedContract } from 'generated/models';
import Skeleton from 'react-loading-skeleton';
import Item from './ContractItem';

const RenderRow = ({
  index,
  item,
  isHighlighted,
  isLoaded,
}: RenderComponentProps<AssignedContract>) => {
  // const rowClass = index % 2 ? 'even' : 'odd';
  if (!isLoaded || !item) {
    return (
      <div data-index={index}>
        <Box height="95px" mb={4}>
          <Skeleton count={4} />
        </Box>
      </div>
    );
  }

  return (
    <ProductCard
      key={item.contract?.uid}
      data-index={index}
      variant={isHighlighted ? 'selected' : InputVariant.FILLED}
      mb={4}
    >
      <Radio value={item.contract?.uid} />
      <Item contract={item} />
    </ProductCard>
  );
};

export default RenderRow;
