import { isArray } from 'lodash';

const toArrayOrUndefined = <T>(maybeValue?: T | T[] | null): undefined | T[] => {
  if (!maybeValue) {
    return undefined;
  } else if (isArray(maybeValue)) {
    return maybeValue.length > 0 ? maybeValue : undefined;
  }
  return [maybeValue];
};

export default toArrayOrUndefined;
