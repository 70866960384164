import getCsrfToken from 'data/getCsrfToken';
import useConfig from 'data/useConfig';
import useCurrentLocale from 'data/useCurrentLocale';
import { useCallback } from 'react';
import { SignInOptions } from 'types/common';
import instance from 'utils/axios';

export async function signIn(
  currentLocale: string,
  providerId: string,
  callbackUrl?: string,
  selectAccount?: boolean,
  createAccount?: boolean,
) {
  const authorizationParams = {
    ui_locales: currentLocale,
    ...(selectAccount ? { prompt: 'select_account' } : {}),
    ...(createAccount ? { sign_up: 'true' } : {}),
  };
  const provider = providerId || 'SodexoConnect';
  const signInUrl = `/auth/signin/${provider}?${new URLSearchParams(authorizationParams)}`;

  try {
    const response = await instance.post(
      signInUrl,
      // @ts-expect-error
      new URLSearchParams({
        csrfToken: await getCsrfToken(),
        callbackUrl: callbackUrl ?? window.location.href,
        json: true,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    const url = response?.data?.url ?? callbackUrl;
    window.location.href = url;
    // If url contains a hash, the browser does not reload the page. We reload manually
    if (url.includes('#')) window.location.reload();
    return;
  } catch (e) {
    console.error(e);
  }
}

const useSignIn = () => {
  const { value: currentLocale } = useCurrentLocale();
  const { providerId } = useConfig();

  return useCallback(
    async (options?: SignInOptions) =>
      signIn(
        currentLocale,
        providerId,
        options?.callbackUrl,
        options?.selectAccount,
        options?.createAccount,
      ),
    [currentLocale, providerId],
  );
};

export default useSignIn;
