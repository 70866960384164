/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { PayoutFrequency } from './payout-frequency';
import { Price } from './price';
import { ProductTypeEnum } from './product-type-enum';

/**
 * 
 * @export
 * @interface ProductFull
 */
export interface ProductFull {
    /**
     * Available payout list
     * @type {Array<PayoutFrequency>}
     * @memberof ProductFull
     */
    payoutList?: Array<PayoutFrequency> | null;
    /**
     * Services attached to product
     * @type {Array<string>}
     * @memberof ProductFull
     */
    services?: Array<string>;
    /**
     * Product price list
     * @type {Array<Price>}
     * @memberof ProductFull
     */
    priceList?: Array<Price>;
    /**
     * Object unique ID
     * @type {string}
     * @memberof ProductFull
     */
    uid: string;
    /**
     * Product unique code used in the master BO system
     * @type {string}
     * @memberof ProductFull
     */
    code: string;
    /**
     * Product name in en
     * @type {string}
     * @memberof ProductFull
     */
    name: string;
    /**
     * Product translation key
     * @type {string}
     * @memberof ProductFull
     */
    nameKey: string;
    /**
     * 
     * @type {ProductTypeEnum}
     * @memberof ProductFull
     */
    type: ProductTypeEnum;
    /**
     * Loop
     * @type {string}
     * @memberof ProductFull
     */
    loop: ProductFullLoopEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ProductFullLoopEnum {
    Closed = 'closed',
    Open = 'open'
}



