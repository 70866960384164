import { ReactNode, useEffect, useRef } from 'react';
import instanceCommon, { basicConfig, instanceApi } from 'utils/axios';
import {
  getErrorResponseInterceptor,
  getSuccessResponseInterceptor,
  HttpCallbackOptions,
} from 'utils/axios/interceptors';

interface InterceptorProps {
  currentLocale: string;
  providerId: string;
  children: ReactNode;
}

const Interceptor = ({ children, currentLocale, providerId }: InterceptorProps) => {
  const commonInstanceInterceptor = useRef<number | null>(null);
  const apiInstanceInterceptor = useRef<number | null>(null);

  useEffect(() => {
    if (currentLocale && providerId) {
      const callbackOptions: HttpCallbackOptions = { currentLocale, providerId };
      if (commonInstanceInterceptor.current !== null) {
        instanceCommon.interceptors.response.eject(commonInstanceInterceptor.current);
      }
      commonInstanceInterceptor.current = instanceCommon.interceptors.response.use(
        getSuccessResponseInterceptor(basicConfig.httpCallbacks, callbackOptions),
        getErrorResponseInterceptor(
          basicConfig.httpCallbacks,
          basicConfig.excludedHttpCallbacks,
          callbackOptions,
        ),
      );

      if (apiInstanceInterceptor.current !== null) {
        instanceApi.interceptors.response.eject(apiInstanceInterceptor.current);
      }
      apiInstanceInterceptor.current = instanceApi.interceptors.response.use(
        getSuccessResponseInterceptor(basicConfig.httpCallbacks, callbackOptions),
        getErrorResponseInterceptor(
          basicConfig.httpCallbacks,
          basicConfig.excludedHttpCallbacks,
          callbackOptions,
        ),
      );
    }
  }, [currentLocale, providerId]);

  return <>{children}</>;
};

export default Interceptor;
