import useSWR, { KeyedMutator, SWRConfiguration } from 'swr';
import { Session } from 'types/common';
import { fetcher } from 'utils/axios';

const refreshSwrConfig: SWRConfiguration = {
  dedupingInterval: 40 * 1000,
  refreshInterval: 60 * 1000,
};

const useSession = (): [Session | null, boolean, KeyedMutator<Session>] => {
  const { data, isValidating, mutate } = useSWR<Session>('/auth/session', fetcher, {
    ...refreshSwrConfig,
  });
  return [data && Object.keys(data).length > 0 ? data ?? null : null, isValidating, mutate];
};

export default useSession;
