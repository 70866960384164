import { Box } from '@chakra-ui/react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { FieldHelpText, InputVariant } from '@pluxee-design-system/core';
import env from 'env';
import { memo, useCallback } from 'react';
import withFastField, { WithFastFieldProps } from './withFastField';

interface CaptchaControlProps {
  id: string;
  name: string;
  mandatory?: boolean;
}

// TODO: get rid-of formik
const CaptchaControl = ({
  field,
  field: { setValue },
  id,
  name,
  ...captchaProps
}: CaptchaControlProps & WithFastFieldProps<string>) => {
  const handleReset = useCallback(() => setValue(''), [setValue]);
  const handleVerify = useCallback((token: string, _eKey: string) => setValue(token), [setValue]);

  return (
    <Box>
      <HCaptcha
        sitekey={env.captchaSiteKey}
        id={id}
        onExpire={handleReset}
        onError={handleReset}
        onChalExpired={handleReset}
        onVerify={handleVerify}
        {...captchaProps}
      />
      {field.isTouched && field.error && (
        <FieldHelpText text={field.error} variant={InputVariant.ERROR} />
      )}
    </Box>
  );
};

const MemoizedCaptchaControl = memo(CaptchaControl);

export default withFastField(MemoizedCaptchaControl);
