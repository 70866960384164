import { Box, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/react';
import { getContractStatus } from 'components/Contracts/ContractStatus';
import { AssignedContract } from 'generated/models';
import useTranslations from 'i18n';

interface ContractItemProps {
  contract: AssignedContract;
}

const ContractItem = ({
  contract: {
    contract: { companyAddress, companyCin, companyName, masterId, status } = {},
    makeOnboarding,
  },
}: ContractItemProps) => {
  const { t } = useTranslations();
  const { tKey, tDefault } = getContractStatus(status);

  return (
    <Flex
      alignItems={{ base: 'flex-start', md: 'center' }}
      width="100%"
      justify="space-between"
      gap={{ base: 0, md: 2 }}
      direction={{ base: 'column', md: 'row' }}
    >
      <Box>
        <Text variant="body.smallMedium" color="semantic.outline.focus">
          {makeOnboarding ? t('global_texts.statuses.onboarding', 'Onboarding') : t(tKey, tDefault)}
        </Text>
        <Text variant="subtitle.section" noOfLines={1} color="semantic.text.primary">
          {companyName}
        </Text>
        <Text variant="body.medium" noOfLines={1} color="semantic.text.tertiary">
          {companyAddress?.street} {companyAddress?.houseNumber}, {companyAddress?.zip}{' '}
          {companyAddress?.city}
        </Text>
      </Box>
      <Spacer />
      <SimpleGrid
        templateColumns="60px 105px" // fit-content(100%)
        spacingX={3}
        spacingY={0}
        hideBelow="md"
        flex="0 0 auto"
      >
        <Text variant="body.medium" color="semantic.text.tertiary">
          {t('global_texts.modals.selectCompany.cin', 'CIN')}
        </Text>
        <Text variant="body.medium" color="semantic.text.primary" noOfLines={1}>
          {companyCin}
        </Text>
        {masterId && (
          <>
            <Text variant="body.medium" color="semantic.text.tertiary">
              {t('global_texts.modals.selectCompany.contract', 'Contract')}
            </Text>
            <Text variant="body.medium" color="semantic.text.primary" noOfLines={1}>
              {masterId}
            </Text>
          </>
        )}
      </SimpleGrid>
    </Flex>
  );
};

export default ContractItem;
