import { I18nProps } from 'i18n/types';
import { createContext } from 'react';

const I18nContext = createContext<I18nProps>({
  nsReady: () => false,
  t: (key, defaultValue) => defaultValue ?? key,
  tGroup: () => [],
  tHtml: (key, defaultValue) => defaultValue ?? key,
  tQueryKey: () => '',
});

export default I18nContext;
