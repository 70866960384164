import { AxiosInstance } from 'axios';
import { RequestArgs } from 'generated/base';
import { instanceApi } from 'utils/axios';

const createRequestUrl = (
  axiosArgs: RequestArgs,
  globalAxios: AxiosInstance = instanceApi,
): string => globalAxios.defaults.baseURL + axiosArgs.url;

export default createRequestUrl;
