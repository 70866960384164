import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import {
  Button,
  ButtonVariant,
  Footer as FooterUI,
  IconButton,
  Icons,
  Input,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import env from 'env';
import { I18nProps, withLazyNamespaces } from 'i18n';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import cobrowse from 'trackers/cobrowse';

interface FooterProps extends I18nProps {
  currentLocale: string;
  hasCobrowsing: boolean;
}

const Footer = ({ currentLocale, hasCobrowsing, t, tGroup }: FooterProps) => {
  const [showCobrowse, setShowCobrowse] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const hideCobrowse = useCallback(() => setShowCobrowse(false), [setShowCobrowse]);
  const [cobrowseJoinCode, setCobrowseJoinCode] = useState('');

  const handleCobrowseCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCobrowseJoinCode(event.target.value);
    },
    [setCobrowseJoinCode],
  );
  const handleCobrowseShare = useCallback(() => {
    cobrowse.startShareScreen(cobrowseJoinCode);
  }, [cobrowseJoinCode]);

  const leftElements = useMemo(
    () => [
      { key: 'copyright', children: t('ngm_menu.footer.copywriteInformation', '© Pluxee 2023') },
      ...tGroup('ngm_menu.footer.policyLinks').map(
        ({ cookie_settings: isCookiesSettings, link }) => ({
          key: link?.href,
          children: link?.title,
          ...(isCookiesSettings
            ? {
                to: '#',
                onClick: () => {
                  if (typeof window !== 'undefined' && window.OneTrust) {
                    window?.OneTrust?.ToggleInfoDisplay?.();
                  }
                },
              }
            : { href: link?.href, isExternal: true }),
        }),
      ),
      ...(isMobile && hasCobrowsing
        ? [
            {
              key: 'cobrowse',
              children: <SvgSpriteConsumer size={16} svgId={Icons.SCREEN16} />,
              to: '#',
              onClick: () => setShowCobrowse(true),
            },
          ]
        : []),
    ],
    [hasCobrowsing, isMobile, t, tGroup],
  );
  const rightElements = useMemo(
    () => [
      {
        key: 'version',
        children: t('ngm_menu.footer.version', `Version ${env.rumVersion}`, {
          version: env.rumVersion,
        }),
      },
      ...(!isMobile && hasCobrowsing
        ? [
            {
              key: 'cobrowse',
              children: <SvgSpriteConsumer size={16} svgId={Icons.SCREEN16} />,
              to: '#',
              onClick: () => setShowCobrowse(true),
            },
          ]
        : []),
    ],
    [hasCobrowsing, isMobile, setShowCobrowse, t],
  );

  useEffect(() => {
    if (showCobrowse) {
      const parent = document.getElementById('cobrowse-security-form');
      const iframe = parent?.getElementsByTagName('iframe');
      if (iframe?.[0]) parent?.removeChild(iframe[0]);

      cobrowse.start(currentLocale, isMobile);
    }
  }, [currentLocale, isMobile, showCobrowse]);

  return (
    <>
      {hasCobrowsing && (
        <Box>
          <Flex
            display={showCobrowse ? 'flex' : 'none'}
            direction={{ base: 'column', md: 'row' }}
            id="cobrowse-security-form"
            position="absolute"
            width="100%"
            left={0}
            height={{ base: '184px', md: '120px' }}
            bottom={{ base: '78px', md: 12 }}
            background="semantic.surface.1"
            boxShadow="bottom"
            zIndex="1"
            py={4}
            pl={8}
            pr={14}
            gap={2}
            align="center"
            mt={{ base: '184px', md: '120px' }}
          >
            <Box>
              <Text variant="subtitle.section" color="semantic.text.tertiary" noOfLines={3}>
                {t('cobrowsing.header', 'Well done, you have found the screen sharing option')}
              </Text>
              <Text
                variant="subtitle.card"
                color="semantic.text.tertiary"
                hideBelow="md"
                noOfLines={3}
              >
                {t(
                  'cobrowsing.description',
                  'Are you currently in communication with one of our employees? Then enter the code that was sent to you. If this is not the case, you can simply ignore and close this window.',
                )}
              </Text>
            </Box>
            <Box position="absolute" right={4} top={4}>
              <IconButton
                aria-label="Close"
                variant={ButtonVariant.ICON}
                icon={<SvgSpriteConsumer svgId={Icons.DELETE24} size={24} />}
                onClick={hideCobrowse}
                size="sm"
              />
            </Box>
            {cobrowse.config.v2 && (
              <Flex
                gap={4}
                direction={{ base: 'column', md: 'row' }}
                align={{ base: 'flex-start', md: 'initial' }}
                width="100%"
                flex={{ base: '1 0 106px', md: '1 0 384px' }}
              >
                <Input
                  type="text"
                  name="joinCode"
                  placeholder={t('cobrowsing.joinCode', 'Secret key')}
                  value={cobrowseJoinCode}
                  onChange={handleCobrowseCodeChange}
                />
                <Button type="button" variant="primaryFilled" onClick={handleCobrowseShare}>
                  {t('cobrowsing.shareButton', 'Share your screen')}
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      )}
      <FooterUI
        leftElements={leftElements}
        rightElements={rightElements}
        // zIndex={2}
        mt="auto"
      />
    </>
  );
};

export default withLazyNamespaces(Footer, 'ngm_menu', { name: 'cobrowsing', ifCountry: 'be' });
