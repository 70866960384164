/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Type of the back office request
 * @export
 * @enum {string}
 */

export enum BRTypeEnum {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Attach = 'attach',
    Detach = 'detach',
    Activate = 'activate',
    Deactivate = 'deactivate',
    Block = 'block',
    Unblock = 'unblock',
    Generate = 'generate',
    DocumentsFresh = 'documents_fresh',
    TransactionsList = 'transactions_list',
    TransactionRecord = 'transaction_record',
    Confirm = 'confirm',
    LocationAdd = 'location_add'
}



