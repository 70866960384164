/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactFormRequest } from '../models';
// @ts-ignore
import { PromotionFormRequest } from '../models';
// @ts-ignore
import { SuccessResponse } from '../models';
/**
 * SupportApi - axios parameter creator
 * @export
 */
export const SupportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Submit the promotion form
         * @param {PromotionFormRequest} promotionFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionContactForm: async (promotionFormRequest: PromotionFormRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'promotionFormRequest' is not null or undefined
            assertParamExists('promotionContactForm', 'promotionFormRequest', promotionFormRequest)
            const localVarPath = `/api/support/promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apimKey required
            await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promotionFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit the contact form
         * @param {ContactFormRequest} contactFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportContactForm: async (contactFormRequest: ContactFormRequest, ngmCountry?: 'cz' | 'be', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactFormRequest' is not null or undefined
            assertParamExists('supportContactForm', 'contactFormRequest', contactFormRequest)
            const localVarPath = `/api/support/contact-form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ngmCountry !== undefined && ngmCountry !== null) {
                localVarHeaderParameter['Ngm-Country'] = String(ngmCountry);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportApi - functional programming interface
 * @export
 */
export const SupportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Submit the promotion form
         * @param {PromotionFormRequest} promotionFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promotionContactForm(promotionFormRequest: PromotionFormRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promotionContactForm(promotionFormRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit the contact form
         * @param {ContactFormRequest} contactFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async supportContactForm(contactFormRequest: ContactFormRequest, ngmCountry?: 'cz' | 'be', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.supportContactForm(contactFormRequest, ngmCountry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupportApi - factory interface
 * @export
 */
export const SupportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupportApiFp(configuration)
    return {
        /**
         * 
         * @summary Submit the promotion form
         * @param {PromotionFormRequest} promotionFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promotionContactForm(promotionFormRequest: PromotionFormRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.promotionContactForm(promotionFormRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit the contact form
         * @param {ContactFormRequest} contactFormRequest 
         * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        supportContactForm(contactFormRequest: ContactFormRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse> {
            return localVarFp.supportContactForm(contactFormRequest, ngmCountry, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupportApi - interface
 * @export
 * @interface SupportApi
 */
export interface SupportApiInterface {
    /**
     * 
     * @summary Submit the promotion form
     * @param {PromotionFormRequest} promotionFormRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApiInterface
     */
    promotionContactForm(promotionFormRequest: PromotionFormRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

    /**
     * 
     * @summary Submit the contact form
     * @param {ContactFormRequest} contactFormRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApiInterface
     */
    supportContactForm(contactFormRequest: ContactFormRequest, ngmCountry?: 'cz' | 'be', options?: any): AxiosPromise<SuccessResponse>;

}

/**
 * SupportApi - object-oriented interface
 * @export
 * @class SupportApi
 * @extends {BaseAPI}
 */
export class SupportApi extends BaseAPI implements SupportApiInterface {
    /**
     * 
     * @summary Submit the promotion form
     * @param {PromotionFormRequest} promotionFormRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public promotionContactForm(promotionFormRequest: PromotionFormRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return SupportApiFp(this.configuration).promotionContactForm(promotionFormRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit the contact form
     * @param {ContactFormRequest} contactFormRequest 
     * @param {'cz' | 'be'} [ngmCountry] Country ISO Alpha-2 Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportApi
     */
    public supportContactForm(contactFormRequest: ContactFormRequest, ngmCountry?: 'cz' | 'be', options?: any) {
        return SupportApiFp(this.configuration).supportContactForm(contactFormRequest, ngmCountry, options).then((request) => request(this.axios, this.basePath));
    }
}
