import { useMediaQuery, useToken } from '@chakra-ui/react';
import {
  Header,
  Icons,
  LinkProps,
  NavigationItem,
  SubheaderVariants,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import { useContracts } from 'api/contractApi';
import HamburgerMenu from 'components/Navbar/HamburgerMenu';
import HamburgerNestedMenu from 'components/Navbar/HamburgerNestedMenu';
import { globalInfoModal } from 'components/Navbar/modals';
import useModal from 'context/ModalContext';
import useContractSwitcher from 'data/useContractSwitcher';
import useDismiss from 'data/useDismiss';
import useSignIn from 'data/useSignIn';
import env from 'env';
import { I18nProps, withLazyNamespaces } from 'i18n';
import NextLink from 'next/link';
import { useCallback, useEffect, useMemo } from 'react';
import gtm from 'trackers/gtm';
import { Session } from 'types/common';
import insertIf from 'utils/insertIf';
import { getFullBasePath } from 'utils/router';

interface NavbarProps extends I18nProps {
  availableLanguages: string[];
  countryCode: string;
  currentLocale: string;
  session?: Session | null;
  setCurrentLocale: (locale: string) => void;
  showContractSwitcher: boolean;
}

const Navbar = ({
  availableLanguages,
  countryCode,
  currentLocale,
  t,
  tHtml,
  session,
  setCurrentLocale,
  showContractSwitcher,
}: NavbarProps) => {
  const isLoggedIn = Boolean(session?.user);
  const lg = useToken('breakpoints', 'lg');
  const [isLargerThanLg] = useMediaQuery(`(min-width: ${lg})`);
  // contract switcher
  const { isOK, meta } = useContracts(undefined, isLoggedIn && showContractSwitcher);
  const { currentContract, handleClick } = useContractSwitcher(
    t,
    undefined,
    isLoggedIn && showContractSwitcher,
  );
  const totalContracts = meta?.totalRecords || 0;
  const contractName =
    currentContract?.contract?.companyName || t('ngm_menu.mainMenu.contracts', 'Contracts');
  // global modal
  const triggerModal = useModal();
  const modalId = t('global_info_message.modal.uniqueId', '');
  const { handleDismiss: handleDismissModal, isDismissed: isModalDismissed } = useDismiss(
    'global-modal-dismiss',
    null,
    modalId,
  );
  const modalDescriptionRaw = t('global_info_message.modal.description', '');
  const showModal = modalId && modalDescriptionRaw && !isModalDismissed;

  // subheader toast
  const toastId = t('global_info_message.toast.uniqueId', '');
  const { handleDismiss: handleDismissToast, isDismissed: isToastDismissed } = useDismiss(
    'subheader-toast-dismiss',
    null,
    toastId,
  );
  const toastDescription = t('global_info_message.toast.description', '');
  const toastVariant = t('global_info_message.toast.severity', SubheaderVariants.SUBHEADER_SUCCESS);
  const showToast = toastId && toastDescription && !isToastDismissed;

  const signInHandler = useSignIn();
  const links = useMemo<LinkProps[]>(
    () => [
      {
        key: 'support',
        id: 'Navbar_supportAnchor',
        as: NextLink,
        children: t('ngm_menu.mainMenu.support', 'Support'),
        onClick: () => gtm.trackClickSupport(),
        href: '/support',
      },
      {
        key: 'home',
        id: 'Navbar_homeAnchor',
        as: NextLink,
        children: t('ngm_menu.mainMenu.home', 'Home'),
        href: '/',
      },
      ...insertIf(isOK && totalContracts > 1, {
        key: 'contractSwitcher',
        id: 'Navbar_contractSwitch',
        onClick: handleClick,
        rightIcon: <SvgSpriteConsumer size={24} svgId={Icons.CHEVRON_DOWN24} />,
        children: `${contractName} (${totalContracts})`,
      }),
    ],
    [contractName, handleClick, isOK, totalContracts, t],
  );

  const handleLogin = useCallback(() => {
    gtm.trackClickLogin();
    signInHandler();
  }, [signInHandler]);
  const handleSettings = useCallback(
    () =>
      window.open(
        `${env.sodexoConnectUrl}/am/${countryCode}/profile?ui_locales=${currentLocale}`,
        '_blank',
      ),
    [countryCode, currentLocale],
  );
  const handleLogout = useCallback(
    () =>
      window.open(
        `${env.sodexoConnectUrl}/op/oidc/session/end?id_token_hint=${
          session?.idToken
        }&silent_logout=true&post_logout_redirect_uri=${getFullBasePath()}/logout-success`,
        '_self',
      ),
    [session?.idToken],
  );

  const langSwitcher = useMemo(
    () => ({
      availableLanguages: availableLanguages.map((lang) => ({
        code: lang,
        name: lang.split('-')?.[0] ?? lang,
        onClick: () => {
          gtm.trackClickSelectLanguage(lang);
          setCurrentLocale(lang);
        },
      })),
      currentLanguage: {
        name: currentLocale,
        code: currentLocale.split('-')?.[0] ?? currentLocale,
        onClick: () => {},
      },
    }),
    [availableLanguages, currentLocale, setCurrentLocale],
  );

  // TODO: remove when Hamburger icon officially added to Header
  const hamburgerLinks = useMemo<LinkProps[]>(
    () => [
      {
        key: 'menu',
        id: 'Navbar_menuAnchor',
        as: HamburgerMenu,
        navigation: (
          <>
            {links?.map(({ children, leftIcon, ...link }) => (
              <NavigationItem
                key={link.key}
                {...link}
                // @ts-expect-error
                icon={leftIcon}
                title={children as string}
                withDivider
              />
            ))}
            <HamburgerNestedMenu
              backButtonWithDivider
              goBackNavigationTitle={t('global_texts.buttons.back', 'Back')}
              headerTitle={t('ngm_menu.mainMenu.userDropdown.changeLanguage', 'Change language')}
              icon={<SvgSpriteConsumer size={24} svgId={Icons.GLOBE24} />}
              title={t('ngm_menu.mainMenu.userDropdown.changeLanguage', 'Change language')}
              withDivider
            >
              {langSwitcher.availableLanguages.map((lang) => (
                <NavigationItem
                  key={lang.code}
                  icon={<SvgSpriteConsumer size={24} svgId={Icons.GLOBE24} />}
                  isSelected={lang.code === langSwitcher.currentLanguage.code}
                  onClick={lang.onClick}
                  title={lang.name}
                  withDivider
                />
              ))}
            </HamburgerNestedMenu>
          </>
        ),
      },
    ],
    [langSwitcher.availableLanguages, langSwitcher.currentLanguage.code, links, t],
  );

  useEffect(() => {
    if (showModal) {
      triggerModal(globalInfoModal(t, tHtml)).then(handleDismissModal);
    }
  }, [handleDismissModal, showModal, triggerModal, t, tHtml]);

  return (
    <Header
      zIndex={2}
      background="semantic.surface.1"
      languageSwitcher={langSwitcher}
      loginButtonProps={{ onClick: handleLogin, id: 'Navbar_loginAnchor' }}
      navItems={isLargerThanLg ? links : hamburgerLinks}
      userName={session?.user?.name}
      userMenu={
        <>
          <NavigationItem
            icon={<SvgSpriteConsumer size={24} svgId={Icons.WHEEL24} />}
            onClick={handleSettings}
            title={t('ngm_menu.mainMenu.userDropdown.settings', 'Settings')}
            withDivider
          />
          {/*{!isLargerThanLg && (*/}
          {/*  <>*/}
          {/*    <NestingMenuItem*/}
          {/*      backButtonWithDivider*/}
          {/*      goBackNavigationTitle={t('global_texts.buttons.back', 'Back')}*/}
          {/*      headerTitle={t('ngm_menu.mainMenu.userDropdown.changeLanguage', 'Change language')}*/}
          {/*      icon={<SvgSpriteConsumer size={24} svgId={Icons.GLOBE24} />}*/}
          {/*      title={t('ngm_menu.mainMenu.userDropdown.changeLanguage', 'Change language')}*/}
          {/*      withDivider*/}
          {/*    >*/}
          {/*      {langSwitcher.availableLanguages.map((lang) => (*/}
          {/*        <NavigationItem*/}
          {/*          key={lang.code}*/}
          {/*          icon={<SvgSpriteConsumer size={24} svgId={Icons.GLOBE24} />}*/}
          {/*          isSelected={lang.code === langSwitcher.currentLanguage.code}*/}
          {/*          onClick={lang.onClick}*/}
          {/*          title={lang.name}*/}
          {/*          withDivider*/}
          {/*        />*/}
          {/*      ))}*/}
          {/*    </NestingMenuItem>*/}
          {/*  </>*/}
          {/*)}*/}
          <NavigationItem
            icon={<SvgSpriteConsumer size={24} svgId={Icons.ON_OFF24} />}
            onClick={handleLogout}
            title={t('ngm_menu.mainMenu.userDropdown.logout', 'Logout')}
          />
        </>
      }
      translations={{
        login: t('ngm_menu.mainMenu.login', 'Login'),
        productName: session?.user?.name ?? 'NGM',
      }}
      subheaderToast={
        showToast
          ? {
              description: toastDescription,
              variant: toastVariant,
              // @ts-ignore
              onClose: handleDismissToast,
            }
          : undefined
      }
    />
  );
};

export default withLazyNamespaces(Navbar, 'global_info_message', 'global_texts', 'ngm_menu');
