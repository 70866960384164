import getConfig from 'next/config';
import * as process from 'process';

interface ClientEnvType {
  env: 'local' | 'production'; // no point to map other environments?
  version?: string;
  apiBase: string;
  apiProxy: string;
  captchaSiteKey: string;
  rumApplicationId?: string;
  rumClientToken?: string;
  rumEnv?: string;
  rumSite?: string;
  rumService?: string;
  rumVersion?: string;
  baseUrl: string;
  sodexoConnectUrl: string;
  nextAuthPath: string;
  nextAuthRefreshWindow: number;
}

interface ServerEnvType {
  apimHeaders: any;
  apiEndpoint: string;
  sodexoConnectIdCz: string;
  sodexoConnectIdBe: string;
  sodexoConnectResource: string;
  sodexoConnectResourceCz?: string;
  sodexoConnectResourceBe?: string;
  sodexoConnectSecret: string;
  captchaSecret: string;
  captchaApiUrl: string;
  contentstackApiBaseUrl: string;
  cmsEnvironment: string;
  cmsTransverseAccessToken: string;
  cmsTransverseApiKey: string;
  cmsApiKey: string;
  cmsAccessToken: string;
  cmsRefreshDataTimeout: number;
  hubSpotApiUrl: string;
  hubSpotAccessToken: string;
}

type EnvType = ClientEnvType & Partial<ServerEnvType>;

// serverRuntimeConfig is undefined on client side
const env: EnvType =
  'test' !== process.env.NODE_ENV
    ? {
        ...getConfig().publicRuntimeConfig,
        ...getConfig().serverRuntimeConfig,
        ...{
          apimHeaders: process.env.OCP_APIM_SUBSCRIPTION_KEY
            ? {
                'Ocp-Apim-Subscription-Key': process.env.OCP_APIM_SUBSCRIPTION_KEY,
              }
            : {},
          apiEndpoint: process.env.API_ENDPOINT,
          // SodexoConnect
          sodexoConnectIdCz: process.env.SODEXO_CONNECT_ID_CZ,
          sodexoConnectIdBe: process.env.SODEXO_CONNECT_ID_BE,
          sodexoConnectResource: process.env.SODEXO_CONNECT_RESOURCE,
          sodexoConnectResourceCz: process.env.SODEXO_CONNECT_RESOURCE_CZ,
          sodexoConnectResourceBe: process.env.SODEXO_CONNECT_RESOURCE_BE,
          sodexoConnectSecret: process.env.SODEXO_CONNECT_SECRET,
          // ContentStack
          contentstackApiBaseUrl: process.env.CONTENTSTACK_API_BASE_URL,
          cmsEnvironment: process.env.CMS_ENVIRONMENT,
          cmsTransverseAccessToken: process.env.CMS_TRANSVERSE_ACCESS_TOKEN,
          cmsTransverseApiKey: process.env.CMS_TRANSVERSE_API_KEY,
          cmsApiKey: process.env.CMS_API_KEY,
          cmsAccessToken: process.env.CMS_ACCESS_TOKEN,
          // Country configuration
          cmsRefreshDataTimeout: 300000, // milliseconds
          // Captcha
          captchaSecret: process.env.CAPTCHA_SECRET,
          captchaApiUrl: 'https://api.hcaptcha.com',
          // HubSpot
          hubSpotApiUrl: 'https://api.hubapi.com',
          hubSpotAccessToken: process.env.HUBSPOT_ACCESS_TOKEN,
        },
      }
    : {};

export default env;
