import env from 'env';
import Head from 'next/head';

interface AppTitleProps {
  title?: string;
  subTitle?: string;
}

const envName = env.env && env.env !== 'production' ? ` ${env.env}` : '';

const AppTitle = ({ title, subTitle }: AppTitleProps) => {
  if (title) {
    return (
      <Head>
        <title>
          {title}
          {subTitle ? `: ${subTitle}` : ''}
          {envName}
        </title>
      </Head>
    );
  }
  return null;
};

export default AppTitle;
