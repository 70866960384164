import { useContext } from 'react';
import I18nContextLoader from './I18nContextLoader';
import { I18nContextLoaderType } from './types';

const useI18nLoader = (): I18nContextLoaderType => {
  const context = useContext(I18nContextLoader);

  if (context === undefined) {
    console.error('useI18nLoader must be used within a I18nContextLoader');
  }

  return context;
};

export default useI18nLoader;
