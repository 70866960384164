import { defineStyleConfig } from '@chakra-ui/react';

const GridCard = defineStyleConfig({
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 4,
    gap: 4,
    border: '1px solid',
    borderColor: 'semantic.border.secondary',
  },
  variants: {
    default: {
      background: 'semantic.surface.1',
    },
    selected: {
      background: 'semantic.cta.secondaryHover',
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: 'default',
  },
});

export default GridCard;
