/* tslint:disable */
/* eslint-disable */
/**
 * NGM Merchant Backend API (APIM)
 * Main API of the Sodexo NGM project
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export * from './api/affiliation-api';
export * from './api/business-activity-api';
export * from './api/company-api';
export * from './api/contract-api';
export * from './api/contract-file-api';
export * from './api/default-api';
export * from './api/employee-api';
export * from './api/employee-file-api';
export * from './api/location-api';
export * from './api/partner-account-api';
export * from './api/pos-api';
export * from './api/products-api';
export * from './api/sdx-api';
export * from './api/support-api';
export * from './api/user-api';

