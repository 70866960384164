import { isEqual } from 'lodash';
import { DependencyList, useRef } from 'react';

export function useDeepMemoize(value: DependencyList) {
  const ref = useRef<DependencyList>([]);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
